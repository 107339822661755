import { Formula } from './formula'
import { getPeriodicTax, getDieselAddon } from './PeriodicTax'

function toposort(nodes, edges) {
  var cursor = nodes.length,
    sorted = new Array(cursor),
    visited = {},
    i = cursor

  while (i--) {
    if (!visited[i]) visit(nodes[i], i, [])
  }

  return sorted

  function visit(node, i, predecessors) {
    if (predecessors.indexOf(node) >= 0) {
      throw new Error('Cyclic dependency: ' + JSON.stringify(node))
    }

    if (!~nodes.indexOf(node)) {
      throw new Error(
        'Found unknown node. Make sure to provided all involved nodes. Unknown node: ' + JSON.stringify(node),
      )
    }

    if (visited[i]) return
    visited[i] = true

    // outgoing edges
    var outgoing = edges.filter(function (edge) {
      return edge[0] === node
    })
    if ((i = outgoing.length)) {
      var preds = predecessors.concat(node)
      do {
        var child = outgoing[--i][1]
        visit(child, nodes.indexOf(child), preds)
      } while (i)
    }

    sorted[--cursor] = node
  }
}

function uniqueNodes(arr) {
  var res = []
  for (var i = 0, len = arr.length; i < len; i++) {
    var edge = arr[i]
    if (res.indexOf(edge[0]) < 0) res.push(edge[0])
    if (res.indexOf(edge[1]) < 0) res.push(edge[1])
  }
  return res
}

var CPR_REGEXP = /^\d{10}$/

function cprBirthday(viewValue) {
  var valid = CPR_REGEXP.test(viewValue)
  if (valid) {
    var day = parseInt(viewValue.substring(0, 2), 10)
    var month = parseInt(viewValue.substring(2, 4), 10)
    var year = parseInt(viewValue.substring(4, 6), 10)
    var datetest = new Date()
    datetest.setFullYear(1900 + year, month - 1, day)
    var valid1 = datetest.getFullYear() == 1900 + year && datetest.getMonth() == month - 1 && datetest.getDate() == day
    if (valid1) {
      return datetest
    }
  }
  return new Date()
}

export function ComputationEngine(
  modelVars,
  serverVars,
  getUserId,
  isSuperAdmin,
  splitInsuranceCompanyId,
  customVariableDefs,
  getHasAutoItCase,
) {
  var d = new Date()
  var today = new Date(d.getFullYear(), d.getMonth(), d.getDate())

  var self = this
  this.getVariableDefs = function () {
    return variableDefs
  }

  this.getServerVars = function () {
    return serverVars
  }

  let reportTypeErrors = true

  this.setErrorReporting = function (args) {
    reportTypeErrors = args.typeErrors ?? reportTypeErrors
  }

  this.eval = function (str, fallback) {
    //console.log(str)
    try {
      var temp = evalNode(jsep(str))
      if (temp == temp) {
        // NaN should never come out of computation engine!!
        var out = temp
      } else {
        var out = undefined
      }
      if (out === undefined) {
        return fallback
      }
      return out
    } catch (error) {
      console.log('ERROR:' + str + ';', error)
      return fallback
    }
  }

  this.evalUnsafe = function (str, error) {
    try {
      return evalNode(jsep(str))
    } catch (error) {
      return 'ERROR:' + str + ';' + error
    }
  }

  var getYearDate = function (year) {
    var date = new Date()
    date.setFullYear(year, 0, 1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  var getFoersteBetalingDefaultValue = function (isLeasingProduct, d) {
    if (d === undefined) {
      d = new Date()
    }
    var year = d.getFullYear()
    var month = d.getMonth()
    if (d.getDate() <= 10 || isLeasingProduct) {
      month += 1
    } else {
      month += 2
    }
    if (month >= 12) {
      month = month % 12
      year++
    }
    return new Date(year, month, 1, 0, 0, 0, 0)
  }

  var nextFirstOfMonth = function (d) {
    if (d === undefined) {
      d = new Date()
    }
    var year = d.getFullYear()
    var month = d.getMonth()
    if (d.getDate() == 1) {
      return d
    }
    month += 1
    if (month >= 12) {
      month = month % 12
      year++
    }
    return new Date(year, month, 1, 0, 0, 0, 0)
  }

  var firstOfMonth = function (d) {
    if (d === undefined) {
      d = new Date()
    }
    var year = d.getFullYear()
    var month = d.getMonth()
    return new Date(year, month, 1, 0, 0, 0, 0)
  }

  var defaultVariableDefs = {}

  var variableDefs = customVariableDefs !== undefined ? customVariableDefs : defaultVariableDefs

  var resetDependencies = []
  for (var varName in variableDefs) {
    if (variableDefs[varName].reset) {
      for (var i in variableDefs[varName].reset) {
        resetDependencies.push([variableDefs[varName].reset[i], varName])
      }
    }
  }

  this.resetOrdering = toposort(uniqueNodes(resetDependencies), resetDependencies).filter(function (x) {
    return variableDefs[x].reset !== undefined
  })

  var functions = {
    bstart: function (loebetid, alderMdr) {
      if (alderMdr >= 36) return loebetid
      else {
        return Math.max(0, Math.min(loebetid, 36 - alderMdr))
      }
    },
    bslut: function (loebetid, alderMdr) {
      return loebetid - functions.bstart(loebetid, alderMdr)
    },
    validateEmail: function (email) {
      if (email === undefined) return true

      var parts = (email + '').split('@')
      return parts.length == 2 && parts[0].length > 0 && parts[1].length > 0
    },
    getForsikringAarligPraemie: function (forsikringPosition) {
      var temp = serverVars.forsikringAarligPraemie[forsikringPosition]
      if (temp === undefined) {
        return 0
      } else {
        return temp
      }
    },
    getGpsEnhedAbonnement: function (firmakode, medKoerebog) {
      return serverVars.gpsEnhedAbonnement[firmakode][medKoerebog]
    },
    getGpsEnhedPris: function (firmakode, medKoerebog) {
      return serverVars.gpsEnhedPris[firmakode][medKoerebog]
    },
    getGpsFirmanavn: function (firmakode) {
      return serverVars.gpsFirmanavn[firmakode]
    },
    //For non core applications that only have one variant
    maxRentesats: function (companyGroupId, productId) {
      if (serverVars.rentesats !== undefined) {
        if (serverVars.rentesats[companyGroupId] !== undefined) {
          if (serverVars.rentesats[companyGroupId][productId] !== undefined) {
            var maxAge = Math.max.apply(null, Object.keys(serverVars.rentesats[companyGroupId][productId]))
            return serverVars.rentesats[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    maxRentesatsAge: function (companyGroupId, productId) {
      if (serverVars.rentesatsAge !== undefined) {
        if (serverVars.rentesatsAge[companyGroupId] !== undefined) {
          if (serverVars.rentesatsAge[companyGroupId][productId] !== undefined) {
            var maxAge = Math.max.apply(null, Object.keys(serverVars.rentesatsAge[companyGroupId][productId]))
            return serverVars.rentesatsAge[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    maxRentesatsPrice: function (companyGroupId, productId) {
      if (serverVars.rentesatsPrice !== undefined) {
        if (serverVars.rentesatsPrice[companyGroupId] !== undefined) {
          if (serverVars.rentesatsPrice[companyGroupId][productId] !== undefined) {
            var maxAge = Math.max.apply(null, Object.keys(serverVars.rentesatsPrice[companyGroupId][productId]))
            return serverVars.rentesatsPrice[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    if: function (condition, a, b) {
      return condition === 1 || condition === '1' || condition === true ? a : b
    },
    isUndefined: function (a) {
      return a === undefined
    },
    getUserId: function () {
      if (getUserId !== undefined) return getUserId()
      return undefined
    },
    isSuperAdmin: function () {
      if (isSuperAdmin !== undefined) return isSuperAdmin()
      return false
    },
    splitInsuranceCompanyId: function () {
      if (splitInsuranceCompanyId !== undefined) {
        return splitInsuranceCompanyId()
      }
      return null
    },
    getToday: function () {
      return today
    },
    getYear: function (d) {
      return d.getFullYear()
    },
    getDay: function (d) {
      return d.getDate()
    },
    getDefaultProductId: function () {
      return serverVars.defaultProductId[getUserId()]
    },
    getHasAutoItCase() {
      return (getHasAutoItCase ? getHasAutoItCase() : false) ?? false
    },
    getFirstOfCurrentYear: function () {
      return getYearDate(new Date().getFullYear())
    },
    getFoersteBetalingDefaultValue: getFoersteBetalingDefaultValue,
    nextFirstOfMonth: nextFirstOfMonth,
    firstOfMonth: firstOfMonth,
    getForsikringPosition: function (insuranceCompanyId, arg1, arg2, arg3, arg4, arg5) {
      var keys = Object.keys(serverVars.forsikringMaxPris ?? {}).sort((a, b) => a - b)

      const coreKeys = [
        'forsikringPosInsuranceCompanyId',
        'forsikringPosVarevogn',
        'forsikringPosEjIndregistreret',
        'forsikringPosGlas',
        'forsikringPosFossil',
        'forsikringMaxPris',
      ]
      const kroneKeys = [
        'forsikringPosInsuranceCompanyId',
        'forsikringPosEjIndregistreret',
        'forsikringPosGlas',
        'forsikringMaxPris',
        'forsikringPosMin96Mdr',
      ]
      const perbKeys = ['forsikringPosInsuranceCompanyId', 'forsikringMaxPris']

      if (coreKeys.every((k) => Object.hasOwn(serverVars, k))) {
        const varevogn = arg1
        const ejIndregistreret = arg2
        const glas = arg3
        const fossil = arg4
        // noinspection UnnecessaryLocalVariableJS
        const maxpris = arg5

        for (const i of keys) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            varevogn === serverVars.forsikringPosVarevogn[i] &&
            ejIndregistreret === serverVars.forsikringPosEjIndregistreret[i] &&
            glas === serverVars.forsikringPosGlas[i] &&
            fossil === serverVars.forsikringPosFossil[i] &&
            (maxpris || 0) <= serverVars.forsikringMaxPris[i]
          ) {
            return i
          }
        }
      } else if (kroneKeys.every((k) => Object.hasOwn(serverVars, k))) {
        const ejIndregistreret = arg1
        const glas = arg2
        const maxpris = arg3
        // noinspection UnnecessaryLocalVariableJS
        const min96mdr = arg4

        for (const i of keys) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            ejIndregistreret === serverVars.forsikringPosEjIndregistreret[i] &&
            glas === serverVars.forsikringPosGlas[i] &&
            (maxpris || 0) <= serverVars.forsikringMaxPris[i] &&
            min96mdr === serverVars.forsikringPosMin96Mdr[i]
          ) {
            return i
          }
        }
      } else if (perbKeys.every((k) => Object.hasOwn(serverVars, k))) {
        // noinspection UnnecessaryLocalVariableJS
        const maxpris = arg1

        for (var i in serverVars.forsikringMaxPris) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            maxpris <= serverVars.forsikringMaxPris[i]
          )
            return i
        }
      } else if (Object.keys(serverVars).length > 0) {
        const res = {
          found: {
            core: Object.fromEntries(coreKeys.map((k) => [k, Object.hasOwn(serverVars, k)])),
            krone: Object.fromEntries(kroneKeys.map((k) => [k, Object.hasOwn(serverVars, k)])),
            perb: Object.fromEntries(perbKeys.map((k) => [k, Object.hasOwn(serverVars, k)]))
          },
          value: {
            core: Object.fromEntries(coreKeys.map((k) => [k, serverVars[k]])),
            krone: Object.fromEntries(kroneKeys.map((k) => [k, serverVars[k]])),
            perb: Object.fromEntries(perbKeys.map((k) => [k, serverVars[k]]))
          },
          sv: {...serverVars}
        }
        console.log(res)
        throw new Error("Don't know what instance implementation to use for getForsikringPosition")
      }

      return undefined
    },
    getDepreciationMatrixPct: function (depreciationMatrixId, annualMileage, months) {
      if (serverVars.depreciationMatrixPct && serverVars.depreciationMatrixPct[depreciationMatrixId]) {
        // we assume that keys are found in sorted order
        var keys = Object.keys(serverVars.depreciationMatrixPct[depreciationMatrixId]).sort((a, b) => a - b)

        // we also assume that the sorted key sequence corresponds to a sequence of increasing annualMileage and months
        for (var i of keys) {
          //console.log('reached item ' + i + ", " + serverVars.depreciationMatrixAnnualMileage[depreciationMatrixId][i] + ", " + serverVars.depreciationMatrixMonths[depreciationMatrixId][i])
          if (
            annualMileage <= serverVars.depreciationMatrixAnnualMileage[depreciationMatrixId][i] &&
            months <= serverVars.depreciationMatrixMonths[depreciationMatrixId][i]
          ) {
            return serverVars.depreciationMatrixPct[depreciationMatrixId][i]
          }
        }
      }

      return 0.0
    },

    getCarGarantiePosition: function (
      product,
      nyBil,
      forlaengelse,
      bilAlder,
      forsikringsperiode,
      kilometerstand,
      hestekraefter,
      koersel,
      fabriksgaranti,
    ) {
      var keys = Object.keys(serverVars.carGarantiePosProduct ?? {}).sort((a, b) => a - b)

      for (var i of keys) {
        if (
          product === serverVars.carGarantiePosProduct[i] &&
          nyBil === serverVars.carGarantiePosNyBil[i] &&
          forlaengelse === serverVars.carGarantiePosForlaengelse[i] &&
          bilAlder <= serverVars.carGarantiePosMaxAlder[i] &&
          forsikringsperiode === serverVars.carGarantiePosForsikringsperiode[i] &&
          kilometerstand <= serverVars.carGarantiePosMaxKm[i] &&
          (hestekraefter <= serverVars.carGarantiePosMaxHK[i] || serverVars.carGarantiePosMaxHK[i] == null) &&
          (koersel <= serverVars.carGarantiePosMaxKoersel[i] || serverVars.carGarantiePosMaxKoersel[i] == null) &&
          (fabriksgaranti === serverVars.carGarantiePosFabriksgaranti[i] ||
            serverVars.carGarantiePosFabriksgaranti[i] == null)
        ) {
          return i
        }
      }

      return undefined
    },
    ceil: Math.ceil,
    floor: Math.floor,
    round: Math.round,
    min: Math.min,
    max: Math.max,
    existingCompanyGroupId: function (a, b) {
      return serverVars.maxLoebetid !== undefined && serverVars.maxLoebetid[a] !== undefined ? a : b
    },
    datecmp: function (a, b) {
      if (a instanceof Date && b instanceof Date && a !== null && b !== null) {
        return a.getTime() - b.getTime()
      } else {
        return 0
      }
    },
    fv: Formula.FV,
    pmt: Formula.PMT,
    pv: Formula.PV,
    nper: Formula.NPER,
    rate: Formula.RATE,
    cumipmt: Formula.CUMIPMT,
    effect: Formula.EFFECT,
    cprBirthday: cprBirthday,
    monthsSince: function (d1, d2) {
      if (d1 === 0) {
        // 0 er fallbackværdi i serverVars. Denne skal sørge for at monthsSince ikke får nogen værdi
        return undefined
      }
      if (d1 === undefined || d1 === null) {
        return 0
      }
      if (d2 === undefined || d2 === null) {
        d2 = new Date()
      }

      // vi udregner antal måneder i forhold til hvis bilen havde været indreg. pr. d. 1. i indreg. måneden.
      // dateOffset er det der skal trækkes fra slutdatoen for at dette giver mening
      var dateOffset = d1.getDate() - 1

      // der lægges 1 dag til slutdatoen, da vi udregner antal måneder fra d1 kl 0.00 til *og med* d2, dvs. faktisk d2+1 kl. 0.00].
      var d1p = new Date(d1)
      d1p.setDate(1)
      var d2p = new Date(d2)
      d2p.setDate(d2.getDate() - dateOffset + 1)

      var m1 = d1p.getMonth() + 12 * d1p.getFullYear()
      var m2 = d2p.getMonth() + 12 * d2p.getFullYear()
      var diff = m2 - m1
      return diff
    },
    isWithinDays: function (date, days) {
      if (date) {
        var temp = new Date()
        temp.setDate(temp.getDate() + days)

        return date.getTime() <= temp.getTime()
      }
      return false
    },
    addMonths: function (date, months) {
      if (date === undefined) {
        date = new Date()
      }

      if (months === undefined) {
        return undefined
      }

      var temp = new Date(date)
      temp.setMonth(temp.getMonth() + months)
      temp.setDate(temp.getDate() - 1)
      return temp
    },
    addDays: function (date, days) {
      if (date === undefined) {
        date = new Date()
      }
      if (days === undefined) {
        return undefined
      }

      var temp = new Date(date)
      temp.setDate(temp.getDate() + days)
      return temp
    },
    ultimoMonth: function (d) {
      var out = new Date(d)
      out.setMonth(d.getMonth() + 1)
      out.setDate(0)
      return out
    },
    cprMod11: function (cpr) {
      if (cpr == undefined || cpr == null) {
        return false
      }
      var out =
        cpr.length == 10 &&
        (4 * cpr[0] +
          3 * cpr[1] +
          2 * cpr[2] +
          7 * cpr[3] +
          6 * cpr[4] +
          5 * cpr[5] +
          4 * cpr[6] +
          3 * cpr[7] +
          2 * cpr[8] +
          1 * cpr[9]) %
          11 ==
          0
      return out
    },
    strlen: function (str) {
      if (Object.prototype.toString.call(str) === '[object String]') {
        return str.length
      }
      return 0
    },
    udskudtRegAfgiftPct: function (alderMdr, loebetidWrite) {
      return (
        Math.max(0, Math.min(3, alderMdr + loebetidWrite) - Math.max(0, alderMdr)) * 0.02 +
        Math.max(0, Math.min(36, alderMdr + loebetidWrite) - Math.max(3, alderMdr)) * 0.01 +
        Math.max(0, alderMdr + loebetidWrite - Math.max(36, alderMdr)) * 0.005
      )
    },
    regAfgiftForbrugt: function (alderMdr, loebetid, vurderetRegAfgift) {
      return functions.udskudtRegAfgiftPct(alderMdr, loebetid) * vurderetRegAfgift
    },
    regAfgiftRest: function (alderMdr, loebetid, vurderetRegAfgift) {
      return vurderetRegAfgift - functions.regAfgiftForbrugt(alderMdr, loebetid, vurderetRegAfgift)
    },
    regAfgiftRente: function (alderMdr, loebetid, vurderetRegAfgift, renteRegAfgiftPct) {
      return (functions.regAfgiftRest(alderMdr, loebetid, vurderetRegAfgift) * renteRegAfgiftPct * loebetid) / 12
    },
    regAfgiftForudbetaling: function (alderMdr, loebetid, vurderetRegAfgift, renteRegAfgiftPct) {
      return (
        functions.regAfgiftForbrugt(alderMdr, loebetid, vurderetRegAfgift) +
        functions.regAfgiftRente(alderMdr, loebetid, vurderetRegAfgift, renteRegAfgiftPct)
      )
    },
    getRenteRegAfgiftPct(leveringsdatoHalfYear) {
      if (serverVars.renteRegAfgiftPct) {
        const entriesArr = Object.entries(serverVars.renteRegAfgiftPct)
        entriesArr.sort((a, b) => a[0] - b[0])
        const entries = Object.fromEntries(entriesArr)
        return entries[leveringsdatoHalfYear] ?? entriesArr.at(-1)[1]
      } else {
        return 0
      }
    },
    beskatningsgrundlagMiljoe: function (currentDate) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 2.5
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 3.5
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 4.5
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 6
      } else {
        return 7
      }
    },
    beskatningsgrundlagLav: function (currentDate) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 0.245
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 0.24
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 0.235
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 0.23
      } else {
        return 0.225
      }
    },
    beskatningsgrundlagHoej: function (currentDate) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 0.205
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 0.21
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 0.215
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 0.22
      } else {
        return 0.225
      }
    },
    getPeriodicTax: getPeriodicTax,
    getDieselAddon: getDieselAddon,
    halfYear: function (date) {
      if (!!date) {
        return date.getFullYear().toString() + (date.getMonth() <= 5 ? '1' : '2')
      } else {
        date = new Date()
        return date.getFullYear().toString() + (date.getMonth() <= 5 ? '1' : '2')
      }
    },
    getProductPriceLevel(companyGroupIdFinal, productId, price) {
      const levels = (serverVars.productPriceLevels[companyGroupIdFinal] ?? {})[productId] ?? []
      const sortedLevels = [...levels].sort((a, b) => a.value - b.value)

      while (sortedLevels.length) {
        const level = sortedLevels.pop()
        if (price >= level.value) {
          return level.key
        }
      }

      return undefined
    },
  }

  // 1. pass
  // 2. pass
  var expressionCache = {}
  /*for (var i in variableDefs) {
        if (variableDefs[i].vartype === 'model') {
            console.log(i)
        }
    }*/
  var setDefaultValues = function (justInitialized) {
    //console.log(modelVars);
    for (var i in modelVars) {
      delete modelVars[i]
    }
    //console.log(modelVars);

    //var justInitialized = {};
    for (var i in variableDefs) {
      if (variableDefs[i].vartype === 'model') {
        //console.log(i,variableDefs[i].defaultValue)
        //	if (modelVars[i] === undefined) {
        //			justInitialized[i] = true;
        modelVars[i] =
          variableDefs[i].defaultValue ??
          (variableDefs[i].defaultExpr ? getLiteralValue(jsep(variableDefs[i].defaultExpr)) : undefined) // might be undefined!

        if (justInitialized !== undefined) {
          justInitialized[i] = [] //true;
        }
        //	}
      }
    }
    //return justInitialized;
  }

  setDefaultValues()
  this.resetComputation = function (justInitialized) {
    /* 
        
        Beware that the ordering of the this.eval() calls is important, since partial computations are cached.

        When debugging, do not introduce extra this.eval() calls, since they might make the cache contain other values than the ones you expect.

        */
    //console.log('resetComputation was called')
    expressionCache = {}
    //var justInitialized =
    setDefaultValues()
    for (var i in variableDefs) {
      if (variableDefs[i].vartype === 'model') {
        //if (justInitialized[i] === true) {
        if (variableDefs[i].defaultExpr !== undefined) {
          //console.log('resetComputation: field ' + i)

          //if (i=='loebetidWrite')
          const before = modelVars[i]
          const cacheBefore = Object.assign({}, expressionCache)
          //console.log('resetComputation',i,'before',modelVars[i],'after',this.eval(variableDefs[i].defaultExpr))
          modelVars[i] = this.eval(variableDefs[i].defaultExpr)

          // add it to cache...
          //this.eval(i)

          //if (i === 'totalprisMoms' || i === 'productId') {
          //
          //    console.log('resetComputation',i,'before/after',before, modelVars[i], 'cache before/after', cacheBefore, Object.assign({}, expressionCache))
          //    console.log('modelVars after', Object.assign({}, modelVars))
          //}

          //console.log('resetComputation: ' +  this.eval(variableDefs[i].defaultExpr))
          //console.log('resetComputation: ', this.eval('faellespoliceMuligSelskab'), this.eval('faellespoliceMuligSelskab'), this.eval('faellespoliceMuligSelskab'))

          if (justInitialized !== undefined) {
            justInitialized[i] = [] //= true;
          }
        } //}
      }
    }
    expressionCache = {}
  }

  this.clearExpressionCache = function (modelVar) {
    expressionCache = {}
  }

  function getExpressionValue(rawIdentifier) {
    if (expressionCache[rawIdentifier] !== undefined) {
      //console.log('in cache: ' + rawIdentifier + ' = ' + expressionCache[rawIdentifier])
      return expressionCache[rawIdentifier]
    }
    //console.log('getExpressionValue: not in cache: ' + rawIdentifier)

    var arrIdentifier = rawIdentifier.split('.')
    var identifier = arrIdentifier[0]
    var out
    if (arrIdentifier.length > 1) {
      if (variableDefs[identifier][arrIdentifier[1] + 'Expr'] !== undefined) {
        out = expressionCache[rawIdentifier] = self.eval(variableDefs[identifier][arrIdentifier[1] + 'Expr'])
      } else {
        throw 'Underudtryk ikke defineret'
      }
    } else {
      out = expressionCache[rawIdentifier] = self.eval(variableDefs[identifier].expression)
    }

    //console.log('getExpressionValue: not in cache: ' + rawIdentifier + ' = ' + out)
    return out
  }

  this.getValues = function () {
    var out = {}
    for (var identifier in variableDefs) {
      out[identifier] = getValue(identifier)
      for (var subIdentifier in variableDefs[identifier]) {
        if (subIdentifier.substr(subIdentifier.length - 4) == 'Expr') {
          var exprName = identifier + '.' + subIdentifier.substr(0, subIdentifier.length - 4)
          out[exprName] = getValue(exprName)
        }
      }
    }
    return out
  }

  function getValue(rawIdentifier) {
    var identifier = rawIdentifier.split('.')[0]
    if (variableDefs[identifier] === undefined) {
      throw 'Variabel med navn ' + identifier + ' kunne ikke findes'
    }
    const expectedTypes = dataTypeToTypes(variableDefs[identifier].datatype, variableDefs[identifier].required)

    switch (variableDefs[identifier].vartype) {
      case 'server':
        if (rawIdentifier.indexOf('.') > 0) {
          return getExpressionValue(rawIdentifier)
        }

        var temp = serverVars[identifier]

        var fallback = variableDefs[identifier].fallbackValue
        if (variableDefs[identifier].fallbackExpr !== undefined) {
          fallback = self.eval(variableDefs[identifier].fallbackExpr)
        }
        if (temp === undefined) {
          if (fallback === undefined) {
            fallback = expectedTypes.length ? valueZero(fallback, expectedTypes[0]) : 0
          }

          if (Object.keys(serverVars).length > 0) {
            console.log(
              'expected server variable ' +
                identifier +
                ' not found, even though serverVars object is non-empty. Returning fallback value ' +
                fallback +
                '.',
            )
          }

          assertSpecificType(fallback, expectedTypes, null, rawIdentifier + '.fallback', null)
          return fallback
        }
        if (Object.prototype.toString.call(variableDefs[identifier].lookupBy) === '[object Array]') {
          var lookupBy = variableDefs[identifier].lookupBy
          for (var i in lookupBy) {
            temp = temp[getValue(lookupBy[i])]
            if (temp === undefined) {
              if (fallback === undefined) {
                fallback = expectedTypes.length ? valueZero(fallback, expectedTypes[0]) : 0
              }

              assertSpecificType(fallback, expectedTypes, null, rawIdentifier + '.fallback', null)
              return fallback
            }
          }
        }
        return temp
      case 'model':
        if (rawIdentifier.indexOf('.') > 0) {
          return getExpressionValue(rawIdentifier)
        } else {
          //console.log('getValue of ' + identifier + ' = ' + modelVars[identifier])
          assertSpecificType(modelVars[identifier], expectedTypes, null, rawIdentifier, null)
          return modelVars[identifier]
        }
      case 'expression':
        if (rawIdentifier.indexOf('.') > 0) {
          return getExpressionValue(rawIdentifier)
        }

        const res = getExpressionValue(identifier)
        assertSpecificType(res, expectedTypes, null, rawIdentifier, null)
        return res
      default:
        throw 'Variabel med navn ' + identifier + ' har ikke variabeltype'
    }
  }

  function getFunc(identifier) {
    if (functions[identifier] !== undefined) {
      // skal være function, dvs. ikke undefined
      return functions[identifier]
    }
    throw 'Funktion med navn ' + identifier + ' kunne ikke findes'
  }

  function getLiteralValue(node) {
    if (node === undefined) return undefined
    if (node.type === 'Literal') {
      return node.value
    }
    return undefined
  }

  function valueZero(value, tpe) {
    if (!tpe) {
      tpe = typeof value
    }

    switch (tpe) {
      case 'undefined':
      case 'object':
      case 'function':
      case 'symbol':
        if (typeof tpe !== 'undefined') {
          console.error(`Found value ${value} with unexpected type ${typeof value}`)
        }
        return undefined
      case 'boolean':
        return false
      case 'number':
        return 0
      case 'string':
        return ''
      case 'bigint':
        return BigInt(0)
      default:
        if (reportTypeErrors) {
          console.error(`Don't know what default value to give to type ${tpe}`)
        }
        return 0
    }
  }

  function pprintNode(node) {
    if (!node) {
      return ''
    }

    switch (node.type) {
      case 'Literal':
        return node.raw
      case 'BinaryExpression':
      case 'LogicalExpression':
        return `${pprintNode(node.left)} ${node.operator} ${pprintNode(node.right)}`
      case 'UnaryExpression':
        return `${node.operator}${pprintNode(node.argument)}`
      case 'CallExpression':
        return `${node.callee.name}(${node.arguments.map(pprintNode).join(', ')})`
      case 'Identifier':
        return node.name
      case 'MemberExpression':
        return `${node.object.name}.${node.property.name}`
      default:
        return JSON.stringify(node)
    }
  }

  function operatorTypes(operator) {
    switch (operator) {
      case '||':
        return ['boolean', 'number'] //Grumble grumble
      case '&&':
        return ['boolean', 'number'] //Grumble grumble
      case '==':
      case '!=':
        return []
      case '+':
      case '-':
      case '*':
      case '/':
      case '%':
        return ['number']
      case '>':
        return ['number', 'boolean'] //Grumble grumble
      case '>=':
      case '<':
      case '<=':
        return ['number']
      case '!':
        return ['boolean', 'number']
      default:
        console.error(`Unsupported operator: ${operator}`)
        return []
    }
  }

  function dataTypeToTypes(datatype, varDefRequired) {
    if (!datatype) {
      return []
    }

    const required = Boolean(varDefRequired)
    const res = []
    switch (datatype) {
      case '':
        break
      case 'amount':
      case 'percent':
        res.push('number')
        break
      case 'date':
        res.push(Date)
        break
      case 'year':
      case 'count':
        res.push('number')
        break
      case 'boolean':
        res.push('boolean')
        break
      case 'text':
        res.push('string')
        break
      case 'decimal':
      case 'singledecimal':
        res.push('number')
        break
      case 'cvr':
      case 'cpr':
      case 'ucwords':
        res.push('string')
        break
      case 'postnr':
      case 'telefon':
        res.push('number')
        break
      case 'cpr-cvr':
      case 'ucwords-brand':
      case 'bank-regnr':
      case 'bank-kontonr':
      case 'registreringsnummer':
      case 'stelnummer':
      case 'uppercase':
        res.push('string')
        break
      case 'digits':
      case 'leasingkontraktnr':
      case 'enum':
        res.push('number')
        break
      default:
        console.log(`Unknown datatype: ${datatype}`)
    }

    if (res.length) {
      res.push('undefined') // Modelvars can often be undefined if they are invalid
      if (!required) {
        res.push('null')
      }
    }

    return res
  }

  function assertTypeError(cond, error) {
    if (!cond && reportTypeErrors) {
      console.error(error)
    }
  }

  function assertSpecificType(toType, expectedTypes, vNode, fullExpression, node) {
    if (expectedTypes.length === 0) {
      return
    }

    const tpe = typeof toType
    const hasExpectedType =
      expectedTypes.includes(tpe) ||
      (toType === null && expectedTypes.includes('null')) ||
      (tpe === 'object' && expectedTypes.some((t) => typeof t !== 'string' && toType instanceof t))

    let prettyTypeName
    if (toType === null) {
      prettyTypeName = 'null'
    }
    if (toType === undefined) {
      prettyTypeName = 'undefined'
    }
    if (!prettyTypeName && Symbol.toStringTag) {
      prettyTypeName = toType[Symbol.toStringTag]
    }
    if (!prettyTypeName && toType && toType.constructor) {
      prettyTypeName = toType.constructor.name
    }

    assertTypeError(
      hasExpectedType,
      `Found value ${toType} from "${pprintNode(vNode)}" in ${fullExpression} from "${pprintNode(
        node,
      )}" with type ${typeof toType}(${prettyTypeName}), but expected types ${expectedTypes}`,
    )
  }

  function evalNode(node) {
    if (node === undefined) return undefined

    switch (node.type) {
      case 'Literal':
        return node.value
      case 'BinaryExpression':
      case 'LogicalExpression':
        let leftV = evalNode(node.left)
        let rightV = evalNode(node.right)

        if (typeof leftV === 'undefined' && typeof rightV === 'undefined') {
          return undefined
        } else if (typeof leftV === 'undefined') {
          leftV = valueZero(rightV)
        } else if (typeof rightV === 'undefined') {
          rightV = valueZero(leftV)
        }

        const expectedTypes = operatorTypes(node.operator)
        assertSpecificType(leftV, expectedTypes, node.left, `${leftV}${node.operator}${rightV}`, node)
        assertSpecificType(rightV, expectedTypes, node.right, `${leftV}${node.operator}${rightV}`, node)

        const assertSameType = () => {
          assertTypeError(
            typeof leftV === typeof rightV || leftV === null || rightV === null,
            `Left and right have different types. Left=${typeof leftV}(${pprintNode(
              node.left,
            )}), Right=${typeof rightV}(${pprintNode(node.right)}) in ${pprintNode(node)}. This is probably a bug`,
          )
        }

        switch (node.operator) {
          case '||':
            return leftV || rightV
          case '&&':
            return leftV && rightV
          case '==':
            assertSameType()
            // noinspection EqualityComparisonWithCoercionJS
            return leftV == rightV
          case '!=':
            assertSameType()
            // noinspection EqualityComparisonWithCoercionJS
            return leftV != rightV
          case '+':
            return leftV + rightV
          case '-':
            return leftV - rightV
          case '*':
            return leftV * rightV
          case '/':
            return leftV / rightV
          case '%':
            return leftV % rightV
          case '>':
            return leftV > rightV
          case '>=':
            return leftV >= rightV
          case '<':
            return leftV < rightV
          case '<=':
            return leftV <= rightV
          default:
            throw new Error('Unsupported operator: ' + node.operator)
        }
      case 'UnaryExpression':
        const argV = evalNode(node.argument)

        const expectedUnaryTypes = operatorTypes(node.operator)
        assertSpecificType(argV, expectedUnaryTypes, node.argument, `${node.operator}${argV}`, node)

        switch (node.operator) {
          case '!':
            if (typeof argV === 'undefined') {
              return true
            }
            return !argV
          case '+':
            if (typeof argV === 'undefined') {
              return 0
            }
            return +argV
          case '-':
            if (typeof argV === 'undefined') {
              return 0
            }
            return -argV
          default:
            throw new Error('Unsupported operator: ' + node.operator)
        }
      case 'CallExpression':
        if (node.callee.type !== 'Identifier') throw 'Funktionsnavn skal være identifier'

        /*if (node.callee.name === 'if') {
            var condition = evalNode(node.arguments[0]);
            if (condition === 1 || condition === '1' || condition === true)  {
                return evalNode(node.arguments[1])
            } else {
                return evalNode(node.arguments[2])
            }
        }*/

        const values = node.arguments.map(evalNode)
        const functionsAllowingUndefined = [
          'validateEmail',
          'isUndefined',
          'monthsSince',
          'addMonths',
          'addDays',
          'cprMod11',
          'if',
          'existingCompanyGroupId',
        ]

        if (functionsAllowingUndefined.includes(node.callee.name) || values.every((v) => v !== undefined)) {
          return getFunc(node.callee.name).apply(self, values)
        } else {
          //console.log(node.callee.name, values)
          return undefined
        }

      //return getFunc(node.callee.name).apply(self, values)

      case 'Identifier':
        if (node.name === 'Infinity') return Infinity
        return getValue(node.name)
      case 'MemberExpression':
        if (node.object.type !== 'Identifier') throw 'Ikke lovlig MemberExpression'
        if (node.property.type !== 'Identifier') throw 'Ikke lovlig MemberExpression'
        return getValue(node.object.name + '.' + node.property.name)
      default:
        throw 'Node type ' + node.type + ' not handled'
    }
  }
}
