import creditPurgingOfUnworthyTemplate from '@/shared/templates/disposableIncomeComputer.html'
import type { IAttributes, IDirective, IHttpService, IQService, IRootScopeService, IScope, ITimeoutService } from 'angular'
import { ComputationEngine } from '../../computationengine/ComputationEngine'
import { danishLocaleNumber } from "@/shared/functions/numbers"
import {CarleasingInterop} from "@/shared/carleasingInterop";
interface KreditvaerdighedServerVars {
  [name: string]: any
}
interface KreditvaerdighedModelVars {
  dispIncomeComputerComment: string

  [name: string]: computationEngine.Scalar | undefined
}

interface KreditvaerdighedVariableDefs {
  dispIncomeComputerComment: computationEngine.VariableDef

  [name: string]: computationEngine.VariableDef
}

interface DispESkat {
  pdfExists: boolean
  samtykkeUUID: string
  table: ESkatTable
  tableIsEmpty: boolean
  rawValues: RawValues
}

interface DispGaeldOplysninger {
  gaeldList: [Gaeld]
}

interface Gaeld {
  startDato: string
  regDato: string
  identifier: string
  gaeldsType: string
  rest: number
  rente: number
}

interface RawValues {
  validPeriod: string
  aarligSkatteOplysningEngangsudbetalingerBeloeb: number
  aarligSkatteOplysningPensionDagpengeStipendieBeloeb: number
  aarligSkatteOplysningLoenIndkomstBeloeb: number
  aarligSkatteOplysningPersonligIndkomstBeloeb: number
  aarligSkatteOplysningKapitalindkomstBeloeb: number
  aarligSkatteOplysningLigningsmaessigtFradragBeloeb: number
  aarligSkatteOplysningSkattepligtigIndkomstBeloeb: number
  aarligSkatteOplysningBeregnetSkatBeloeb: number
  aarligSkatteOplysningBundskatBeloeb: number
  aarligSkatteOplysningKommuneKirkeskatBeloeb: number
  aarligSkatteOplysningEjendomsvaerdiskatBeloeb: number
  aarligSkatteOplysningSamletAktieindkomstBeloeb: number
  aarligSkatteOplysningArbejdsmarkedsbidragBeloeb: number
}

interface ESkatTableSection {
  name: string
  lines: ReadonlyArray<ESkatTableLine>
}
interface ESkatTableLine {
  header: string
  value: number
  isResult: boolean
  isHeader: boolean
}

interface ESkatTable {
  validPeriod: string
  sections: ReadonlyArray<ESkatTableSection>
}

interface PersonOplysBruttoIndkomst {
  aindkomstTilAMBidrag: number
  aindkomstIkkeTilAMBidrag: number
}
interface PersonOplysSkat {
  indeholdtAMBeloeb: number
  indeholdtASkatBeloeb: number
  atpBidragBeloeb: number
}
interface PersonOplysPension {
  pensionsandelBeloeb: number
}

interface BlanketOplysninger {
  bruttoIndkomst: PersonOplysBruttoIndkomst
  skat: PersonOplysSkat
  pension: PersonOplysPension
  nettoIndkomst: number
}

interface DispIndkomstOplysninger {
  cpr: string
  dimMonth: ReadonlyArray<string>
  dimCompany: ReadonlyArray<string>
  indkomstMonthCompany: Record<string, Record<string, BlanketOplysninger>>
  nettoindkomst: Record<string, number>
  bruttoindkomst: Record<string, number>
}

interface DispFormueOplysninger {
  cpr: string
  formue: number
  pengeInstitut: number
  ejendomsvaerdi: number
  aktiebevis: number
  gaeld: number
  gaeldsfaktor: number
}
interface Ejendom {
  kommune: string
  addresse: string
  ejerAndel: number
}

interface DispEjendomsOplysninger {
  ejendomme: ReadonlyArray<Ejendom>
}

interface ESkat {
  dispESkat: DispESkat
  dispIndkomstOplysninger: DispIndkomstOplysninger
  dispGaeldOplysninger: DispGaeldOplysninger
  dispEjendomsOplysninger: DispEjendomsOplysninger
  dispESkat2: DispESkat | undefined
  dispIndkomstOplysninger2: DispIndkomstOplysninger | undefined
  dispGaeldOplysninger2: DispGaeldOplysninger | undefined
  dispEjendomsOplysninger2: DispEjendomsOplysninger | undefined
}

interface DisposableIncomeComputerScope extends IScope {
  ejendomsOplysninger: DispEjendomsOplysninger
  ejendomsOplysninger2: DispEjendomsOplysninger
  eSkatDataListIsNotEmpty: (ls: [DispESkat]) => boolean
  eSkatData: DispESkat
  gaeldOplysninger: DispGaeldOplysninger
  indkomstOplysninger: DispIndkomstOplysninger
  formueOplysninger: DispFormueOplysninger
  eSkatData2: DispESkat | undefined
  eSkatDataList: [DispESkat]
  gaeldOplysninger2: DispGaeldOplysninger | undefined
  indkomstOplysninger2: DispIndkomstOplysninger | undefined
  formueOplysninger2: DispFormueOplysninger | undefined
  getIndkomst: (indkomstOplysninger: DispIndkomstOplysninger, date: string, companyName: string) => number
  eSkatDatePrettyPrint: (x: string) => string
  eSkatPrettyPrint: (x: number) => string
  vocabulary: Map<string, string>
  eSkatIsLoading: boolean
  haftBilFoerToString: () => string
  boligtypeToString: () => string
  udgiftsFordelingToString: () => string
  nyGaeldToString: () => string
  cancel: () => void
  realEngine: computationEngine.ComputationEngine<KreditvaerdighedVariableDefs>
  dummyEngine: DummyEngine
  data: any
  valuesJson: any
  save: () => angular.IPromise<void>
  goToEditMode: () => void
  debug: () => void
  applicationId: number
  groups: ReadonlyArray<any>
  modelVars: KreditvaerdighedModelVars
  serverVars: KreditvaerdighedServerVars
  engine: any
  variableDefs: KreditvaerdighedVariableDefs
  editMode: Boolean
  renderAmount: (value: number) => string
  danishLocaleNumber: (value: string) => string
  $interop: CarleasingInterop
}

class DummyEngine {
  variableDefs: any
  values: any

  constructor(variableDefs: any, values: any) {
    this.variableDefs = variableDefs
    this.values = values
  }

  eval(identifier: any, fallback: any) {
    if (this.values[identifier] === undefined) {
      return fallback
    }
    return this.values[identifier]
  }

  getValues() {
    return this.values
  }
  resetComputation() {}

  clearExpressionCache() {}

  getVariableDefs() {
    return this.variableDefs
  }
}

export const disposableIncomeComputer = [
  '$http',
  '$rootScope',
  '$q',
  '$timeout',
  function (
    $http: IHttpService,
    $rootScope: IRootScopeService,
    $q: IQService,
    $timeout: ITimeoutService,
  ): IDirective<DisposableIncomeComputerScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditPurgingOfUnworthyTemplate,
      scope: {
        applicationId: '=',
        valuesJson: '=',
      },

      link: function ($scope: DisposableIncomeComputerScope, elm: JQLite, attrs: IAttributes) {
        $scope.serverVars = {}
        $scope.modelVars = { dispIncomeComputerComment: '' }
        $scope.editMode = false
        $scope.$interop = ($scope.$parent as any).$interop

        $scope.renderAmount = function (value) {
          return danishLocaleNumber(Math.ceil(value).toString())
        }

        $scope.danishLocaleNumber = danishLocaleNumber

        const emptyFunction = function () {
          return undefined
        }

        $scope.goToEditMode = function () {
          $scope.engine = $scope.realEngine
          $scope.editMode = true
        }

        $scope.save = function () {
          return $http
            .post('/api/kreditvaerdighed/save', {
              applicationId: $scope.applicationId,
              modelVars: $scope.modelVars,
              computedValues: $scope.engine.getValues(),
            })
            .then(() => {
              load().then(() => {
                $timeout(() => {
                  $scope.engine = $scope.dummyEngine
                  $scope.editMode = false
                })
              })
            })
        }

        $scope.cancel = function () {
          $scope.engine = $scope.dummyEngine
          $scope.editMode = false
        }
        $scope.debug = function () {
          console.log($scope.valuesJson)
        }

        $scope.boligtypeToString = function () {
          if ($scope.valuesJson.boligtype === 0) return 'Ejerbolig'
          if ($scope.valuesJson.boligtype === 1) return 'Lejebolig'
          else return 'Andelsbolig'
        }
        $scope.udgiftsFordelingToString = function () {
          if ($scope.valuesJson.udgiftsFordeling === 0) return 'Deles ligeligt 50/50'
          else if ($scope.valuesJson.udgiftsFordeling === 1) return 'Jeg afholder flest udgifter ca. 75/25'
          else if ($scope.valuesJson.udgiftsFordeling === 2) return 'Samlever afholder flest udgifter ca. 75/25'
          else if ($scope.valuesJson.udgiftsFordeling === 3) return 'Jeg betaler all udgifter'
          else if ($scope.valuesJson.udgiftsFordeling === 4) return 'Samlever betaler alle udgifter'
          else return 'Ukendt'
        }
        $scope.nyGaeldToString = function () {
          if ($scope.valuesJson.nyGaeld) return 'Ja'
          else return 'Nej'
        }
        $scope.haftBilFoerToString = function () {
          if ($scope.valuesJson.haftBilFoer) return 'Ja'
          else return 'Nej'
        }

        $scope.getIndkomst = function (
          indkomstOplysninger: DispIndkomstOplysninger,
          date: string,
          companyName: string,
        ): number {
          const info = indkomstOplysninger.indkomstMonthCompany[date][companyName]
          return info.bruttoIndkomst.aindkomstTilAMBidrag + info.bruttoIndkomst.aindkomstIkkeTilAMBidrag
        }

        $scope.eSkatPrettyPrint = function (x: number | string): string {
          if (x !== undefined)
            return x
              .toString()
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          else return ''
        }
        $scope.eSkatDatePrettyPrint = function (x: string): string {
          const year = x.substring(0, 4)
          const month = x.substring(5, 7)                

          let map = new Map([
            ['01', 'Jan'],
            ['02', 'Feb'],
            ['03', 'Mar'],
            ['04', 'Apr'],
            ['05', 'Maj'],
            ['06', 'Jun'],
            ['07', 'Jul'],
            ['08', 'Aug'],
            ['09', 'Sep'],
            ['10', 'Okt'],
            ['11', 'Nov'],
            ['12', 'Dec'],
          ])

          return year + ' ' + map.get(month)
        }

        $scope.eSkatDataListIsNotEmpty = function (ls: [DispESkat]) {
          return ls.map((x) => x.tableIsEmpty).includes(false)
        }

        function load() {
          $scope.eSkatIsLoading = true
          return $http
            .get<{
              varDefs: KreditvaerdighedVariableDefs
              eSkat?: {
                dispESkat: DispESkat
                dispIndkomstOplysninger: DispIndkomstOplysninger
                dispGaeldOplysninger: DispGaeldOplysninger
                dispFormueOplysninger: DispFormueOplysninger
                dispEjendomsOplysninger: DispEjendomsOplysninger

                dispESkat2?: DispESkat
                dispIndkomstOplysninger2?: DispIndkomstOplysninger
                dispGaeldOplysninger2?: DispGaeldOplysninger
                dispFormueOplysninger2?: DispFormueOplysninger
                dispEjendomsOplysninger2: DispEjendomsOplysninger
              }
              disposableIncome?: {
                computedValues: any
                modelVars: any
              }
              modelVarFields: any
              serverVars: any
              groups: any[]
            }>('/api/kreditvaerdighed/load/' + $scope.applicationId)
            .then(function (res) {
              const data = res.data

              // Internally ComputationEngine calls 'setDefaultValues()' in its constructor.
              // Therefore, we initialize ComputationEngine before adding modelVars values

              $scope.variableDefs = data.varDefs
              if (data.eSkat !== undefined) {
                $scope.eSkatData = data.eSkat.dispESkat
                $scope.indkomstOplysninger = data.eSkat.dispIndkomstOplysninger
                $scope.gaeldOplysninger = data.eSkat.dispGaeldOplysninger
                $scope.eSkatDataList = [$scope.eSkatData]
                $scope.formueOplysninger = data.eSkat.dispFormueOplysninger
                $scope.ejendomsOplysninger = data.eSkat.dispEjendomsOplysninger
                if (
                  !!data.eSkat.dispESkat2 &&
                  !!data.eSkat.dispIndkomstOplysninger2 &&
                  !!data.eSkat.dispGaeldOplysninger2
                ) {
                  $scope.eSkatData2 = data.eSkat.dispESkat2
                  $scope.indkomstOplysninger2 = data.eSkat.dispIndkomstOplysninger2
                  $scope.gaeldOplysninger2 = data.eSkat.dispGaeldOplysninger2
                  $scope.eSkatDataList.push(data.eSkat.dispESkat2)
                  $scope.formueOplysninger2 = data.eSkat.dispFormueOplysninger2
                  $scope.ejendomsOplysninger2 = data.eSkat.dispEjendomsOplysninger2
                  $scope.eSkatDataList
                }
              }
              $scope.eSkatIsLoading = false

              $scope.realEngine = new ComputationEngine<KreditvaerdighedVariableDefs>(
                $scope.modelVars,
                $scope.serverVars,
                emptyFunction,
                emptyFunction,
                emptyFunction,
                $scope.variableDefs,
              )

              $scope.engine = $scope.realEngine
              $scope.editMode = true

              if (data.disposableIncome !== undefined) {
                $scope.dummyEngine = new DummyEngine($scope.variableDefs, data.disposableIncome.computedValues)
                $scope.engine = $scope.dummyEngine
                $scope.editMode = false
              }

              if (data.disposableIncome !== undefined) {
                for (const val in data.disposableIncome.modelVars) {
                  if (typeof data.disposableIncome.modelVars[val] !== 'undefined') {
                    $scope.modelVars[val] = data.disposableIncome.modelVars[val]
                  } else {
                    $scope.modelVars[val] = $scope.variableDefs[val].defaultValue
                  }
                }
              }

              for (const val of data.modelVarFields) {
                $scope.serverVars['application_' + val] = $scope.valuesJson[val]
              }
              for (const val in data.serverVars) {
                $scope.serverVars[val] = data.serverVars[val]
              }

              $scope.groups = data.groups

              $scope.$watchCollection('modelVars', function (newVar, oldVar) {
                if ($scope.engine !== undefined) {
                  $scope.engine.clearExpressionCache()
                }
              })
            })
        }

        $scope.$watch('applicationId', function (id) {
          if (id !== undefined) {
            load()
          }
        })

        $scope.$on('disposableIncome.save', function (event, argsOpt) {
          $scope.save()
        })
      },
    }
  },
]
