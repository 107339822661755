import errorTemplate from '@/shared/templates/modals/error.html'
import warningTemplate from '@/shared/templates/modals/warning.html'

export const mainCtrl = [
  '$state',
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$modal',
  '$interop',
  function ($state, $scope, $rootScope, $http, $stateParams, $modal, $interop) {
    $scope.$state = $state
    $rootScope.$on('httperror', function (e, msg) {
      $modal({ template: errorTemplate, show: true, content: msg })
    })

    $rootScope.currentForm = undefined
    $rootScope.currentStatus = 0

    var unsavedWarning = $modal({
      template: warningTemplate,
      show: false,
    })
    $rootScope.warningIsOpen = false

    $rootScope.setWarningClosed = function () {
      $rootScope.warningIsOpen = false
    }

    $rootScope.continue = function () {
      $state.go($rootScope.toState, $rootScope.toParams).then($rootScope.setWarningClosed)
    }

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      //console.log('hej');
      if (
        (fromState.name === 'root.application.edit' || fromState.name === 'root.application.add') &&
        $rootScope.currentForm.$dirty === true &&
        $rootScope.currentStatus === 0
      ) {
        if (!$rootScope.warningIsOpen) {
          $rootScope.warningIsOpen = true
          $rootScope.toState = toState
          $rootScope.toParams = toParams
          unsavedWarning.$promise.then(unsavedWarning.show)
          event.preventDefault()
          return
        }
      }

      if (!$scope.keepAlertAfterStateChange) {
        $scope.globalAlert = false
      }
      $scope.keepAlertAfterStateChange = false
    })

    $scope.setGlobalAlert = function (msg) {
      $scope.globalAlert = msg
      $scope.keepAlertAfterStateChange = true
    }

    $scope.setKeepAlert = function (val) {
      $scope.keepAlertAfterStateChange = val
    }

    $scope.globalAlert = false
    $scope.keepAlertAfterStateChange = false

    $rootScope.$interop = $interop
  },
]
