import { dateToDanish } from '@/shared/functions/dates'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import angular from 'angular'
import existingContractsTemplate from '@/shared/templates/existing-contracts.html'

export const dkkapExistingContracts = [
  '$http',
  '$rootScope',
  '$timeout',
  function ($http, $rootScope, $timeout) {
    return {
      restrict: 'A',
      template: existingContractsTemplate,
      scope: {
        modelVars: '=',
        hideExistingCustomerContracts: '=',
        applicationId: '=',
      },
      link: function ($scope, elm, attrs) {
        $scope.toggle = function () {
          var v0 = $scope.hideExistingCustomerContracts
          var v1 = !v0

          $scope.hideExistingCustomerContracts = v1

          $http.post('/api/application/setHideExistingCustomerContracts/' + $scope.applicationId, {
            hideExistingCustomerContracts: v1,
          })
        }

        $scope.activeNumbers = []
        $scope.existingContracts = {}
        $scope.existingContractsSum = 0.0
        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(timestamp))
        }
        $scope.renderAmount = function (value) {
          return danishLocaleNumber(Math.ceil(value))
        }

        $scope.getRestvaerdiActiveSum = function () {
          var sum = 0
          for (var i in $scope.activeNumbers) {
            var number = $scope.activeNumbers[i]
            if ($scope.existingContracts[number]) {
              for (var j in $scope.existingContracts[number]) {
                var contract = $scope.existingContracts[number][j]
                if (!contract.bruger2) {
                  sum += contract.restvaerdiActive
                }
              }
            }
          }
          return sum
        }

        $scope.getRestgaeldSum = function () {
          var sum = 0
          for (var i in $scope.activeNumbers) {
            var number = $scope.activeNumbers[i]
            if ($scope.existingContracts[number]) {
              for (var j in $scope.existingContracts[number]) {
                var contract = $scope.existingContracts[number][j]
                sum += contract.restgaeld
              }
            }
          }
          return sum
        }

        function loadExisting() {
          var arr = []
          if ($scope.modelVars.kundetype) {
            if ($scope.modelVars.cvr && $scope.modelVars.cvr.length == 8) {
              arr.push($scope.modelVars.cvr)
            }
          } else {
            if ($scope.modelVars.cpr && $scope.modelVars.cpr.length == 10) {
              arr.push($scope.modelVars.cpr)
            }
          }

          if ($scope.modelVars.meddebitor) {
            if ($scope.modelVars.cpr2 && $scope.modelVars.cpr2.length == 10) {
              arr.push($scope.modelVars.cpr2)
            }
          }
          $scope.activeNumbers = arr

          var toBeLoaded = []
          angular.forEach(arr, function (number) {
            if (!(number in $scope.existingContracts)) {
              toBeLoaded.push(number)
              $scope.existingContracts[number] = null
            }
            $http.post('/api/existingContracts/lookup', toBeLoaded).success(function (res) {
              $timeout(function () {
                angular.extend($scope.existingContracts, res)
              })
            })
          })
        }

        $scope.$watch('modelVars.cvr', loadExisting)
        $scope.$watch('modelVars.cpr', loadExisting)
        $scope.$watch('modelVars.cpr2', loadExisting)
        $scope.$watch('modelVars.kundetype', loadExisting)
        $scope.$watch('modelVars.meddebitor', loadExisting)
      },
    }
  },
]
