import { CVR_REGEXP } from '@/shared/regexes'

export const ngCvr = [
  '$http',
  function ($http) {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = viewValue + ''
          if (CVR_REGEXP.test(viewValue)) {
            // it is valid
            ctrl.$setValidity('cvr', true)
            if (scope.setWarning !== undefined) {
              // eksisterer kun på form.html !
              $http.post('/api/registration/companyAddress/' + viewValue, {}).success(function (obj) {
                if (!obj.isValid) {
                  if (attrs.name == 'kautionistCvr') {
                    scope.setWarning('Virksomheden kunne ikke findes.', 'kautionistCvrWarning')
                  } else if (attrs.name == 'kautionist2Cvr') {
                    scope.setWarning('Virksomheden kunne ikke findes.', 'kautionist2CvrWarning')
                  } else if (attrs.name == 'anviserCvr') {
                    scope.setWarning('Virksomheden kunne ikke findes.', 'anviserCvrWarning')
                  } else if (attrs.name == 'anviser2Cvr') {
                    scope.setWarning('Virksomheden kunne ikke findes.', 'anviser2CvrWarning')
                  } else {
                    scope.setWarning('Virksomheden kunne ikke findes.', 'cvrWarning')
                  }
                } else {
                  if (attrs.name == 'cvrCompanyPage') {
                    scope.setWarning(obj.errormsg, 'cvrWarning')
                    scope.modelVars.name = obj.name
                    scope.modelVars.address = obj.address
                    scope.modelVars.zipcode = parseInt(obj.zipcode)
                  } else if (attrs.name == 'kautionistCvr') {
                    scope.setWarning(obj.errormsg, 'kautionistCvrWarning')
                    if (scope.modelVars.kautionistNavn !== undefined) {
                      //scope.cityVars[attrs.ngPostnr] = city;
                      scope.modelVars.kautionistNavn = obj.name
                      scope.modelVars.kautionistAdresse = obj.address
                      scope.modelVars.kautionistPostnr = parseInt(obj.zipcode)
                    }
                  } else if (attrs.name == 'kautionist2Cvr') {
                    scope.setWarning(obj.errormsg, 'kautionist2CvrWarning')
                    if (scope.modelVars.kautionist2Navn !== undefined) {
                      //scope.cityVars[attrs.ngPostnr] = city;
                      scope.modelVars.kautionist2Navn = obj.name
                      scope.modelVars.kautionist2Adresse = obj.address
                      scope.modelVars.kautionist2Postnr = parseInt(obj.zipcode)
                    }
                  } else if (attrs.name == 'anviserCvr') {
                    scope.setWarning(obj.errormsg, 'anviserCvrWarning')
                    if (scope.modelVars.anviserNavn !== undefined) {
                      //scope.cityVars[attrs.ngPostnr] = city;
                      scope.modelVars.anviserNavn = obj.name
                      scope.modelVars.anviserAdresse = obj.address
                      scope.modelVars.anviserPostnr = parseInt(obj.zipcode)
                    }
                  } else if (attrs.name == 'anviser2Cvr') {
                    scope.setWarning(obj.errormsg, 'anviser2CvrWarning')
                    if (scope.modelVars.anviser2Navn !== undefined) {
                      //scope.cityVars[attrs.ngPostnr] = city;
                      scope.modelVars.anviser2Navn = obj.name
                      scope.modelVars.anviser2Adresse = obj.address
                      scope.modelVars.anviser2Postnr = parseInt(obj.zipcode)
                    }
                  } else {
                    scope.setWarning(obj.errormsg, 'cvrWarning')
                    if (scope.modelVars.navn !== undefined) {
                      //scope.cityVars[attrs.ngPostnr] = city;
                      scope.modelVars.navn = obj.name.substring(0, 50)
                      scope.modelVars.adresse = obj.address
                      scope.modelVars.postnr = parseInt(obj.zipcode)
                      scope.modelVars.tlf = obj.phone
                      scope.modelVars.mail = obj.mail
                    }
                  }
                }
              })
            }

            return viewValue
          } else if (viewValue == '') {
            ctrl.$setValidity('cvr', true)
            return null
          } else {
            ctrl.$setValidity('cvr', false)
            return undefined
          }
        })
      },
    }
  },
]
