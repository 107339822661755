import { dateToYMD } from '@/shared/functions/dates'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import { SeasonContractUtil, modifyDate, modifyMonth } from './SeasonContract'

import seasonAdminTemplate from '@/shared/templates/season-admin.html'

export const dkkapSeasonAdmin = [
  '$http',
  '$rootScope',
  '$timeout',
  function ($http, $rootScope, $timeout) {
    return {
      restrict: 'A',
      template: seasonAdminTemplate,
      scope: {
        applicationRow: '=',
        contractRes: '=',
        reloadData: '&',
        create: '&',
        update: '&',
      },
      link: function ($scope, elm, attrs) {
        $scope.abs = Math.abs
        $scope.dateToYMD = dateToYMD
        function newUTCDate(y, m, d) {
          return new Date(Date.UTC(y, m, d))
        }

        function ymdToUTCDate(ymd) {
          var d = ymd.split('-').map(function (x) {
            return parseInt(x, 10)
          })

          return new Date(Date.UTC(d[0], d[1] - 1, d[2]))
        }

        var util = new SeasonContractUtil()
        var start = ymdToUTCDate($scope.applicationRow.values.leveringsdatoFinal)
        var duration = $scope.applicationRow.values.loebetid
        var end = modifyDate(modifyMonth(start, duration), -1)

        var application = {
          start: start,
          end: end,
          monthlyPayment: $scope.applicationRow.values.ydelse,
          interestRate: $scope.applicationRow.values.rentesatsEfterReg,
          presentValue: $scope.applicationRow.values.hovedstol,
          duration: duration,
          futureValue: $scope.applicationRow.values.restvaerdi,
          paymentType: $scope.applicationRow.values.betalingstype,
          regFee: $scope.applicationRow.values.vurderetRegAfgift,
          regFeeInterestPct: $scope.applicationRow.values.renteRegAfgiftPct,
          firstRegistrationDate: !$scope.applicationRow.values.nyBil
            ? ymdToUTCDate($scope.applicationRow.values.indregistreretFoersteGang)
            : null,
        }

        var seasonCount = $scope.applicationRow.values.sommerbilAntalSaesoner

        var origRegSeasons = [1, 2, 3, 4, 5, 6, 7, 8]
          .filter(function (x) {
            return x <= seasonCount
          })
          .map(function (x) {
            return {
              start: ymdToUTCDate($scope.applicationRow.values['startdatoSaeson' + x]),
              duration: $scope.applicationRow.values['loebetidSaeson' + x],
              regFee: null,
              regFeeInterestPct: null,
            }
          })

        var modifiedRegSeasons = [1, 2, 3, 4, 5, 6, 7, 8]
          .filter(function (x) {
            return x <= seasonCount
          })
          .map(function (x) {
            // hvis der er oprettet kontrakter tages info fra de INDREG. kontrakter og bruges til modifikation af planen for sæsonleasingaftalen

            var key = 'M' + (x - 1)
            var changed = $scope.contractRes[key]

            return changed
              ? {
                  start: changed.leveringsdato
                    ? ymdToUTCDate(dateToYMD(new Date(changed.leveringsdato)))
                    : ymdToUTCDate($scope.applicationRow.values['startdatoSaeson' + x]),
                  duration: changed.loebetidWrite,
                  regFee: changed.vurderetRegAfgift,
                  regFeeInterestPct: changed.useRenteRegAfgiftPctWrite ? changed.renteRegAfgiftPctWrite : null,
                }
              : null
          })

        var regSeasons = modifiedRegSeasons.map(function (x, i) {
          return x || origRegSeasons[i]
        })

        $scope.origRegSeasons = origRegSeasons
        $scope.modifiedRegSeasons = modifiedRegSeasons

        var interestRateChanges = []

        var contracts = util.getSeasonContractRanges(application, regSeasons)
        var interestRates = util.getSeasonApplicationInterestRates(application, interestRateChanges)

        $scope.application = application
        $scope.contracts = util.getSeasonContractsFull(application, contracts, interestRates)

        $scope.contractRows = $scope.contracts.map(function (x) {
          var key = (x.registered ? 'M' : 'U') + x.seasonIndex
          return $scope.contractRes[key]
        })

        $scope.selectedContract = null

        $scope.selectContract = function ($event, index) {
          $scope.selectedContract = index
        }

        $scope.ymdToUTCDate = ymdToUTCDate

        $scope.showUTCDate = function (d) {
          return (
            (100 + d.getUTCDate()).toString().substring(1) +
            '.' +
            (101 + d.getUTCMonth()).toString().substring(1) +
            '.' +
            d.getUTCFullYear()
          )
        }

        $scope.showUTCDateYMD = function (d) {
          return (
            d.getUTCFullYear() +
            '-' +
            (101 + d.getUTCMonth()).toString().substring(1) +
            '-' +
            (100 + d.getUTCDate()).toString().substring(1)
          )
        }

        $scope.renderStaticAmount = function (value) {
          return 'kr ' + danishLocaleNumber(Math.ceil(value))
        }

        $scope.renderStaticPct = function (value) {
          return (value * 100).toFixed(2).replace('.', ',') + ' %'
        }
        $scope.renderStatic = function (value) {
          return value.toFixed(2).replace('.', ',')
        }
      },
    }
  },
]
