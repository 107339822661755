import { CarleasingInterop } from '@/shared/carleasingInterop'

import { ComputationEngine } from '@/computationengine/ComputationEngine'
import type { IHttpService } from 'angular'

export const perbInterop: CarleasingInterop = {
  module: 'perb',
  prettyModuleName: 'PerB Finans',
  features: [
    'mailTemplate.mailTemplateCustomerInfoLink2',
    'mailTemplate.mailTemplateCustomerInfoLink2Subject',
    'mailTemplate.smsTemplateCustomerInfoLink2',
    'mailTemplate.penneoCompletionNotification',
    'hasFinancing',
    'hasAutoApproval',
    'hasLegacyCustomerLink',
    'hasExternal',
    'commonConfig.renteRegAfgiftPct'
  ],
  controlPanelStates: [
    'root.controlpanel.company',
    'root.controlpanel.companyGroup',
    'root.controlpanel.commonConfig',
    'root.controlpanel.interestAdjustment',
    'root.controlpanel.selvanmelder',
    'root.controlpanel.insuranceCompany',
    'root.controlpanel.insuranceCosts',
    'root.controlpanel.gpsProvider',
    'root.controlpanel.article',
    'root.controlpanel.news',
    'root.controlpanel.newsletter',
    'root.controlpanel.mailTemplates',
    'root.controlpanel.penneo',
    'root.controlpanel.report',
  ],
  specific: {
    applicationMailOffer: { pdflink: 'finansieringsforslag' },
    navTransferModal: { title: 'TODO' },
    commonConfig: { splitForsikringLegend: 'Fællespolice' },
    creditlinkSummary: {
      headerName: 'Creditlink'
    },
    asgBox: {
      afgiftBeregnetAfAsg: -1
    },
    externalPartnerChoice: {
      options: [],
      defaultChoice: ''
    },
    formCompanyCtrl: {
      async getModelVars($http: IHttpService): Promise<any> {
        return {
          hasAccess: true,
          visKontonummerFaktura: false,
          visKollegersAnsoegninger: false,
          selvbevillingAktiv: false,
          website: '',
          forhandlerlogoLilleMaxBreddeCm: 0,
          forhandlerlogoLilleMaxHoejdeCm: 0,
          forhandlerlogoStorMaxBreddeCm: 0,
          forhandlerlogoStorMaxHoejdeCm: 0,
          forhandlerlogoCenter: false,
          receiveNewsletters: false,
          kommentar: '',
          forhandlerUdenEkstraMaanedRegAfgift: false,
          forhandlerMedDigitalUnderskrift: false,
          visRente: false,
          visEnhedskartotek: false,
          stiftelsesprovisionFlexSplitMax: 0,
          lokalmarginal: 0,
          koerebogDemoUsername: '',
          koerebogDemoPassword: '',
          bilinfoUsername: '',
          bilinfoPassword: '',
          findLeasingApiToken: '',
          leverancegodkendelseNemId: false,
          enableDepositum: false,
        }
      }
    },
    formCompanyGroupCtrl: {
      getCommonConfig: false,
      defaultProductId: 1
    },
    formInsuranceCostsCtrl: {
      kind: [
        { value: 0, option: '18-20, <250HK' },
        { value: 1, option: '21-25, <250HK' },
        { value: 2, option: '>26, <250HK' },
        { value: 3, option: '>26, 251-350HK' },
        { value: 4, option: '>26, >350HK' },
        { value: 5, option: '>30, <250HK' },
        { value: 6, option: '>30, 251-350HK' },
        { value: 7, option: '>30, >350HK' },
      ]
    },
    formSelvanmelderCtrl: {
      options: {
        selvanmelder: [
          {
            value: false,
            option: 'Krone Kapital',
          },
          {
            value: true,
            option: 'PerB Finans',
          },
        ],
      }
    },
    formUserCtrl: {
      dmrEmailParts: [
        {
          name: 'Indregistrerede biler UDEN forsikring',
          id: 'aktivUdenForsikring',
        },
        {
          name: 'Biler uden indregistrering',
          id: 'udenIndregistrering',
        },
        {
          name: 'Biler med indregistrering, under etablering',
          id: 'medIndregistrering',
        },
        {
          name: 'Biler uden stelnummer i NAV',
          id: 'udenStelnummer',
        },
        {
          name: 'Biler med fællespolice men anden forsikring',
          id: 'fællespoliceAndenForsikring',
        },
        {
          name: 'Automatiske ændringer foretaget i NAV',
          id: 'navChanges',
        },
      ]
    },
    headerCtrl: {
      userLinks: true,
      alternateAccounts: false
    },
    registerRkiCtrl: {
      makeCalls: true
    },
    dkkapAnnualReports: {
      doCalls: false,
    },
    dkkapCreditReport: {
      doValidation: false,
      makeCalls: false
    },
    ngAutofield: {
      ngPercentageValueStr: '',
      makeDescription(varDef) {
        return '{{' + varDef.friendlyExpr + '}}'
      }
    },
    ngPercentage: {
      toFixedArgs() {
        return 2
      }
    },
    index: {
      showAftalenr: true,
      showId: false,
      showStatus: false,
      showUdloebsdato: true,
      columns: []
    },
    acceptComment: {
      showEnableExternalSideEffects: false,
      showDocsIncludeDeliveryApproval: false,
      showDocsIncludeInvoice: false,
      showPenneoCC: false
    }
  },
  terms: {
    downPayment: 'Første ydelse',
    administrationFee: 'Administrationsgebyr, købekontrakt',
  },
  creditlinkUrl: 'https://app.casehub.dk/admin/perbfinans/admin',
  friendlyFromDef(def): string {
    return (def as { friendly: string }).friendly
  },
  ComputationEngine: ComputationEngine,
}
