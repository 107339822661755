import { dateToDanish } from '@/shared/functions/dates'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import angular from 'angular'

import fixStartDatesTemplate from '@/shared/templates/fix-start-dates.html'

export const dkkapFixStartDates = [
  '$http',
  '$rootScope',
  '$timeout',
  function ($http, $rootScope, $timeout) {
    return {
      restrict: 'A',
      template: fixStartDatesTemplate,
      scope: {},
      link: function ($scope, elm, attrs) {
        $scope.rows = []
        $scope.sections = [
          {
            name: 'Ubehandlede sager',
            v: true,
          },
          {
            name: 'Tidligere behandlede sager',
            v: false,
          },
        ]
        $scope.showSection = { 0: true, 1: false }

        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(timestamp))
        }
        $scope.renderAmount = function (value) {
          return danishLocaleNumber(Math.ceil(value))
        }

        $scope.toggleDate = function (o, ts) {
          if (o.date === null || o.date.getTime() != ts) {
            o.date = new Date(ts)
          } else {
            o.date = null
          }
        }

        $scope.save = function (row) {
          row.saving = true
          $http
            .post('/api/fixStartDates/save', {
              kontraktnr: row.row.kontraktnr,
              dato: row.date.getTime(),
              kommentar: row.comment,
            })
            .success(function (xs) {
              loadAll()
            })
        }

        $scope.remove = function (row) {
          row.removing = true
          $http
            .post('/api/fixStartDates/save', {
              kontraktnr: row.row.kontraktnr,
              dato: null,
              kommentar: row.comment,
            })
            .success(function (xs) {
              loadAll()
            })
        }

        function loadAll() {
          $http.post('/api/fixStartDates/getAll', {}).success(function (xs) {
            $timeout(function () {
              $scope.rows = []
              angular.forEach(xs, function (row) {
                if (row.date) {
                  row.date = new Date(row.date)
                }
                $scope.rows.push(row)
              })

              angular.forEach($scope.sections, function (section) {
                var count = 0
                angular.forEach(xs, function (row) {
                  console.log(row)
                  if (!row.other == section.v) {
                    count++
                  }
                })
                section.count = count
              })
            })
          })
        }

        loadAll()
      },
    }
  },
]
