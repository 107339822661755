/*declare namespace Formula {
    function PMT(rate: number, periods: number, present: number, future: number, type:number): number
    function FV(rate: number, periods: number, payment: number, value: number, type: number): number
}*/

// @ts-ignore
import { Formula } from '../../computationengine/formula'

type SeasonApplication = {
  readonly start: Date
  readonly end: Date
  readonly interestRate: number
  readonly monthlyPayment: number
  readonly presentValue: number
  readonly duration: number
  readonly futureValue: number
  readonly paymentType: number
  readonly firstRegistrationDate: null | Date
  readonly regFee: number
  readonly regFeeInterestPct: number
}

type RegisteredSeason = {
  readonly start: Date
  readonly duration: number
  readonly regFee: null | number
  readonly regFeeInterestPct: null | number
}

type SeasonContractRange<T> = {
  readonly seasonIndex: number
  readonly registered: Boolean
  readonly start: T
  readonly end: Date
  readonly regFee: null | number
  readonly regFeeInterestPct: null | number
  readonly prepaidRegFee: null | number
  readonly firstRegistrationDate: null | Date // andel af presentValue udgjort af forudbetalt reg. afgift
}

type SeasonContract = SeasonContractRange<Date> & {
  readonly startPeriod: number
  readonly duration: number
}

type SeasonContractFull = SeasonContract & {
  readonly presentValue: number
  readonly futureValue: number
  readonly interests: ReadonlyArray<SeasonContractInterest>
  readonly months: ReadonlyArray<SeasonMonthWithDetails>
  readonly durationContract: number
  readonly futureValueContract: number
  readonly endContract: Date
  readonly monthlyPayment: number
  readonly interestRate: number
  readonly changeInInterestRate: number
  readonly seasonNumber: number
}

type InterestRateChange = {
  readonly start: Date
  readonly interestRateChange: number
}

type SeasonInterestPerDate = {
  readonly start: Date
  readonly interestRate: number
}

type SeasonInterest = SeasonInterestPerDate & {
  readonly end: Date
  readonly startPeriod: number
  readonly duration: number
  readonly presentValue: number
  readonly futureValue: number
  readonly monthlyPayment: number
}

type SeasonMonth = {
  readonly start: Date
  readonly end: Date
  readonly startPeriod: number
  readonly duration: number
  readonly interestRate: number
}

type SeasonMonthWithDetails = SeasonMonth & {
  readonly presentValue: number
  readonly futureValue: number
  readonly payment: number
}

type SeasonContractInterest = {
  readonly start: Date
  readonly end: Date
  readonly interestRate: number
  readonly startPeriod: number
  readonly duration: number
  readonly presentValue: number
  readonly futureValue: number
  readonly monthlyPayment: number
  readonly months: ReadonlyArray<SeasonMonthWithDetails>
  readonly futureValueContract: number
}

export function modifyMonth(d: Date, delta: number): Date {
  const newMonth = (12 + ((d.getUTCMonth() + delta) % 12)) % 12
  const yearDelta = Math.floor((d.getUTCMonth() + delta) / 12)

  const firstOfMonth = new Date(Date.UTC(d.getUTCFullYear() + yearDelta, newMonth, 1))

  const d0 = lastDayOfMonth(firstOfMonth)
  const d1 = d.getUTCDate()

  return new Date(Date.UTC(d.getUTCFullYear() + yearDelta, newMonth, d0 > d1 ? d1 : d0))
}

export function modifyDate(d: Date, delta: number): Date {
  const out = new Date(d.getTime())
  out.setUTCDate(d.getUTCDate() + delta)
  return out
}

function lastDayOfMonth(d: Date): number {
  return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth() + 1, 0, 0, 0, 0, 0)).getUTCDate()
}

function normalizedDayNumber(date: Date): number {
  if (lastDayOfMonth(date) == date.getUTCDate()) {
    return 30
  } else {
    return date.getUTCDate()
  }
}

function dateMonthDiff(startDate: Date, endDate: Date, exclusive: boolean): number {
  const exclusiveNum = exclusive ? 1 : 0
  const yearDiff = endDate.getUTCFullYear() - startDate.getUTCFullYear()
  const monthDiff = endDate.getUTCMonth() - startDate.getUTCMonth()
  const dayDiff = normalizedDayNumber(endDate) - exclusiveNum - (normalizedDayNumber(startDate) - 1)

  return 12 * yearDiff + monthDiff + dayDiff / 30
}

class PrepaidRegFeeUtil {
  prepaidRegFeePct(age: number, duration: number): number {
    const pct =
      Math.max(0, Math.min(3, age + duration) - Math.max(0, age)) * 0.02 +
      Math.max(0, Math.min(36, age + duration) - Math.max(3, age)) * 0.01 +
      Math.max(0, age + duration - Math.max(36, age)) * 0.005
    return pct
  }

  prepaidRegFeeForPeriod(age: number, duration: number, regFee: number): number {
    return this.prepaidRegFeePct(age, duration) * regFee
  }

  remainingRegFee(age: number, duration: number, regFee: number): number {
    return regFee - this.prepaidRegFeeForPeriod(age, duration, regFee)
  }

  interestOfRemainingRegFee(age: number, duration: number, regFee: number, feeInterestPct: number): number {
    return (this.remainingRegFee(age, duration, regFee) * feeInterestPct * duration) / 12
  }

  prepaidRegFee(age: number, duration: number, regFee: number, feeInterestPct: number): number {
    return (
      this.prepaidRegFeeForPeriod(age, duration, regFee) +
      this.interestOfRemainingRegFee(age, duration, regFee, feeInterestPct)
    )
  }
}

export class SeasonContractUtil {
  readonly prepaidRegFeeUtil = new PrepaidRegFeeUtil()

  getSeasonContractRanges(
    application: SeasonApplication,
    seasons: ReadonlyArray<RegisteredSeason>,
  ): ReadonlyArray<SeasonContract> {
    const partialRanges: ReadonlyArray<SeasonContractRange<null | Date>> = seasons
      .map((s, i) => {
        const seasonIndex = i
        const unreg: SeasonContractRange<null | Date> = {
          seasonIndex,
          start: null,
          end: modifyDate(s.start, -1),
          registered: false,
          prepaidRegFee: null,
          regFee: null,
          regFeeInterestPct: null,
          firstRegistrationDate: null,
        }

        const reg: SeasonContractRange<null | Date> = {
          seasonIndex,
          start: s.start,
          end: modifyDate(modifyMonth(s.start, s.duration), -1),
          registered: true,
          prepaidRegFee: null,
          regFee: s.regFee,
          regFeeInterestPct: s.regFeeInterestPct,
          firstRegistrationDate: null,
        }

        return [unreg, reg]
      })
      .reduce((xs, ys) => xs.concat(ys), [])
      .concat({
        seasonIndex: seasons.length,
        start: null,
        end: modifyDate(modifyMonth(application.start, application.duration), -1),
        registered: false,
        prepaidRegFee: null,
        regFee: null,
        regFeeInterestPct: null,
        firstRegistrationDate: null,
      })

    const monthlyPayment = -Formula.PMT(
      application.interestRate / 12,
      application.duration,
      application.presentValue,
      -application.futureValue,
      application.paymentType,
    )

    // fill out the missing dates!
    const rangesTemp = partialRanges
      .map((r, i) => {
        const startFallback: Date = i > 0 ? modifyDate(partialRanges[i - 1].end, 1) : application.start
        const start = r.start || startFallback

        return {
          ...r,
          start,
        }
      })
      .filter((r) => r.start.getTime() <= r.end.getTime()) // remove empty/invalid intervals

    const ranges: ReadonlyArray<SeasonContract> = rangesTemp
      .map((x, i) => {
        const startPeriod = dateMonthDiff(application.start, x.start, true)
        const durationTemp = dateMonthDiff(x.start, x.end, false)

        return {
          ...x,
          startPeriod,
          duration: durationTemp,
        }
      })
      .reduce<ReadonlyArray<SeasonContract>>((xs, x, i) => {
        if (x.registered) {
          const prevRegSeasons = xs.filter((prev) => prev.registered)
          const firstRegSeason = prevRegSeasons.length > 0 ? prevRegSeasons[0] : null
          const lastRegSeason = prevRegSeasons.length > 0 ? prevRegSeasons[prevRegSeasons.length - 1] : null
          const lastRegSeasonFee = lastRegSeason ? lastRegSeason.regFee : null
          const lastRegSeasonFeePct = lastRegSeason ? lastRegSeason.regFeeInterestPct : null

          const mostRecentRegFeePct: number =
            x.regFeeInterestPct || lastRegSeasonFeePct || application.regFeeInterestPct
          const mostRecentRegFee: number = x.regFee || lastRegSeasonFee || application.regFee
          const firstRegSeasonDate: Date = firstRegSeason && firstRegSeason ? firstRegSeason.start : x.start
          const lastRegSeasonDate: Date = lastRegSeasonFee && lastRegSeason ? lastRegSeason.start : x.start
          const mostRecentRegFeeDate: Date = x.regFee ? x.start : lastRegSeasonDate

          // fremskriv nu denne registereringsafgift til x.start
          const ageRegFeeDate: number = Math.floor(
            dateMonthDiff(application.firstRegistrationDate || firstRegSeasonDate, mostRecentRegFeeDate, true),
          )
          const ageStartDate: number = Math.floor(
            dateMonthDiff(application.firstRegistrationDate || firstRegSeasonDate, x.start, true),
          )

          const regFee: number = this.prepaidRegFeeUtil.remainingRegFee(
            ageRegFeeDate,
            ageStartDate - ageRegFeeDate,
            mostRecentRegFee,
          )

          const firstRegDateFallback = prevRegSeasons.length > 0 ? prevRegSeasons[0].start : x.start
          const firstRegDate: Date = application.firstRegistrationDate || firstRegDateFallback

          const prepaidRegFee: number = this.prepaidRegFeeUtil.prepaidRegFee(
            ageStartDate,
            x.duration,
            regFee,
            mostRecentRegFeePct,
          )

          return xs.concat([
            {
              ...x,
              regFee,
              regFeeInterestPct: mostRecentRegFeePct,
              prepaidRegFee: prepaidRegFee,
              firstRegistrationDate: firstRegDate,
            },
          ])
        } else {
          const prevRegSeasons = xs.filter((prev) => prev.registered)

          const firstRegDateFallback = prevRegSeasons.length > 0 ? prevRegSeasons[0].start : null
          const firstRegDate: null | Date = application.firstRegistrationDate || firstRegDateFallback
          return xs.concat([{ ...x, firstRegistrationDate: firstRegDate }])
        }
      }, [])

    return ranges
  }

  getSeasonApplicationInterestRates(
    application: SeasonApplication,
    interestPerDate: ReadonlyArray<InterestRateChange>,
  ): ReadonlyArray<SeasonInterest> {
    const irChanges = interestPerDate.filter(
      (irc) =>
        irc.start.getTime() > application.start.getTime() &&
        irc.start.getTime() < modifyMonth(application.start, application.duration).getTime(),
    )

    const sipr = irChanges.reduce<ReadonlyArray<SeasonInterestPerDate>>(
      (xs: ReadonlyArray<SeasonInterestPerDate>, x: InterestRateChange) => {
        return xs.concat([
          {
            start: x.start,
            interestRate: xs[xs.length - 1].interestRate + x.interestRateChange,
          },
        ])
      },
      [
        {
          start: application.start,
          interestRate: application.interestRate,
        },
      ],
    )

    return sipr.reduce<ReadonlyArray<SeasonInterest>>((prevIRs, ir, i) => {
      const end = modifyDate(
        i < sipr.length - 1 ? sipr[i + 1].start : modifyMonth(application.start, application.duration),
        -1,
      )
      const startPeriod = dateMonthDiff(application.start, ir.start, true)
      const duration = dateMonthDiff(ir.start, end, false)
      const presentValue = prevIRs.length > 0 ? prevIRs[prevIRs.length - 1].futureValue : application.presentValue

      const monthlyPayment = -Formula.PMT(
        ir.interestRate / 12,
        application.duration - startPeriod,
        presentValue,
        -application.futureValue,
        application.paymentType,
      )
      const futureValue = -Formula.FV(
        ir.interestRate / 12,
        duration,
        -monthlyPayment,
        presentValue,
        application.paymentType,
      )

      const temp: SeasonInterest = {
        ...ir,
        end,
        startPeriod,
        duration,
        presentValue,
        monthlyPayment,
        futureValue,
      }
      return prevIRs.concat([temp])
    }, [])
  }

  getSeasonContractsFull(
    application: SeasonApplication,
    seasonContracts: ReadonlyArray<SeasonContractRange<Date>>,
    seasonInterestPerDate: ReadonlyArray<SeasonInterest>,
  ): ReadonlyArray<SeasonContractFull> {
    return seasonContracts.reduce<ReadonlyArray<SeasonContractFull>>((xs, x, i) => {
      const presentValueWithoutFee = i == 0 ? application.presentValue : xs[i - 1].futureValue

      const presentValueContract =
        x.registered && x.prepaidRegFee ? presentValueWithoutFee + x.prepaidRegFee : presentValueWithoutFee

      const prevIRs = seasonInterestPerDate.filter((ir) => ir.start.getTime() <= x.start.getTime())
      const currentIRs = seasonInterestPerDate.filter(
        (ir) => ir.start.getTime() > x.start.getTime() && ir.start.getTime() <= x.end.getTime(),
      )
      const relevantIRs = (prevIRs.length > 0 ? [prevIRs[prevIRs.length - 1]] : []).concat(currentIRs)
      // relevant IRs er de rentesatser der benyttes i løbet af perioden for kontrakt x.

      const endContractTemp: Date = x.registered
        ? x.end
        : modifyDate(modifyMonth(application.start, application.duration), -1) /* slutdato for kontrakt, uafrundet */
      const durationContractTemp: number = dateMonthDiff(x.start, endContractTemp, false)
      const durationContract: number = Math.ceil(durationContractTemp)
      const endContract: Date = modifyDate(modifyMonth(x.start, durationContract), -1)

      const interests = relevantIRs.reduce<ReadonlyArray<SeasonContractInterest>>((processedIRs, ir, i) => {
        const presentValue = i == 0 ? presentValueContract : processedIRs[i - 1].futureValue

        const start: Date = ir.start.getTime() < x.start.getTime() ? x.start : ir.start
        const end: Date = relevantIRs.length > i + 1 ? modifyDate(relevantIRs[i + 1].start, -1) : x.end

        const skewStartArray = start.getUTCDate() > 1 ? [-1] : []
        const startRegular =
          start.getUTCDate() > 1
            ? new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth() + 1, 1, 0, 0, 0, 0))
            : start
        const monthDiff = dateMonthDiff(startRegular, end, false)

        const monthIndexes = skewStartArray.concat(Array.from({ length: Math.ceil(monthDiff) }, (_, idx) => idx))
        // ^  https://www.jstips.co/en/javascript/create-range-0...n-easily-using-one-line/
        // Med Array(N).map((_, idx) => idx) havde vi nogle mærkelige problemer, med et array uden tal [_,_,_,..] istedet for [0,1,2,..].
        // Dette er blevet løst med nyeste version

        const irStartPeriod = dateMonthDiff(application.start, start, true)
        const duration = dateMonthDiff(start, end, false)

        const durationSinceLastIR = dateMonthDiff(ir.start, end, false)
        const durationSinceLastIRContract = dateMonthDiff(ir.start, endContract, false)

        const futureValue = -Formula.FV(
          ir.interestRate / 12,
          durationSinceLastIR,
          -ir.monthlyPayment,
          ir.presentValue,
          application.paymentType,
        ) // Rentesatsens monthly payment + present value benyttes
        const monthlyPayment = -Formula.PMT(
          ir.interestRate / 12,
          duration,
          presentValue,
          -futureValue,
          application.paymentType,
        ) // ønsket present value benyttes sammen med

        const futureValueContract = -Formula.FV(
          ir.interestRate / 12,
          durationSinceLastIRContract,
          -ir.monthlyPayment,
          ir.presentValue,
          application.paymentType,
        ) // Rentesatsens monthly payment + present value benyttes

        const months: ReadonlyArray<SeasonMonthWithDetails> = monthIndexes.map((m) => {
          const monthStart = m == -1 ? start : modifyMonth(startRegular, m)
          const monthEndTemp = modifyDate(m == -1 ? startRegular : modifyMonth(startRegular, m + 1), -1)
          const monthEnd = monthEndTemp.getTime() > end.getTime() ? end : monthEndTemp
          const monthStartPeriod = dateMonthDiff(application.start, monthStart, true)
          const monthDuration = dateMonthDiff(monthStart, monthEnd, false)

          const pv = -Formula.FV(
            ir.interestRate / 12,
            monthStartPeriod - irStartPeriod,
            -monthlyPayment,
            presentValue,
            application.paymentType,
          )
          const fv = -Formula.FV(
            ir.interestRate / 12,
            monthStartPeriod - irStartPeriod + monthDuration,
            -monthlyPayment,
            presentValue,
            application.paymentType,
          )

          return {
            start: monthStart,
            end: monthEnd,
            startPeriod: monthStartPeriod,
            duration: monthDuration,
            interestRate: ir.interestRate,
            presentValue: pv,
            futureValue: fv,
            payment: monthDuration * monthlyPayment,
          }
        })

        const elem: SeasonContractInterest = {
          interestRate: ir.interestRate,
          start,
          end,
          presentValue,
          startPeriod: irStartPeriod,
          duration: dateMonthDiff(start, end, false),
          monthlyPayment: monthlyPayment,
          futureValue: months[months.length - 1].futureValue,
          months,
          futureValueContract,
        }

        return processedIRs.concat([elem])
      }, [])

      const fullContract: SeasonContractFull = {
        ...x,
        seasonNumber: i + 1,
        startPeriod: interests[0].startPeriod,
        presentValue: interests[0].presentValue,
        futureValue: interests[interests.length - 1].futureValue,
        duration: dateMonthDiff(x.start, x.end, false),

        interests,
        months: interests.map((x) => x.months).reduce((xs, ys) => xs.concat(ys), []),
        durationContract,
        endContract,
        futureValueContract: interests[interests.length - 1].futureValueContract,

        monthlyPayment: interests[0].monthlyPayment,
        interestRate: interests[0].interestRate,
        changeInInterestRate:
          interests.length > 1 ? interests[interests.length - 1].interestRate - interests[0].interestRate : 0,
      }

      return xs.concat([fullContract])
    }, [])
  }
}
