import warningDeleteTemplate from '@/shared/templates/modals/warningDelete.html'
import applicationConditionTemplate from '@/shared/templates/modals/applicationCondition.html'
import applicationReasonTemplate from '@/shared/templates/modals/applicationReason.html'

export const indexCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$modal',
  '$q',
  '$timeout',
  function ($scope, $rootScope, $http, $modal, $q, $timeout) {
    $scope.self = $scope
    $scope.activeTab = 0

    $scope.getContractDropdown = function (a, $hide) {
      if (a.approvalStatus === true) {
        return [
          {
            text: 'Hent kontrakt MED kundekopi',
            click: $hide,
            href: '/api/application/contract/' + a.id + '/1',
            target: '_blank',
          },
          {
            text: 'Hent kontrakt UDEN kundekopi',
            click: $hide,
            href: '/api/application/contract/' + a.id + '/0',
            target: '_blank',
          },
        ]
      }
    }

    $scope.statusTypes = {
      0: 'Kladder',
      1: 'Afventer godkendelse',
      2: 'Afslåede',
      3: 'Godkendte de sidste 8 dage',
      4: 'Godkendt tidligere',
      5: 'Under behandling af mig',
      6: 'Kladdearkiv',
      7: $scope.userInfo ? 'Sendt til ' + $scope.userInfo.externalName : 'Sendt til ekstern',
      8: 'Under behandling af andre',
      9: 'FindLeasing',
    }

    $scope.$on('userInfoLoaded', function () {
      $scope.statusTypes['7'] = 'Sendt til ' + $scope.userInfo.externalName
    })

    $scope.statusTypeOrdering = ['9', '1', '5', '8', '0', '3', '4', '6', '2', '7']

    $scope.applicationsLoaded = false
    $scope.emptyResult = true

    $http.post('/api/news/getActiveUserNewsNotifications').success(function (res) {
      $scope.newsNotifications = res
    })
    $scope.newsNotificationHidden = {}

    $scope.hideNewsNotification = function (id) {
      $http.post('/api/news/hideActiveUserNotification/' + id)
      $scope.newsNotificationHidden[id] = true
    }

    $scope.showNewsNotification = function (news) {
      return !$scope.newsNotificationHidden[news.id]
    }

    if ($scope.userInfo !== undefined && $scope.userInfo.tableState !== undefined) {
      $scope.tableState = JSON.parse($scope.userInfo.tableState)
      if ($scope.tableState.orderBy === undefined) $scope.tableState.orderBy = 'product'
      if ($scope.tableState.reverse === undefined) $scope.tableState.reverse = false
      if ($scope.tableState.hide === undefined) $scope.tableState.hide = {}
    } else {
      $scope.tableState = {}
      if ($scope.tableState.orderBy === undefined) $scope.tableState.orderBy = 'product'
      if ($scope.tableState.reverse === undefined) $scope.tableState.reverse = false
      if ($scope.tableState.hide === undefined) $scope.tableState.hide = {}
    }

    $scope.$watch('userInfo', function () {
      if ($scope.userInfo !== undefined && $scope.userInfo.tableState !== undefined) {
        $scope.tableState = JSON.parse($scope.userInfo.tableState)
        if ($scope.tableState.orderBy === undefined) $scope.tableState.orderBy = 'product'
        if ($scope.tableState.reverse === undefined) $scope.tableState.reverse = false
        if ($scope.tableState.hide === undefined) $scope.tableState.hide = {}
      }
    })

    var currentReload = undefined
    var currentTimeout = undefined
    function reloadTable() {
      if (currentReload !== undefined) {
        currentReload.resolve()
      }
      currentReload = $q.defer()

      var url = '/api/application/getPage'
      if ($scope.userId !== undefined) {
        url += '/' + $scope.userId
      }

      $http
        .post(
          url,
          {
            page: $rootScope.currentPage,
            perpage: $scope.perPage,
            tableState: $scope.tableState,
            statusTypeOrdering: $scope.statusTypeOrdering,
            searchString: $rootScope.searchString,
          },
          { timeout: currentReload.promise },
        )
        .success(function (data) {
          $scope.pageCount = data.pageCount
          $scope.pages = []
          for (var i = 1; i <= $scope.pageCount; i++) {
            $scope.pages.push(i)
          }
          $scope.applications = data.groups
          $scope.applicationsLoaded = true
          if (!angular.equals([], $scope.applications)) {
            $scope.emptyResult = false
          }
        })
    }

    $scope.setPage = function (p) {
      $rootScope.currentPage = p
    }
    $scope.self = $scope
    $scope.root = $rootScope
    if (!$rootScope.searchString) {
      $rootScope.searchString = ''
    }
    $scope.$watch('userInfo', function () {
      if ($scope.userInfo !== undefined) {
        $scope.userId = $scope.userInfo.id
        $scope.userLookup = {}

        $scope.users = []
        $http.post('/api/company/getAll').success(function (companies) {
          // når denne er hentet laves
          var companyLookup = {}
          for (var i in companies) {
            companyLookup['' + companies[i].id] = companies[i]
          }

          $http.post('/api/user/getAllByCompanyId').success(function (res) {
            for (var companyId in res) {
              if ($scope.userInfo.isSuperAdmin || companyId == $scope.userInfo.companyId) {
                for (var i in res[companyId]) {
                  $scope.userLookup['' + res[companyId][i].id] = {
                    user: res[companyId][i],
                    company: companyLookup[companyId],
                  }

                  $scope.users.push({
                    id: res[companyId][i].id,
                    name: res[companyId][i].name,
                    company: companyLookup[companyId].name,
                  })
                }
              }
            }
          })
        })
      }
    })
    if (!$rootScope.currentPage) {
      $rootScope.currentPage = 1
    }

    $scope.perPage = 20
    reloadTable()

    $scope.$watch('userId', function (p, p1) {
      if (p != p1) {
        reloadTable()
      }
    })
    $scope.$watch('currentPage', function (p, p1) {
      if (p != p1) {
        reloadTable()
      }
    })
    $scope.$watch('tableState', function (p, p1) {
      if (p != p1) {
        reloadTable()
      }
    })
    $rootScope.$watch('searchString', function (p, p1) {
      if (p != p1) {
        if (currentTimeout !== undefined) {
          $timeout.cancel(currentTimeout)
        }

        currentTimeout = $timeout(function () {
          $scope.setPage(1)
          reloadTable()
        }, 150)
      }
    })

    var persistTableState = function () {
      reloadTable()
      $scope.userInfo.tableState = JSON.stringify($scope.tableState)
      $http.post('/api/setTableState', $scope.tableState)
    }

    $scope.toggleNewsNotifications = function () {
      $scope.tableState.hideNewsNotifications = !$scope.tableState.hideNewsNotifications
      persistTableState()
    }

    $scope.setOrder = function (field) {
      $scope.tableState.reverse = $scope.tableState.orderBy == field ? !$scope.tableState.reverse : false
      $scope.tableState.orderBy = field

      // Gå til den side den nuværende åbne starter, hvis vi er midt nede i en!
      var sub = Math.ceil(($scope.applications[0].intervalFrom - 1) / $scope.perPage)
      $scope.currentPage -= sub

      persistTableState()
    }

    $scope.setHide = function (key) {
      if ($scope.tableState.hide === undefined) {
        $scope.tableState.hide = {}
      }
      $scope.tableState.hide[key] = !$scope.tableState.hide[key]
      if ($scope.tableState.hide[key]) {
        // vi har netop skjult noget der blev vist før!
        for (var i in $scope.applications) {
          if ($scope.applications[i].statusKey == key) {
            var sub = Math.ceil(($scope.applications[i].intervalFrom - 1) / $scope.perPage)
            $rootScope.currentPage -= sub
          }
        }
      }

      persistTableState()
    }

    $scope.delete = function (id) {
      $scope.currentDelete = id
      $modal({ template: warningDeleteTemplate, show: true, scope: $scope })
    }
    $scope.condition = function (a) {
      var subscope = $scope.$new(true)
      subscope.a = a
      subscope.getContractDropdown = $scope.getContractDropdown
      subscope.closeModal = function () {
        subscope.$hide()
      }
      $modal({ template: applicationConditionTemplate, show: true, scope: subscope })
    }

    $scope.reason = function (a) {
      var subscope = $scope.$new(true)
      subscope.a = a

      $modal({ template: applicationReasonTemplate, show: true, scope: subscope })
    }

    $scope.deleteDo = function () {
      //console.log($scope.currentDelete);

      $http.post('/api/application/delete/' + $scope.currentDelete).success(function () {
        reloadTable()
      })
    }
  },
]
