
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/report.adjustedFees.html";
      const src = "<h3>Ændrede gebyrer</h3>\n\n<!--\nValg af periode [måned]\n-->\n\n<form class=\"form-horizontal\" method=\"GET\" action=\"/api/xlsx/adjustedFees\" target=\"_blank\">\n  <div class=\"form-group\">\n    <label for=\"month\" class=\"col-xs-4 control-label\">Etableringsmåned (månedsdag ignoreres)</label>\n    <div class=\"col-xs-4\">\n      <input\n        style=\"width: 100%\"\n        id=\"month\"\n        type=\"text\"\n        ng-model=\"modelVars.month\"\n        class=\"btn btn-default\"\n        bs-datepicker\n        data-placement=\"bottom-right\"\n        data-start-week=\"1\"\n        data-lang=\"da\"\n        data-start-view=\"2\"\n        data-autoclose=\"true\"\n        data-max-date=\"today\"\n        data-date-format=\"MMMM yyyy\"\n        autocomplete=\"off\"\n      />\n      <input type=\"hidden\" ng-value=\"modelVars.period\" name=\"period\" />\n    </div>\n    <div class=\"col-xs-4\">\n      <input style=\"width: 100%\" type=\"submit\" class=\"btn btn-default\" value=\"Hent Excel-rapport\" />\n    </div>\n  </div>\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      