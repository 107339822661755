import { dateToYMD, dateToDanish } from '@/shared/functions/dates'
import angular from 'angular'

import codanFaellespoliceSyncTemplate from '@/shared/templates/codan-faellespolice-sync.html'
import codanDetailsTemplate from '@/shared/templates/modals/codanDetails.html'

export const dkkapCodanFaellespoliceSync = [
  '$http',
  '$rootScope',
  '$timeout',
  '$upload',
  '$modal',
  function ($http, $rootScope, $timeout, $upload, $modal) {
    return {
      restrict: 'A',
      template: codanFaellespoliceSyncTemplate,
      scope: {},
      link: function ($scope, elm, attrs) {
        $scope.dateToDanish = dateToDanish
        $scope.dateToYMD = dateToYMD
        $scope.detailsLoading = true

        $scope.uploadFiles = function (files) {
          $scope.uploading = true
          $upload
            .upload({
              url: '/api/codan/upload',
              file: files,
            })
            .success(function () {
              $scope.uploading = true
              loadAll()
            })
        }

        $scope.showDetails = function (kontraktnr) {
          var subScope = $scope.$new(false)
          subScope.details = $scope.insuranceCost && $scope.insuranceCost[kontraktnr]

          $modal({ template: codanDetailsTemplate, show: true, scope: subScope })
          console.log(kontraktnr)
        }

        function loadAll() {
          $scope.rows = null
          $scope.insuranceCost = null

          $http.post('/api/codan/insuranceCost', {}).success(function (m) {
            $scope.insuranceCost = m
            $scope.detailsLoading = false
          })

          $http.post('/api/codan/differences', {}).success(function (xs) {
            $timeout(function () {
              $scope.rows = []
              angular.forEach(xs, function (row) {
                if (row.startNav) {
                  row.startNav = new Date(row.startNav)
                }
                if (row.startCodan) {
                  row.startCodan = new Date(row.startCodan)
                }
                $scope.rows.push(row)
              })
            })
          })
        }

        loadAll()
      },
    }
  },
]
