import { errorCount } from '../functions/validationErrors'

export const formUserCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  '$timeout',
  '$interop',
  '$modal',
  function ($scope, $rootScope, $http, $stateParams, $location, $state, $timeout, $interop, $modal) {
    $scope.errorCount = errorCount
    $scope.modelVars = { password: '', sendWelcomeMail: false, hasLogin: true }
    var tempId = parseInt($stateParams.id, 10)
    var tempUserId = parseInt($stateParams.userId, 10)

    // NaN håndtering
    if (tempId !== tempId) tempId = undefined
    if (tempUserId !== tempUserId) tempUserId = undefined

    $scope.$watch('modelVars.password', function (pw) {
      $scope.modelVars.sendWelcomeMail = pw != '' && $state.includes('root.controlpanel')
    })

    $scope.currentlyValidatingMail = false
    $scope.timeoutHandle = null
    $scope.setActiveId(tempId)
    $scope.setActiveUserId(tempUserId)
    $scope.modelVars.mailIsValid = true

    $scope.variableDefs = {
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      firstName: {
        friendly: 'Fornavn',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      title: {
        friendly: 'Titel',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      birthday: {
        friendly: 'Fødselsdag',
        datatype: 'date',
        vartype: 'model',
        required: false,
      },
      initials: {
        friendly: 'Initialer',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      position: {
        friendly: 'Stilling',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      mail: {
        friendly: 'E-mail adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      foqusSalespersonCode: {
        friendly: 'Foqus Finance, Sælgerkode',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['firstlease'],
        },
      },
      hasLogin: {
        friendly: 'Primær konto (Bruges til login)',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['alternateAccounts'],
        },
      },
      password: {
        friendly: 'Password',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      phone: {
        friendly: 'Telefon',
        datatype: 'digits',
        vartype: 'model',
        required: true,
      },
      mobile: {
        friendly: 'Mobil',
        datatype: 'digits',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['user.mobile'],
        },
      },
      kladdearkivDage: {
        friendly: 'Antal dage til kladdearkiv (0 = standardindstilling)',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      asgEmail: {
        friendly: 'ASG Email',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
      asgPassword: {
        friendly: 'ASG Password',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
    }

    //$scope.delete = function() {

    // prøv at slette brugeren
    /*
        $scope.deleteModal.alert = 'Sletning kunne ikke gennemføres';
        return false;*/
    //$scope.setGlobalAlert('Brugeren blev slettet!');
    //$location.url('/controlpanel/company');
    //return true;

    //}

    $scope.dmrEmailParts = $interop.specific.formUserCtrl.dmrEmailParts

    $scope.modelVars.dmrEmailConfig = {}
    for (var i in $scope.dmrEmailParts) {
      $scope.modelVars.dmrEmailConfig[$scope.dmrEmailParts[i].id] = false
    }

    $http.post('/api/company/getAll').success(function (res) {
      $scope.companies = res
      if ($scope.modelVars.companyId === undefined) {
        if ($stateParams.id === undefined) {
          // Stamdata og brugere
          $scope.modelVars.companyId = $rootScope.userInfo.companyId
        } else {
          $scope.modelVars.companyId = res[0].id
        }
      }
    })

    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Brugeren blev gemt.')

      if ($scope.userId === undefined) {
        if ($state.includes('root.controlpanel')) {
          $location.url('/controlpanel/company/edit/' + $scope.modelVars.companyId + '/' + res)
        } else {
          $location.url('/company/user/edit/' + res)
        }
      } else {
        $scope.setKeepAlert(false)
      }
    }

    $scope.validateMail = function () {
      if ($scope.modelVars.hasLogin) {
        $scope.currentlyValidatingMail = true
        clearTimeout($scope.timeoutHandle)
        $scope.timeoutHandle = setTimeout(function () {
          if ($scope.modelVars.hasLogin) {
            $http
              .post('/api/user/validateMail', {
                mail: $scope.modelVars.mail || '',
                id: $scope.userId,
              })
              .success(function (res) {
                $timeout(() => {
                  $scope.modelVars.mailIsValid = res.result
                  $scope.currentlyValidatingMail = false
                })
              })
          } else {
            $timeout(() => {
              $scope.modelVars.mailIsValid = true
              $scope.currentlyValidatingMail = false
            })
          }
        }, 400)
      } else {
        $scope.modelVars.mailIsValid = true
        $scope.currentlyValidatingMail = false
      }
    }

    $scope.$watch('modelVars.hasLogin', $scope.validateMail)

    if ($scope.userId !== undefined) {
      var loadUser = function () {
        $http.post('/api/user/get/' + $stateParams.userId).success(function (res) {
          for (var i in res) {
            if (i === 'birthday' && res[i] != null) {
              $scope.modelVars[i] = new Date(res[i])
            } else if (i === 'hasLogin') {
              $scope.modelVars[i] = res[i] === 'TRUE'
            } else if (i !== 'dmrEmailConfig') {
              $scope.modelVars[i] = res[i]
            }
          }

          if (res.dmrEmailConfig !== '' && res.dmrEmailConfig !== '{}') {
            $scope.modelVars.dmrEmailConfig = JSON.parse(res.dmrEmailConfig)
          }

          $scope.formIsLoaded = true
        })
      }

      $scope.save = function () {
        var param = {}
        for (var i in $scope.modelVars) {
          if ($scope.modelVars[i] instanceof Date) {
            param[i] = $scope.modelVars[i].getTime()
          } else {
            param[i] = $scope.modelVars[i]
          }
        }

        $http.post('/api/user/edit/' + $stateParams.userId, param).success(saveSuccess)
      }
      loadUser()

      $scope.deactivate = function () {
        $http.post('/api/user/deactivate/' + $scope.userId).success(function () {
          $scope.setGlobalAlert('Brugeren blev deaktiveret.')
          loadUser()
          $scope.loadAll()
        })
      }

      $scope.activate = function () {
        $http.post('/api/user/activate/' + $scope.userId).success(function () {
          $scope.setGlobalAlert('Brugeren blev genaktiveret.')
          loadUser()
          $scope.loadAll()
        })
      }

      $scope.showDelete = function () {
        const modalScope = $scope.$new(true)
        modalScope.vars = { replacementUserId: undefined }

        modalScope.doDelete = function () {
          $http.post('/api/user/delete/' + $scope.userId, modalScope.vars).success(function () {
            $location.url('/controlpanel/company/edit/' + $scope.modelVars.companyId + '/' + $scope.id)
            $scope.setGlobalAlert('Bruger slettet.')
            $scope.loadAll()
          })
        }
        modalScope.users = []
        const companyId = $scope.id
        for (const i in $scope.userList[companyId]) {
          modalScope.users.push({
            id: $scope.userList[companyId][i].id,
            name: $scope.userList[companyId][i].name,
            deactivated: $scope.userList[companyId][i].deactivated,
            company: $scope.list.find(c => c.id === companyId).name,
          })
        }

        const template = `
        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" ng-click="$hide()">&times;</button>
                <h4 class="modal-title">Slet bruger?</h4>
              </div>
              <div class="modal-body">
                <div class="form-horizontal">
                  <p>Er du sikker på at du vil slette brugeren? Hvis du sletter brugeren, skal du vælge en anden bruger, som overtager brugerens nuværende kladdesager.</p>
                  <div class="form-group">
                    <label for="select" class="col-xs-4 control-label" infotext-click="userId">Vælg ny sælger</label>
                    <div class="col-xs-8">
                      <select
                        ng-model="vars.replacementUserId"
                        class="form-control"
                        id="select"
                        ng-options="u.id as u.name group by u.company for u in users |  orderBy:['company','name']"
                      ></select>
                    </div>
                  </div>
                
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" ng-click="$hide()">Nej</button>
                <button type="button" class="btn btn-danger" ng-disabled="!vars.replacementUserId" ng-click="doDelete(); $hide()">Ja</button>
              </div>
            </div>
          </div>
        </div>`
        $modal({
          template,
          show: true,
          scope: modalScope,
        })
      }

      $scope.activateAPI = function () {
        $http.post('/api/external/activate/' + $scope.userId).success(function () {
          $scope.setGlobalAlert('API blev genaktiveret.')
          loadUser()
          $scope.loadAll()
        })
      }
      $scope.deactivateAPI = function () {
        $http.post('/api/external/deactivate/' + $scope.userId).success(function () {
          $scope.setGlobalAlert('API blev deaktiveret.')
          loadUser()
          $scope.loadAll()
        })
      }
      $scope.resetAPI = function () {
        $http.post('/api/external/reset/' + $scope.userId).success(function () {
          loadUser()
          $scope.loadAll()
        })
      }
    } else {
      $scope.modelVars.isCompanyAdmin = false
      $scope.modelVars.isSuperAdmin = false
      $scope.modelVars.initials = ''
      $scope.modelVars.position = ''
      $scope.modelVars.companyId = tempId
      $scope.modelVars.kladdearkivDage = 0
      $scope.modelVars.title = ''
      $scope.modelVars.firstName = ''
      $scope.modelVars.birthday = null
      $scope.selectedProducts = {}
      $scope.modelVars.asgEmail = ''
      $scope.modelVars.asgPassword = ''
      $scope.modelVars.alternateAccounts = ''
      $scope.modelVars.foqusSalespersonCode = ''
      $scope.modelVars.isCreditAnalysisApproverDisposableIncome = false
      $scope.modelVars.isCreditAnalysisApproverContract = false
      $scope.modelVars.isCreditAnalysisApproverKyc = false

      $scope.save = function () {
        var param = {}
        for (var i in $scope.modelVars) {
          if ($scope.modelVars[i] instanceof Date) {
            param[i] = $scope.modelVars[i].getTime()
          } else {
            param[i] = $scope.modelVars[i]
          }
        }
        $http.post('/api/user/add', param).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
