import indexTemplate from '../templates/index.html'

import formTemplate from '../templates/form.html'
import reportAdjustedFeesTemplate from '@/shared/templates/report.adjustedFees.html'
import {
  companyGroupState,
  makeFormState,
  makeIndexState,
  makeStateConfig,
  recalculationState,
} from '@/shared/config/states'
import formsCompanyGroupTemplate from '../templates/forms/companyGroup.html'

import type { IStateProvider } from 'ui-router'
import type { ILocationService } from 'angular'

function adjustedFeesState(config: IStateProvider) {
  config
    .state('root.controlpanel.report.index', {
      url: '/controlpanel/report',
      controller: [
        '$location',
        function ($location: ILocationService) {
          $location.path('/controlpanel/report/adjustedFees')
        },
      ],
    })
    .state('root.controlpanel.report.adjustedFees', {
      url: '/controlpanel/report/adjustedFees',
      controller: 'reportAdjustedFeesCtrl',
      template: reportAdjustedFeesTemplate,
    })
}

export const stateConfig = makeStateConfig([
  makeIndexState(indexTemplate),
  makeFormState(formTemplate),
  recalculationState,
  adjustedFeesState,
  companyGroupState(formsCompanyGroupTemplate),
])
