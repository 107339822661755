import { validCpr } from '@/shared/functions/cpr'

export const ngCpr = [
  '$http',
  function ($http) {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = viewValue + ''

          var valid = validCpr(viewValue)

          if (valid) {
            // it is valid
            ctrl.$setValidity('cpr', true)
            $http.post('/api/registration/personAddress/' + viewValue).success(function (obj) {
              var fieldPostfix = attrs.name.substring(3, 4)
              if (!obj.isValid) {
                if (fieldPostfix == '2') {
                  scope.setWarning('Personen kunne ikke findes.', 'cpr2Warning')
                } else if (attrs.name == 'kautionistCpr') {
                  scope.setWarning('Personen kunne ikke findes.', 'kautionistCprWarning')
                } else if (attrs.name == 'kautionist2Cpr') {
                  scope.setWarning('Personen kunne ikke findes.', 'kautionist2CprWarning')
                } else if (attrs.name == 'anviserCpr') {
                  scope.setWarning('Personen kunne ikke findes.', 'anviserCprWarning')
                } else {
                  scope.setWarning('Personen kunne ikke findes.', 'cprWarning')
                }
              } else {
                if (attrs.name == 'cpr' || attrs.name == 'cpr2') {
                  if (scope.modelVars.navn !== undefined) {
                    if (fieldPostfix == '2') {
                      scope.setWarning(obj.errormsg, 'cpr2Warning')
                    } else {
                      scope.setWarning(obj.errormsg, 'cprWarning')
                    }
                    //scope.cityVars[attrs.ngPostnr] = city;
                    scope.modelVars['navn' + fieldPostfix] = obj.name.substring(0, 50)
                    scope.modelVars['adresse' + fieldPostfix] = obj.address
                    scope.modelVars['postnr' + fieldPostfix] = obj.zipcode
                    scope.modelVars['tlf' + fieldPostfix] = obj.phone
                    scope.modelVars['mail' + fieldPostfix] = obj.mail
                  }
                } else if (attrs.name == 'kautionistCpr') {
                  scope.setWarning(obj.errormsg, 'kautionistCprWarning')
                  scope.modelVars['kautionistNavn'] = obj.name
                  scope.modelVars['kautionistAdresse'] = obj.address
                  scope.modelVars['kautionistPostnr'] = obj.zipcode
                } else if (attrs.name == 'kautionist2Cpr') {
                  scope.setWarning(obj.errormsg, 'kautionist2CprWarning')
                  scope.modelVars['kautionist2Navn'] = obj.name
                  scope.modelVars['kautionist2Adresse'] = obj.address
                  scope.modelVars['kautionist2Postnr'] = obj.zipcode
                } else if (attrs.name == 'anviserCpr') {
                  scope.setWarning(obj.errormsg, 'anviserCprWarning')
                  scope.modelVars['anviserNavn'] = obj.name
                  scope.modelVars['anviserAdresse'] = obj.address
                  scope.modelVars['anviserPostnr'] = obj.zipcode
                }
              }
            })
            return viewValue
          } else if (viewValue == '') {
            ctrl.$setValidity('cpr', true)
            return ''
          } else {
            ctrl.$setValidity('cpr', false)
            return undefined
          }
        })
      },
    }
  },
]
