import { dateToYMD, dateToDanish } from '@/shared/functions/dates'
import { prepareDMRResult } from '@/shared/functions/dmr'
import { isEmpty } from '@/shared/functions/isEmpty'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import { ucwordsImproved } from '@/shared/functions/ucwords'
import { errorList, errorCount } from '@/shared/functions/validationErrors'
import { ComputationEngine } from '../../computationengine/ComputationEngine'
import { CVR_REGEXP } from '@/shared/regexes'

import retrofitTemplate from '@/shared/templates/modals/retrofit.html'
import newDMRContractTemplate from '@/shared/templates/modals/newDMRContract.html'
import applicationConditionTemplate from '@/shared/templates/modals/applicationCondition.html'
import leasingkodeTemplate from '@/shared/templates/modals/leasingkode.html'
import attachmentsCernoTemplate from '@/shared/templates/modals/attachmentsCerno.html'
import genberegningregafgiftTemplate from '@/shared/templates/modals/genberegningregafgift.html'
import prisskiltTemplate from '@/shared/templates/modals/prisskilt.html'
import attachFileTemplate from '@/shared/templates/modals/attachFile.html'
import vehicleCatalogTemplate from '@/shared/templates/modals/vehicleCatalog.html'
import warningRedoRKITemplate from '@/shared/templates/modals/warningRedoRKI.html'
import mailTemplate from '@/shared/templates/modals/mail.html'
import warningUndoTemplate from '@/shared/templates/modals/warningUndo.html'
import applicationMailExternalTemplate from '@/shared/templates/modals/applicationMailExternal.html'
import applicationMailContractTemplate from '@/shared/templates/modals/applicationMailContract.html'
import applicationMailOfferTemplate from '@/shared/templates/modals/applicationMailOffer.html'
import applicationMailMaterialRequestTemplate from '@/shared/templates/modals/applicationMailMaterialRequest.html'
import editInfotextTemplate from '@/shared/templates/modals/editInfotext.html'
import bestyrelsesgodkendelseTemplate from '@/shared/templates/modals/bestyrelsesgodkendelse.html'
import godkendafvisningfejlTemplate from '@/shared/templates/modals/godkendafvisningfejl.html'
import acceptCommentTemplate from '@/shared/templates/modals/acceptComment.html'
import warningSendTemplate from '@/shared/templates/modals/warningSend.html'
import applicationExternalLiabilitiesTemplate from '@/shared/templates/modals/applicationExternalLiabilities.html'
import browserWarningKoerebogTemplate from '@/shared/templates/modals/browserWarningKoerebog.html'

import angular from 'angular'

export const formCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$modal',
  '$templateCache',
  '$timeout',
  '$upload',
  '$interpolate',
  '$dateFormatter',
  '$window',
  'dkkapVarDefs',
  function (
    $scope,
    $rootScope,
    $http,
    $stateParams,
    $location,
    $modal,
    $templateCache,
    $timeout,
    $upload,
    $interpolate,
    $dateFormatter,
    $window,
    dkkapVarDefs,
  ) {
    $scope.persons = {}

    $scope.debugClick = function () {
      console.log($scope.engine.eval('anslaaetGroenEjerafgiftPrAar'))
    }

    $scope.findLeasingDelete = function () {
      if (!$scope.modelVars.externFindLeasingId) {
        console.error('FindLeasing ID ej fundet')
      } else {
        $http.post('/api/findleasing/delete/' + $scope.id).then(function (res) {
          $scope.modelVars.externFindLeasingId = null
        })
      }
    }

    $scope.findLeasingTransferring = false
    $scope.findLeasingTransfer = function () {
      if (!$scope.modelVars.externBilinfoId) {
        console.error('Bilinfo ID ej fundet')
      } else {
        $scope.findLeasingTransferring = true
        var payload = {
          vehicle_id: $scope.modelVars.externBilinfoId,
          offer_id: $scope.modelVars.externFindLeasingId,
          list_type: 0,
          funding: $scope.modelVars.leasingtype ? 0 : 1,
          ownership: 1,
          price: Math.round($scope.engine.eval('totalprisExMoms')),
          price_tax: $scope.modelVars.brugtmoms,
          tax_price: $scope.modelVars.vurderetRegAfgift,
          first_registration: dateToYMD(
            $scope.modelVars.nyBil ? new Date() : $scope.modelVars.indregistreretFoersteGang,
          ),
          remaining_value: Math.round($scope.engine.eval('restvaerdi')),
          first_pay: Math.round($scope.engine.eval('foersteYdelseExMoms')),
          deposit: null,
          establishing_price: Math.round(
            $scope.engine.eval('fasteOmkostninger') -
              $scope.engine.eval('dokumentgebyrFinal') - // == ekstraMaanedRegAfgiftTotal + loebetidAar*dokumentgebyr + loebetid*opkraevningsgebyr
              $scope.engine.eval('ekspeditionsgebyrAfterWrite'), // == loebetidAar*ekspeditionsgebyr
          ),
          cost_per_year: Math.round($scope.engine.eval('dokumentgebyr') + $scope.engine.eval('ekspeditionsgebyr')),
          cost_per_month: Math.round($scope.engine.eval('opkraevningsgebyr')),
          delivery: $scope.modelVars.leveringsomkostningerExMoms,
          plates_cost: Math.round($scope.engine.eval('nummerpladerFinal')),
          handling: Math.round($scope.engine.eval('stiftelsesprovisionEfterReg')),
          interest_rate: ($scope.engine.eval('rentesatsEfterReg') * 100).toFixed(2),
          taxation: $scope.modelVars.beskatningsgrundlagBil,
          date_to_date_taxation: $scope.engine.eval('ekstraMaanedRegAfgiftInactive'),
        }

        $http.post('/api/findleasing/save', { offer: payload, applicationId: $scope.id }).then(function (res) {
          $scope.modelVars.externFindLeasingId = res.data.id
          $scope.findLeasingTransferring = false
        })
      }
    }

    $scope.findLeasingSynced = false
    $scope.findLeasingSync = function () {
      console.log('sync start')
      if ($scope.modelVars.externFindLeasingId) {
        $http.post('/api/findleasing/syncActiveIds').then(function (res) {
          if (!res.data[$scope.modelVars.externFindLeasingId]) {
            $scope.modelVars.externFindLeasingId = null
          }
          $scope.findLeasingSynced = true
          console.log('sync performed')
        })
      } else {
        $scope.findLeasingSynced = true
        console.log('sync not performed')
      }
    }

    $scope.koerebogClick = function (event) {
      // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
      var isChromium = window.chrome
      var winNav = window.navigator
      var vendorName = winNav.vendor
      var isOpera = typeof window.opr !== 'undefined'
      var isIEedge = winNav.userAgent.indexOf('Edge') > -1
      var isIOSChrome = winNav.userAgent.match('CriOS')

      if (isIOSChrome) {
        // is Google Chrome on IOS
      } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is Google Chrome
      } else {
        event.stopPropagation()
        event.preventDefault()

        var subScope = $scope.$new(true)
        subScope.aftalenr = $scope.aftalenr
        $modal({ template: browserWarningKoerebogTemplate, show: true, scope: $scope })

        return false
      }
    }

    var getTimeOfDate = function (x) {
      if (typeof x === 'string' || x instanceof String) {
        var parts = x.split('-')
        return new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10)).getTime()
      } else if (x instanceof Date) {
        return x.getTime()
      } else {
        return x
      }
    }

    $scope.collapseStatus = false

    $scope.beforeUnload = function (e) {
      var confirmationMessage = 'Vil du forlade siden uden at gemme?'

      if ($rootScope.currentForm.$dirty && $rootScope.currentStatus === 0) {
        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('beforeunload', $scope.beforeUnload)
    })

    $window.addEventListener('beforeunload', $scope.beforeUnload)
    $scope.setUseFoersteYdelseExMomsPctWrite = function (newVal) {
      var oldVal = modelVars.useFoersteYdelseExMomsPctWrite
      var BELOEB = false
      var PROCENT = true

      if (oldVal === newVal) return

      if (newVal === PROCENT) {
        var temp = $scope.engine.eval('foersteYdelseExMomsPct')
        if (isEmpty(temp)) {
          $scope.modelVars.foersteYdelseExMomsPctWrite = undefined
        } else {
          $scope.modelVars.foersteYdelseExMomsPctWrite = temp
        }
      }
      if (newVal === BELOEB) {
        var temp = $scope.engine.eval('foersteYdelseExMoms')
        if (isEmpty(temp)) {
          $scope.modelVars.foersteYdelseExMomsWrite = undefined
        } else {
          $scope.modelVars.foersteYdelseExMomsWrite = temp
        }
      }
      $scope.modelVars.useFoersteYdelseExMomsPctWrite = newVal
    }
    $scope.setUseRestvaerdiCalc = function (newVal) {
      var oldVal = modelVars.useRestvaerdiCalc
      if (oldVal === newVal) return

      if (newVal === 'ydelse') {
        var temp = $scope.engine.eval('ydelse')
        if (isEmpty(temp)) {
          $scope.modelVars.ydelseWrite = undefined
        } else {
          $scope.modelVars.ydelseWrite = temp
        }
      }

      if (newVal === 'restvaerdi') {
        var temp = engine.eval('restvaerdi')

        if (isEmpty(temp)) {
          $scope.modelVars.restvaerdiExMomsWrite = undefined
        } else {
          $scope.modelVars.restvaerdiExMomsWrite = temp
        }
      }

      if (newVal === 'afviklingsprocent') {
        var temp = engine.eval('afviklingsprocent')
        if (isEmpty(temp) || !isFinite(temp)) {
          $scope.modelVars.afviklingsprocentWrite = 0 // percent often ends up in divide by zero case.
        } else {
          $scope.modelVars.afviklingsprocentWrite = temp
        }
      }
      $scope.modelVars.useRestvaerdiCalc = newVal
    }
    // vejl
    $scope.instructions = [
      {
        condition: 'vejlKilometerstandFoersteYdelse',
        description:
          'Pga. km-standen så bør førsteydelsen være større. Den er sat til {{foersteYdelseExMoms}} og bør derfor være så tæt på {{foersteYdelseExMomsMaxAfrundet}} som muligt',
        interpolate: ['foersteYdelseExMoms', 'foersteYdelseExMomsMaxAfrundet'],
      },
      {
        condition: 'vejlKilometerstandAfviklingPct',
        description:
          'Pga. km-standen så bør restværdien være mindre (afviklingsprocenten er lige nu {{afviklingsprocent}} og bør som minimum være {{afviklingMinPctVejl}})',
        interpolate: ['afviklingsprocent', 'afviklingMinPctVejl'],
      },
      {
        condition: 'vejlKilometerstand',
        description:
          'Km-standen er umiddelbart for høj. For at vi skal godkende ansøgningen, skal alt andet være ”perfekt”.',
        interpolate: [],
      },
      {
        condition: 'vejlFoersteYdelse',
        description:
          'Førsteydelsen er {{foersteYdelseExMomsPct}}. Den bør som minimum være {{foersteYdelseMinPctVejl}}',
        interpolate: ['foersteYdelseExMomsPct', 'foersteYdelseMinPctVejl'],
      },
      {
        condition: 'vejlProvision',
        description:
          'Din provision udgør {{anvisningsprovisionPctFY}} af førsteydelsen. Sæt venligst førsteydelsen op eller korriger din provision.',
        interpolate: ['anvisningsprovisionPctFY'],
      },
      {
        condition: 'vejlAfviklingPct',
        description:
          'Der er kun en afviklingsprocent på {{afviklingsprocent}}. Den bedes udgøre minimum {{afviklingMinPctVejl}}. Nedsæt venligst restværdien.',
        interpolate: ['afviklingsprocent', 'afviklingMinPctVejl'],
      },
      {
        condition: 'vejlMomsfradrag',
        description: 'Leasingtager får ikke momsfradrag, da løbetiden er mindre end 7 måneder. ',
        interpolate: [],
      },
      {
        condition: 'vejlKoerselPrAar',
        description:
          'Da årlig kørsel er sat til {{maxKoerselPrAar}} km, så bør afviklingsprocenten være minimum {{afviklingMinPctVejlHoejKm}} (den er nu {{afviklingsprocent}})',
        interpolate: ['maxKoerselPrAar', 'afviklingMinPctVejlHoejKm', 'afviklingsprocent'],
      },
      {
        condition: 'vejlEnkeltmandsvirksomhed',
        description:
          'Virksomheden er en enkeltmandsvirksomhed. Du bedes derfor indsende seneste årsopgørelse med tilhørende regnskab, så vi kan behandle ansøgningen hurtigere. Du kan vedhæfte bilag til denne ansøgning ved at bruge funktionen ”Vedlæg filer”.',
        interpolate: [],
      },
      {
        condition: 'vejlRegnskabMangler',
        description:
          'Virksomheden har endnu ikke offentliggjort et årsregnskab. Du bedes derfor indsende en råbalance, så vi kan behandle ansøgningen hurtigere. Du kan vedhæfte bilag til denne ansøgning ved at bruge funktionen ”Vedlæg filer”.',
        interpolate: [],
      },
      {
        condition: 'vejlPrivatkunde',
        description:
          'Kunden er en privatkunde. Du bedes derfor indsende seneste årsopgørelse, så vi kan behandle ansøgningen hurtigere. Du kan vedhæfte bilag til denne ansøgning ved at bruge funktionen ”Vedlæg filer”.',
        interpolate: [],
      },
      {
        condition: 'vejlLeasingForholdsmaessigAfgift',
        description:
          'Husk at vedhæfte salgsopstilling på bilen, bilens vognkort samt din beregning af registreringsafgiften. Du kan vedhæfte bilag til denne ansøgning ved at bruge funktionen ”Vedlæg filer”.',
        interpolate: [],
      },
      {
        condition: 'vejlLeasingFuldAfgift',
        description:
          'Husk at vedhæfte salgsopstilling på bilen. Du kan vedhæfte bilag til denne ansøgning ved at bruge funktionen ”Vedlæg filer”.',
        interpolate: [],
      },
    ]

    $scope.shownInstructions = null
    $scope.showInstructions = function () {
      var out = []

      angular.forEach($scope.instructions, function (e) {
        if (!e.condition || $scope.engine.eval(e.condition)) {
          if (!e.conditionCB || e.conditionCB()) {
            // compute interpolation obj
            var fields = {}
            angular.forEach(e.interpolate, function (f) {
              fields[f] = formatValue($scope.variableDefs[f], $scope.engine.eval(f))
            })

            var temp = $interpolate(e.description)
            out.push(temp(fields))
          }
        }
      })

      return out
    }

    $scope.copy = function () {
      var items = [
        'productId',
        'kundetype',
        'cpr',
        'cvr',
        'navn',
        'adresse',
        'postnr',
        'tlf',
        'tlfAlternativ',
        'mail',
        'meddebitor',
        'cpr2',
        'navn2',
        'adresse2',
        'postnr2',
        'tlf2',
        'tlfAlternativ2',
        'mail2',
        'kautionist',
        'kautionistCpr',
        'kautionistCvr',
        'kautionistNavn',
        'kautionistAdresse',
        'kautionistPostnr',
        'kautionist2',
        'kautionist2Cpr',
        'kautionist2Cvr',
        'kautionist2Navn',
        'kautionist2Adresse',
        'kautionist2Postnr',
        'ejerCpr1',
        'ejerNavn1',
        'ejerCpr2',
        'ejerNavn2',
        'ejerCpr3',
        'ejerNavn3',
        'ejerCpr4',
        'ejerNavn4',
        'netsRegNr',
        'netsKontoNr',
        'netsAfventer',
        'braendstofkortOKMobilPinkode',
        'braendstofkortOKMobil',
        'braendstofkortOKPinkode',
        'braendstofkortOK',
        'braendstofkortUnoXPinkode',
        'braendstofkortUnoX',
        'braendstofkortQ8',
        'braendstofkortShellPinkode',
        'braendstofkortShell',
        'braendstofkortCircleK',
        'boligtype',
        'bruttoloen',
        'nettoloen',
        'raadighedsbeloeb',
        'antalVoksne',
        'antalBoern',
        'haftBilFoer',
        'maerkeModel',
        'varevogn',
        'brugtmoms',
        'registreringsnummer',
        'stelnummer',
        'nyBil',
        'tidligereIndregistreret',
        'indregistreretFoersteGang',
        'ejIndregistreretAargang',
        'campingFastligger',
        'farve',
        'gpsFirmaKode',
        'produktlink',
        'anviserForhandler',
        'anviserKontraktsaet',
        'anviserCpr',
        'anviserCvr',
        'anviserNavn',
        'anviserAdresse',
        'anviserPostnr',
        'anviser2',
        'anviser2Kontraktsaet',
        'anviser2Cvr',
        'anviser2Navn',
        'anviser2Adresse',
        'anviser2Postnr',
        'beskatningsgrundlagBil',
        'leasinggiverBetalerNummerplader',
      ]

      var obj = {}
      for (var i in items) {
        var d = $scope.modelVars[items[i]]
        if (items[i] == 'boligtype') {
          obj[items[i]] = d === false ? 0 : d === true ? 1 : d
        } else {
          obj[items[i]] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      if ($scope.modelVars.productId >= 4) {
        // Leasingprodukt
        obj.genleasing = true
        obj.genleasingKontraktnr = $scope.aftalenr
      }

      localStorage.setItem('modelVarsPreload', JSON.stringify(obj))
    }

    $scope.show = function (x) {
      console.log(x)
    }

    $scope.renderTimestamp = function (timestamp) {
      return dateToDanish(new Date(timestamp), true)
    }

    $scope.forceEnabled = {}

    $scope.retrofitVars = function () {
      var dateFields = ['forsikringIkraft']

      var out = []
      for (var v in $scope.forceEnabled) {
        var isDate = dateFields.indexOf(v) > -1
        /*
            if (isDate) {
                console.log('---')
                console.log('$scope.modelVars['+v+'].getTimezoneOffset()', $scope.modelVars[v].getTimezoneOffset())
                console.log('$scope.modelVars['+v+'].getTime()', $scope.modelVars[v].getTime())
                console.log('$scope.modelVars['+v+']', $scope.modelVars[v])
                console.log('getTimeOfDate($scope.modelVars['+v+'])', getTimeOfDate($scope.modelVars[v]))
                console.log('getTimeOfDate(dateToYMD($scope.modelVars['+v+']))', getTimeOfDate(dateToYMD($scope.modelVars[v])))
                console.log('$scope.currentRow['+v+']', $scope.currentRow[v])
                console.log('getTimeOfDate($scope.currentRow['+v+'])', getTimeOfDate($scope.currentRow[v]))
            }
            */

        var isDifferent = isDate
          ? getTimeOfDate($scope.modelVars[v] instanceof Date ? dateToYMD($scope.modelVars[v]) : $scope.modelVars[v]) !=
            getTimeOfDate($scope.currentRow[v])
          : $scope.modelVars[v] != $scope.currentRow[v]

        if (isDifferent) {
          if (v == 'forsikringIkraft') {
            out.push({
              name: v,
              valueBefore: dateToDanish(new Date(getTimeOfDate($scope.currentRow[v]))),
              valueAfter: dateToDanish($scope.modelVars[v]),
            })
          } else if (v == 'insuranceCompanyId') {
            out.push({
              name: v,
              valueBefore: $scope.insuranceCompanyLookup[$scope.currentRow[v]],
              valueAfter: $scope.insuranceCompanyLookup[$scope.modelVars[v]],
            })
          } else if (v == 'faellespolice') {
            if ($scope.currentRow['valuesJson']) {
              var valuesJson = JSON.parse($scope.currentRow['valuesJson'])
              if (valuesJson.faellespolice) {
                if (valuesJson.forsikringFaellespoliceSelskab != $scope.modelVars['insuranceCompanyId']) {
                  out.push({ name: 'faellespolice', valueBefore: true, valueAfter: false })
                }
              }
            }
          } else {
            out.push({ name: v, valueBefore: $scope.currentRow[v], valueAfter: $scope.modelVars[v] })
          }
        }
      }
      return out
    }

    $scope.retrofitVarsValid = function (form) {
      for (var v in $scope.forceEnabled) {
        if (form[v] && form[v].$invalid) return false
      }
      return true
    }

    $scope.resetBetalingFoersteGang = function () {
      $scope.modelVars.betalingFoersteGang = $scope.engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
    }

    $scope.openRetrofitModal = function () {
      var subScope = $scope.$new(true)
      subScope.aftalenr = $scope.aftalenr
      subScope.productId = $scope.modelVars.productId
      subScope.saving = false
      subScope.retrofitVars = $scope.retrofitVars()
      subScope.variableDefs = $scope.variableDefs
      subScope.save = function ($hide, updateNav) {
        subScope.saving = true

        var vs = {}
        angular.forEach(subScope.retrofitVars, function (v) {
          vs[v.name] = v.valueAfter
        })

        for (var v in $scope.forceEnabled) {
          if (subScope.variableDefs[v].vartype == 'model' || vs[v] === undefined) {
            if ($scope.modelVars[v] instanceof Date) {
              vs[v] = $scope.modelVars[v].getTime()
            } else {
              vs[v] = $scope.modelVars[v]
            }
          }
        }

        $http.post('/api/retrofit/retrofitValues/' + $scope.id + '/' + (updateNav ? 1 : 0), vs).success(function (res) {
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
          $hide()
        })
      }
      $modal({ template: retrofitTemplate, show: true, scope: subScope })
    }

    $scope.seasonAdmin = false

    $scope.toggleSeasonAdmin = function () {
      $scope.seasonAdmin = !$scope.seasonAdmin
    }

    $scope.openNewDMRContractModal = function (saeson) {
      var subScope = $scope.$new(true)
      subScope.variableDefs = {
        loebetid: {
          friendly: 'Løbetid (mdr.)',
          datatype: 'count',
          vartype: 'model',
        },
        ydelse: {
          friendly: 'Ydelse',
          datatype: 'amount',
          vartype: 'model',
        },
        rentesats: {
          friendly: 'Rentesats',
          datatype: 'percent',
          vartype: 'model',
        },
        vurderetRegAfgift: {
          friendly: 'Vurderet reg. afgift',
          datatype: 'amount',
          vartype: 'model',
        },
        fakturaprisExMoms: {
          friendly: 'Bilens pris (RV fra NAV)',
          datatype: 'amount',
          vartype: 'model',
        },
      }

      subScope.modelVars = {
        loebetid: engine.eval('loebetidSaeson' + saeson),
        ydelse: Math.round(0 + engine.eval('ydelse') + (0 + engine.eval('sommerbilRegAfgiftSaeson' + saeson))),
        rentesats: engine.eval('rentesatsEfterReg'),
        vurderetRegAfgift: 0,
        fakturaprisExMoms: null,
      }

      $http.post('/api/existingContracts/kontraktnr/' + $scope.aftalenr).success(function (res) {
        $timeout(function () {
          subScope.modelVars.fakturaprisExMoms = res.restvaerdi
        })
      })

      $modal({ template: newDMRContractTemplate, show: true, scope: subScope })
    }

    $scope.materials = []
    $scope.uploading = false

    $scope.websiteLink = function (x) {
      if (x === undefined) return x
      if (x.substr(0, 7) == 'http://') {
        return x
      }
      return 'http://' + x
    }

    $scope.websiteText = function (x) {
      if (x === undefined) return x
      if (x.substr(0, 7) == 'http://') {
        return x.substr(7)
      }
      return x
    }

    $scope.fieldDescriptions = {}

    $scope.autostuffWatches = {}

    $scope.orderByValue = function (value) {
      return value.name
    }
    $scope.cityVars = {}

    var initializeDownloadButton = function (downloadButtonState) {
      var out = {}
      for (var i in $scope.downloadButtonStates) {
        var n = $scope.downloadButtonStates[i].name
        if (downloadButtonState[n] !== undefined) {
          out[n] = downloadButtonState[n]
        } else {
          out[n] = $scope.downloadButtonStates[i].default
        }
      }
      return out
    }

    var initializeRegisterButton = function (registerButtonState) {
      var out = {}
      for (var i in $scope.registerButtonStates) {
        var n = $scope.registerButtonStates[i].name
        if (registerButtonState[n] !== undefined) {
          out[n] = registerButtonState[n]
        } else {
          out[n] = $scope.registerButtonStates[i].default()
        }
      }
      return out
    }

    $scope.translateHPStrToInt = function (hpStr) {
      if (hpStr === '=' || hpStr === '') return 0
      const regex = /\([0-9]+ HK\)/
      const match = hpStr.match(regex)[0]
      return parseInt(match.substring(1, match.length - 4))
    }

    $scope.motorregisterResult = {}
    $scope.motorregister = function (type) {
      var query = ''
      if (type == undefined) {
        query = $scope.dmrsoegning
      }
      if (type == 'registreringsnummer') {
        query = $scope.modelVars.registreringsnummer
      }
      if (type == 'stelnummer') {
        query = $scope.modelVars.stelnummer
      }
      $scope.motorregisterResult = {
        register: 'motorregister',
        type: 'wait',
      }
      $http.post('/api/DMRLookup/' + query).success(function (res) {
        var parseDmrDate = function (str) {
          if (str != '-' && !!str) {
            var temp = str.split('-')
            if (temp.length == 3) {
              return new Date(temp[2], temp[1] - 1, temp[0])
            }
          }
          return null
        }

        $scope.motorregisterSections = prepareDMRResult(res)
        $scope.motorregisterResult = res
        if (res.type == 'success' && $scope.status == 0) {
          if (res.result['Køretøj']['Registreringsforhold']['Første registreringsdato'] != '-') {
            var temp = res.result['Køretøj']['Registreringsforhold']['Første registreringsdato'].split('-')
            if (temp.length > 0) {
              modelVars.nyBil = false
              modelVars.indregistreretFoersteGang = new Date(temp[2], temp[1] - 1, temp[0])
            }
          }

          modelVars.dmrArt = res.result['Køretøj']['Køretøj']['Art']
          modelVars.dmrSynSenestDato = parseDmrDate(res.result['Syn']['Synsindkaldelse(r)']['Indkaldt til syn senest'])
          modelVars.dmrSynFrekvens = res.result['Syn']['Synsindkaldelse(r)']['Frekvens for periodisk syn']
          modelVars.dmrSynNaesteDato = parseDmrDate(
            res.result['Syn']['Synsindkaldelse(r)']['Beregnet dato for næste indkaldelse til periodisk syn'],
          )
          modelVars.horsePower = $scope.translateHPStrToInt(
            res.result['Tekniske oplysninger']['Motor osv.']['Største effekt'],
          )

          modelVars.stelnummer = res.result['Køretøj']['Køretøj']['Stelnummer']
          modelVars.varevogn = res.result['Køretøj']['Køretøj']['Art'] != 'Personbil'
          if (modelVars.maerkeModel == '') {
            modelVars.maerkeModel = ucwordsImproved(res.result['Køretøj']['Køretøj']['Mærke, Model, Variant'])
              .split(' ')
              .map(function (x) {
                return x.replace(/,$/, '')
              })
              .filter(function (x) {
                return x.toLowerCase() != 'ukendt' && x.toLowerCase() != 'uoplyst'
              })
              .join(' ')
              .substring(0, 60)
          }
          if (
            res.result['Køretøj']['Identifikation']['Farve'] != 'Ukendt' &&
            res.result['Køretøj']['Identifikation']['Farve'] != '-'
          ) {
            modelVars.farve = res.result['Køretøj']['Identifikation']['Farve']
          }
        }
      })
    }

    $scope.bilbogen = function () {
      var query = $scope.modelVars.stelnummer
      $scope.motorregisterResult = {
        register: 'bilbogen',
        type: 'wait',
      }
      $http.post('/api/bilbogenLookup/' + query).success(function (res) {
        $scope.motorregisterSections = res.sections
        $scope.motorregisterResult = res
      })
    }

    $scope.downloadLinks = [
      /*{
              'pdflink' : 'finansieringsforslag',
              'active' : function() { return $scope.modelVars.productId <= 3; },
              'friendly' : 'Udskriv finansieringsforslag'
            },
            {
              'pdflink' : 'finansieringsforslag',
              'active' : function() { return $scope.modelVars.productId >= 4; },
              'friendly' : 'Udskriv leasingforslag'
            },*/
      {
        pdflink: 'braendstofkort',
        active: function () {
          return $scope.modelVars.productId == 6 || $scope.modelVars.productId == 8
        },
        friendly: 'Bestillingsformular til brændstofkort',
      },
      {
        pdflink: 'sef',
        active: function () {
          return $scope.modelVars.productId == 1 || $scope.modelVars.productId == 3
        },
        friendly: 'SEF-skema',
      },
      {
        click: 'prisskilt()',
        active: function () {
          return $scope.modelVars.productId == 1 || $scope.modelVars.productId == 3
        },
        friendly: 'Prisskilt',
      },
      {
        pdflink: 'firmabilbeskatning',
        active: function () {
          return $scope.modelVars.productId >= 5
        },
        friendly: 'Beregning, beskatning af fri bil',
      },
      {
        pdflink: 'kautionserklaering',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Kautionserklæring',
      },
      {
        href: function () {
          if ($scope.currentRow !== undefined) {
            return '/api/SOS/getPDF/' + $scope.currentRow.id + '/' + $scope.signature
          }
        },
        target: '_blank',
        active: function () {
          return $scope.currentRow !== undefined && ($scope.modelVars.productId == 6 || $scope.modelVars.productId == 8)
        },
        friendly: 'SOS International servicekort',
      },
      {
        pdflink: 'husstandsoplysninger',
        active: function () {
          return $scope.modelVars.kundetype == false
        },
        friendly: 'Formular til husstandsoplysninger',
      },
      {
        click: 'leasingkode(false)',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Bestilling af leasingkontrakt Krone',
      } /*,
    {
      'click' : 'leasingkode(true)',
      'active' : function() { return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin; },
      'friendly' : 'Anmodning om ny afgift'
    }*/,
      {
        click: 'genberegningregafgift()',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin && $scope.modelVars.productId >= 5
        },
        friendly: 'Genberegning af reg. afgift',
      },
      {
        pdflink: 'maskinrum',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Maskinrum',
      },
      {
        pdflink: 'finansieringsforslagmaskinrum',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin && $scope.modelVars.productId <= 3
        },
        friendly: 'Finansieringsforslag og maskinrum',
      },
      {
        pdflink: 'finansieringsforslagmaskinrum',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin && $scope.modelVars.productId > 3
        },
        friendly: 'Leasingforslag og maskinrum',
      },
      {
        pdflink: 'kontraktudkast',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Kontrakt (udkast)',
      },
      {
        pdflink: 'husstandsoplysningerudfyldt',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Husstandsoplysninger (udfyldt)',
      },
      {
        pdflink: 'alarmopkald',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Alarmopkald',
      },
      {
        pdflink: 'vanvidskoersel',
        active: function () {
          return true
        },
        friendly: 'Tro og love: Vanvidskørsel',
      },
    ]

    $scope.savingTooLow = function () {
      return (
        isSplitleasing() &&
        modelVars.anslaaetAndelPrivatkoersel > 0 &&
        engine.eval('anslaaetBeskatningBesparelse') < engine.eval('splitTotalKraevetBesparelse')
      )
    }

    $scope.partPrivateTooHigh = function () {
      return isSplitleasing() && modelVars.anslaaetAndelPrivatkoersel > serverVars.splitAndelPrivatAdvarelse
    }

    var contractIsApproved = function () {
      return $scope.status == 2 && $scope.currentRow.approvalStatus == true
    }
    var contractHasCondition = function () {
      return $scope.currentRow.betingelseKunde != '' || $scope.currentRow.betingelseSaelger != ''
    }
    var isFinansiering = function () {
      return [1, 2, 3].indexOf($scope.modelVars.productId) > -1
    }
    var isLeasing = function () {
      return [4, 5, 6, 7, 8, 9].indexOf($scope.modelVars.productId) > -1
    }
    var isSplitleasing = function () {
      return $scope.modelVars.productId == 6 || $scope.modelVars.productId == 8
    }
    var isSommerbil = function () {
      return $scope.modelVars.productId == 7 || $scope.modelVars.productId == 8
    }
    var isKoebstaederne = function () {
      return engine.eval('faellespolice', true) //$scope.insuranceCompanyLookupReverse['Købstædernes Forsikring'];
    }

    $scope.insuranceUseable = function (str) {
      return $scope.insuranceCompanyLookupReverse[str] !== undefined
    }

    $scope.insuranceSelect = function (str) {
      $scope.modelVars.insuranceCompanyId = $scope.insuranceCompanyLookupReverse[str]
    }

    $scope.extractRegistrationDate = function (str) {
      var parts = str.substring(str.length - 10).split('-')
      return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
    }

    $scope.condition = function (a) {
      var subscope = $scope.$new(true)
      subscope.a = a

      $modal({ template: applicationConditionTemplate, show: true, scope: subscope })
    }

    $scope.registerButtonStates = [
      {
        name: 'rki',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'RKI',
      },
      {
        name: 'dowjones',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Dow Jones',
      },
      {
        name: 'tinglysning',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Tinglysning',
      },
      {
        name: 'cvr',
        active: function () {
          return $scope.modelVars.kundetype
        },
        default: function () {
          return true
        },
        friendly: 'CVR',
      },
      {
        name: 'ejerstruktur',
        active: function () {
          return $scope.modelVars.kundetype
        },
        default: function () {
          return true
        },
        friendly: 'Ejerstruktur',
      },
      {
        name: 'motorregister',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Motorregister',
      },
      {
        name: 'bilbogen',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Bilbogen',
      },
      {
        name: 'husstandsoplysningerudfyldt',
        active: function () {
          return !$scope.modelVars.kundetype
        },
        default: function () {
          return $scope.modelVars && ($scope.modelVars.productId == 1 || $scope.modelVars.productId == 3)
        },
        friendly: 'Husstandsoplysninger',
      },
    ]

    $scope.downloadButtonStates = [
      {
        name: 'inklOmkostninger',
        active: function () {
          return $scope.modelVars.productId <= 3
        },
        default: false,
        friendly: 'Vis omkostninger',
      },
      {
        name: 'inklRegistreringsafgift',
        active: function () {
          return $scope.modelVars.productId >= 5
        },
        default: true,
        friendly: 'Vis vurderet reg.afgift',
      },
      {
        name: 'inklForhandler',
        active: function () {
          return !$scope.engine.eval('usesCompanyLogo')
        },
        default: true,
        friendly: 'Vis forhandlerinformation',
      },
      {
        name: 'inklLeveringsdato',
        active: function () {
          return $scope.modelVars.productId == 3 || $scope.modelVars.productId >= 5
        },
        default: true,
        friendly: 'Vis leveringsdato',
      },
      {
        name: 'inklBeskatningsgrundlag',
        active: function () {
          return $scope.modelVars.productId == 4 || $scope.modelVars.productId == 5 || $scope.modelVars.productId == 7
        },
        default: true,
        friendly: 'Vis beskatningsgrundlag',
      },
      {
        name: 'inklTotalprisExMoms',
        active: function () {
          return (
            $scope.modelVars.productId == 4 ||
            $scope.modelVars.productId == 5 ||
            $scope.modelVars.productId == 6 ||
            $scope.modelVars.productId == 7 ||
            $scope.modelVars.productId == 8
          )
        },
        default: false,
        friendly: 'Vis totalpris ex. moms',
      },
      {
        name: 'inklOverkoerselKmPris',
        active: function () {
          return (
            $scope.modelVars.productId == 4 ||
            $scope.modelVars.productId == 5 ||
            $scope.modelVars.productId == 6 ||
            $scope.modelVars.productId == 7 ||
            $scope.modelVars.productId == 8
          )
        },
        default: true,
        friendly: 'Vis pris pr. overkørt km',
      },
      {
        name: 'inklFaellespolice',
        active: function () {
          return (
            $scope.modelVars.productId == 5 ||
            $scope.modelVars.productId == 6 ||
            $scope.modelVars.productId == 7 ||
            $scope.modelVars.productId == 8
          )
        },
        default: true,
        friendly: 'Vis fællespolice-tilbud',
      },
    ]
    $scope.contractDownloadLinks = []
    $scope.downloadButtonState = initializeDownloadButton({})
    $scope.registerButtonState = initializeRegisterButton({})

    $scope.$watch('userInfo', function () {
      if ($scope.userInfo !== undefined && $scope.userInfo.downloadButtonState !== undefined) {
        $scope.contractDownloadLinks = [
          {
            href: '/api/application/contract/' + $stateParams.id + '/1',
            target: '_blank',
            active: function () {
              return contractIsApproved() && !contractHasCondition()
            },
            friendly: 'Kontraktdokumentsæt MED kundekopi',
          },
          {
            href: '/api/application/contract/' + $stateParams.id + '/0',
            target: '_blank',
            active: function () {
              return contractIsApproved() && !contractHasCondition()
            },
            friendly: 'Kontraktdokumentsæt UDEN kundekopi',
          },
          {
            click: 'condition(currentRow)',
            active: function () {
              return contractIsApproved() && contractHasCondition()
            },
            friendly: 'Kontraktdokumentsæt (Betinget)',
          },
        ]

        if ($scope.userInfo.isSuperAdmin) {
          $scope.contractDownloadLinks = $scope.contractDownloadLinks.concat([
            {
              pdflink: 'huskeliste',
              friendly: 'Huskeliste til forhandler',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'huskelisteleasing',
              friendly: 'Huskeliste til forhandler',
              active: function () {
                return contractIsApproved() && isLeasing()
              },
            },
            {
              pdflink: 'huskelistekunde',
              friendly: 'Huskeliste til kunde',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'huskelistekundeleasing',
              friendly: 'Huskeliste til kunde',
              active: function () {
                return contractIsApproved() && isLeasing()
              },
            },
            {
              pdflink: 'supplerendeoplysninger',
              friendly: 'Supplerende oplysninger',
              active: function () {
                return contractIsApproved()
              },
            },
            {
              pdflink: 'betalingsadvis',
              friendly: 'Betalingsadvis',
              active: function () {
                return contractIsApproved()
              },
            },
            {
              pdflink: 'pbs',
              friendly: 'PBS-aftale',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'laaneaftale',
              friendly: 'Låneaftale',
              active: function () {
                return contractIsApproved() && isFinansiering() && $scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'kontrakt',
              friendly: 'Finanseringskontrakt',
              active: function () {
                return contractIsApproved() && isFinansiering() && !$scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'ejerpantebrev',
              friendly: 'Ejerpantebrev',
              active: function () {
                return (
                  contractIsApproved() &&
                  isFinansiering() &&
                  $scope.modelVars.gaeldsbrev &&
                  !$scope.modelVars.gaeldsbrevUdenPant
                )
              },
            },
            {
              pdflink: 'ejendomsforbehold',
              friendly: 'Ejendomsforbehold',
              active: function () {
                return contractIsApproved() && isFinansiering() && !$scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'sef',
              friendly: 'SEF-skema',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'Leasingkontrakt',
              active: function () {
                return contractIsApproved() && isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'Leasingkontrakt (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'Allonge',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'Allonge (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'skatAllongeAnviser',
              friendly: 'Anviser køber',
              active: function () {
                return isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },

            {
              pdflink: 'anviserprivatforhandler',
              friendly: 'Anviser køber - sekundær',
              active: function () {
                return isLeasing() && $scope.modelVars.anviser2 > 0
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'allongeindfrielse',
              friendly: 'Allonge, indfrielse',
              active: function () {
                return $scope.userInfo && $scope.userInfo.isSuperAdmin && !isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'faktura',
              friendly: 'Faktura',
              active: function () {
                return (contractIsApproved() || $scope.userInfo.isSuperAdmin) && isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'faktura',
              friendly: 'Faktura (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'Leverancegodkendelse',
              active: function () {
                return contractIsApproved() && isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'Leverancegodkendelse (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'Tro- og love erklæring (Bruger 2)',
              active: function () {
                return (
                  ((contractIsApproved() && isKoebstaederne()) || $scope.userInfo.isSuperAdmin) &&
                  $scope.modelVars.productId >= 5
                )
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'Tro- og love erklæring',
              active: function () {
                return (
                  ((contractIsApproved() && isKoebstaederne()) || $scope.userInfo.isSuperAdmin) &&
                  isLeasing() &&
                  $scope.modelVars.productId >= 5
                )
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'SKAT Leasingkontrakt',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                version: 'SKAT',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeAnviser',
              friendly: 'SKAT A1 - Anviser køber',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeAnviserPrivatForhandler',
              friendly: 'SKAT A1B - Anviser - Sekundær',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'SKAT A2 - Splitleasing',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'SKAT A2 - Splitleasing (Bruger 2)',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '2',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeKaution',
              friendly: 'SKAT A4 - Selvskyldnerkaution',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'SKAT A5 - Leverancegodkendelse',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'SKAT - Tro- og loveerklæring',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
          ])
        }

        $scope.downloadButtonState = initializeDownloadButton(JSON.parse($scope.userInfo.downloadButtonState))
      }
    })
    $scope.testevent = function (e) {
      $http.post('/api/application/experian/parseXMLTester')
    }

    $scope.toggleButtonState = function (n) {
      $scope.downloadButtonState[n] = !$scope.downloadButtonState[n]
      $http.post('/api/setDownloadButtonState', $scope.downloadButtonState)

      return false
    }

    $scope.toggleRegisterButtonState = function (n) {
      $scope.registerButtonState[n] = !$scope.registerButtonState[n]
      return false
    }

    $scope.registerButtonQueryString = function () {
      if ($scope.registerButtonState !== undefined) {
        var out = []
        for (var i in $scope.registerButtonStates) {
          if ($scope.registerButtonStates[i].active()) {
            var item = $scope.registerButtonStates[i].name
            out.push(item + '=' + ($scope.registerButtonState[item] ? 1 : 0))
          }
        }
        return out.join('&')
      }
      return ''
    }

    var getDownloadLinks = function (arr) {
      var out1 = []

      for (var i in arr) {
        if (arr[i].active()) {
          out1.push({
            text: '<i class="fa fa-print"></i> ' + arr[i].friendly,
            params: JSON.stringify(arr[i].params),
            pdflink: arr[i].pdflink,
            href: typeof arr[i].href == 'function' ? arr[i].href() : arr[i].href,
            click: arr[i].click,
            target: arr[i].target,
            downloadButtonState: $scope.downloadButtonState,
          })
        }
      }
      return out1
    }

    $scope.downloadButtonDropdown = function () {
      var out2 = []
      for (var i in $scope.downloadButtonStates) {
        if ($scope.downloadButtonStates[i].active()) {
          var c = $scope.downloadButtonState[$scope.downloadButtonStates[i].name] ? 'fa-check-square-o' : 'fa-square-o'
          out2.push({
            text: '<i class="fa ' + c + '"></i> ' + $scope.downloadButtonStates[i].friendly,
            click: "toggleButtonState('" + $scope.downloadButtonStates[i].name + "')",
          })
        }
      }
      return out2
    }

    $scope.registerButtonDropdown = function () {
      var out2 = []
      for (var i in $scope.registerButtonStates) {
        if ($scope.registerButtonStates[i].active()) {
          var c = $scope.registerButtonState[$scope.registerButtonStates[i].name] ? 'fa-check-square-o' : 'fa-square-o'
          out2.push({
            text: '<i class="fa ' + c + '"></i> ' + $scope.registerButtonStates[i].friendly,
            click: "toggleRegisterButtonState('" + $scope.registerButtonStates[i].name + "')",
          })
        }
      }
      return out2
    }

    $scope.setReviewButtonDropdown = function () {
      if ($scope.currentRow === undefined) {
        return []
      }

      var reviewEmployeeId = $scope.currentRow.reviewEmployee
      return $scope.users
        .filter(function (user) {
          return $scope.userLookup[user.id].company.id === 1 && !$scope.userLookup[user.id].user.deactivated
        })
        .map(function (user) {
          var c = reviewEmployeeId === user.id ? 'fa-check-circle-o' : 'fa-circle-o'
          var click =
            reviewEmployeeId === user.id ? 'setReview(false,' + user.id + ')' : 'setReview(true,' + user.id + ')'
          return {
            text: '<i class="fa ' + c + '"></i> ' + user.name,
            click: click,
          }
        })
    }

    $scope.dmrContractDropdown = function () {
      var out2 = []
      for (var i = 1; i <= $scope.engine.eval('sommerbilAntalSaesoner'); i++) {
        out2.push({
          text:
            'Sæson ' +
            i +
            ' (' +
            $dateFormatter.formatDate($scope.engine.eval('startdatoSaeson' + i), 'dd.MM.yyyy') +
            '-' +
            $dateFormatter.formatDate($scope.engine.eval('slutdatoSaeson' + i), 'dd.MM.yyyy') +
            ')',
          click: 'openNewDMRContractModal(' + i + ')',
        })
      }
      return out2
    }

    $scope.moreDownloadButtonDropdown = function () {
      var out1 = getDownloadLinks($scope.downloadLinks)

      var out3 = getDownloadLinks($scope.contractDownloadLinks)
      if (out1.length > 0 && out3.length > 0) {
        out1.push({
          divider: true,
        })
      }
      return out1.concat(out3)
    }
    $scope.downloadButtonTitle = function () {
      if ($scope.modelVars.productId <= 3) {
        return 'Udskriv finansieringsforslag'
      } else {
        return 'Udskriv leasingforslag'
      }
    }

    $scope.leasingkodeParams = {
      nypris: 0,
      handelspris: 0,
      eftermonteretUdstyr: 0,
      fastPeriode: false,
      kronekontrakt: '',
      forsikring: '',
    }
    var leasingkodeScope = $rootScope.$new()

    var leasingkodeModal = $modal({ scope: leasingkodeScope, template: leasingkodeTemplate, show: false })

    $scope.transferAttachmentsToCerno = function () {
      var scope = $scope.$new()
      scope.self = scope
      scope.transferState = {}
      scope.count = 0
      scope.transferring = false
      scope.allSelected = false
      scope.updateCount = function () {
        var count = 0
        for (var x in scope.transferState) {
          if (scope.transferState[x]) {
            count++
          }
        }

        scope.allSelected = count === $scope.materials.length
        scope.count = count
      }

      scope.selectAll = function () {
        $scope.materials.forEach(function (m) {
          scope.transferState[m.filename] = !scope.allSelected
        })
        scope.updateCount()
      }

      scope.startTransfer = function ($hide) {
        scope.transferring = true
        var files = []
        for (var x in scope.transferState) {
          if (scope.transferState[x]) {
            files.push(x)
          }
        }
        $http
          .post('/cerno/transferAttachments/' + scope.id, files)
          .success(function (res) {
            $scope.setGlobalAlert('Vedhæftede filer blev overført til Cerno!')
            $hide()
          })
          .error(function (msg) {
            scope.error = msg
          })
      }

      var modal = $modal({
        scope: scope,
        template: attachmentsCernoTemplate,
        show: false,
      })

      modal.$promise.then(modal.show)
    }

    $scope.leasingkode = function (isNyAfgift) {
      leasingkodeScope.id = $scope.id
      leasingkodeScope.formIsLoaded = true
      leasingkodeScope.modelVars = {}
      leasingkodeScope.isNyAfgift = isNyAfgift

      leasingkodeScope.errorCount = errorCount

      leasingkodeScope.options = $scope.options
      leasingkodeScope.engine = new ComputationEngine(
        leasingkodeScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs,
      )

      // Vi tillader at der kan være fejl i angulars formvalidering, når en beregning er låst
      // Derfor skal vi bruge initialModelVars hvor angular ikke har sat felter til undefined
      // Helt konkret havde vi problemet med restværdi på sæsonleasingsager
      angular.extend(leasingkodeScope.modelVars, $scope.formIsDisabled ? $scope.initialModelVars : $scope.modelVars)

      leasingkodeScope.transferToCerno = function ($hide) {
        var temp = leasingkodeScope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
        var req = {
          type: 'leasingkode',
          filename: 'Bestilling af leasingkontrakt Krone.pdf',
          values: temp,
        }
        $http
          .post('/cerno/transferTemporaryPDF/' + leasingkodeScope.id, req)
          .success(function (res) {
            $scope.setGlobalAlert('Bestilling af leasingkontrakt Krone blev overført til Cerno!')
            $hide()
          })
          .error(function (msg) {
            leasingkodeScope.error = msg
          })
      }

      leasingkodeScope.variableDefs = $scope.variableDefs

      leasingkodeScope.$watchCollection('modelVars', function (newVar, oldVar) {
        leasingkodeScope.engine.clearExpressionCache()
      })

      leasingkodeModal.$promise.then(leasingkodeModal.show)
    }

    var genberegningRegAfgiftScope = $rootScope.$new()
    genberegningRegAfgiftScope.renderDate = dateToDanish
    var genberegningRegAfgiftModal = $modal({
      scope: genberegningRegAfgiftScope,
      template: genberegningregafgiftTemplate,
      show: false,
    })

    $scope.genberegningregafgift = function () {
      genberegningRegAfgiftScope.formIsLoaded = true
      genberegningRegAfgiftScope.modelVars = {}

      genberegningRegAfgiftScope.errorCount = errorCount

      genberegningRegAfgiftScope.options = $scope.options
      genberegningRegAfgiftScope.engine = new ComputationEngine(
        genberegningRegAfgiftScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs,
      )
      angular.extend(genberegningRegAfgiftScope.modelVars, $scope.modelVars)

      genberegningRegAfgiftScope.variableDefs = $scope.variableDefs

      genberegningRegAfgiftScope.$watchCollection('modelVars', function (newVar, oldVar) {
        genberegningRegAfgiftScope.engine.clearExpressionCache()
      })

      genberegningRegAfgiftModal.$promise.then(genberegningRegAfgiftModal.show)
    }

    $scope.prisskiltParams = {}

    var prisskiltScope = $rootScope.$new()

    var prisskiltModal = $modal({ scope: prisskiltScope, template: prisskiltTemplate, show: false })

    $scope.prisskilt = function () {
      prisskiltScope.formIsLoaded = true
      prisskiltScope.modelVars = {}

      prisskiltScope.errorCount = errorCount

      prisskiltScope.options = $scope.options
      prisskiltScope.engine = new ComputationEngine(
        prisskiltScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs,
      )
      angular.extend(prisskiltScope.modelVars, $scope.modelVars)

      prisskiltScope.variableDefs = $scope.variableDefs

      prisskiltScope.$watchCollection('modelVars', function (newVar, oldVar) {
        prisskiltScope.engine.clearExpressionCache()
      })

      prisskiltModal.$promise.then(prisskiltModal.show)
    }

    $scope.userLookup = {}

    $scope.users = []
    $http.post('/api/company/getAll').success(function (companies) {
      // når denne er hentet laves
      var companyLookup = {}
      for (var i in companies) {
        companyLookup['' + companies[i].id] = companies[i]
      }

      $http.post('/api/user/getAllByCompanyId').success(function (res) {
        for (var companyId in res) {
          if ($scope.userInfo.isSuperAdmin || companyId == $scope.userInfo.companyId) {
            for (var i in res[companyId]) {
              $scope.userLookup['' + res[companyId][i].id] = {
                user: res[companyId][i],
                company: companyLookup[companyId],
              }

              $scope.users.push({
                id: res[companyId][i].id,
                name: res[companyId][i].name,
                company: companyLookup[companyId].name,
              })
            }
          }
        }
      })
    })

    var attachFileModal = $modal({ scope: $scope, template: attachFileTemplate, show: false })

    $scope.openAttachFileModal = function (e) {
      e.preventDefault()
      attachFileModal.$promise.then(attachFileModal.show)
    }

    var vehicleCatalogModal = $modal({ scope: $scope, template: vehicleCatalogTemplate, show: false })

    $scope.vehicleModalMode = undefined
    $scope.knownVehicles = undefined
    $scope.filteredKnownVehicles = undefined
    $scope.search = { text: '' }

    $scope.openVehicleCatalog = function (e, mode) {
      e.preventDefault()
      $scope.vehicleModalMode = mode

      var url =
        mode === 'bilinfo'
          ? '/api/bilinfo/getAll'
          : '/api/existingVehicles/getTop/' + $scope.modelVars.productId + '/1000'

      $http.get(url).success(function (res0) {
        var res = mode === 'bilinfo' ? res0.Vehicles : res0
        $scope.knownVehicles = res
        $scope.filteredKnownVehicles = res
      })

      vehicleCatalogModal.$promise.then(vehicleCatalogModal.show)
    }

    $scope.selectKnownVehicle = function (vehicle) {
      vehicleCatalogModal.hide()

      if ($scope.vehicleModalMode !== 'bilinfo') {
        $scope.modelVars.maerkeModel = vehicle.maerkeModel
        $scope.modelVars.registreringsnummer = vehicle.registreringsnummer
        $scope.modelVars.stelnummer = vehicle.stelnummer
        $scope.modelVars.nyBil = false
        $scope.modelVars.tidligereIndregistreret = true
        $scope.modelVars.indregistreretFoersteGang = vehicle.indregistreretFoersteGang
        $scope.modelVars.farve = vehicle.farve
        $scope.modelVars.vurderetRegAfgift = vehicle.vurderetRegAfgift
        $scope.modelVars.kilometerstand = vehicle.kilometerstand
        $scope.modelVars.fakturaprisExMoms = vehicle.bookValue
      } else {
        $scope.modelVars.externBilinfoId = vehicle.VehicleSourceId
        $scope.modelVars.farve = vehicle.Color
        $scope.modelVars.maerkeModel = vehicle.Make + ' ' + vehicle.Model + ' ' + vehicle.Variant
        $scope.modelVars.kilometerstand = vehicle.Mileage
        $scope.modelVars.indregistreretFoersteGang = new Date(vehicle.RegistrationDate.split('-').reverse().join('-'))
      }
    }

    $scope.filterKnownVehicles = function () {
      const searchText = $scope.search.text.toLowerCase()

      if ($scope.vehicleModalMode !== 'bilinfo') {
        $scope.filteredKnownVehicles = $scope.knownVehicles.filter(function (vehicle) {
          return (
            vehicle.maerkeModel.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.stelnummer.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.farve.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.registreringsnummer.toLowerCase().indexOf(searchText) !== -1
          )
        })
      } else {
        $scope.filteredKnownVehicles = $scope.knownVehicles.filter(function (vehicle) {
          return (
            vehicle.Make.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.Model.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.Variant.toLowerCase().indexOf(searchText) !== -1 ||
            (vehicle.Color || '').toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.VehicleSourceId.toLowerCase().indexOf(searchText) !== -1
          )
        })
      }

      console.log($scope.filteredKnownVehicles.length)
    }

    $scope.bundleUrl = function () {
      if ($scope.id === undefined) {
        return '/api/applicationUpload/getBundleTemp/' + $scope.uploaddir
      } else {
        return '/api/applicationUpload/getBundle/' + $scope.id
      }
    }

    $scope.materialExtCount = function (matchExt) {
      var c = 0
      angular.forEach($scope.materials, function (m) {
        var ext = m.filename.split('.').slice('-1').join().toLowerCase()
        if (ext == matchExt) {
          c++
        }
      })
      return c
    }

    $scope.reloadMaterials = function () {
      if ($scope.id === undefined) {
        if ($scope.uploaddir !== undefined) {
          $http
            .post('/api/applicationUpload/getMaterialListTemp/' + $scope.uploaddir)
            .success(function (res) {
              $scope.materials = res.files
            })
            .error(function (res) {
              // TODO: Could not load materials
            })
        }
      } else {
        $http
          .post('/api/applicationUpload/getMaterialList/' + $scope.id)
          .success(function (res) {
            $scope.materials = res.files
          })
          .error(function (res) {
            // TODO: Could not load materials
          })
      }
    }

    $scope.uploadMaterial = function ($close) {
      if ($scope.selectedMaterials) {
        if ($scope.id !== undefined) {
          var uploadUrl = '/api/applicationUpload/uploadMaterials/' + $scope.id
        } else {
          if ($scope.uploaddir === undefined) {
            var uploadUrl = '/api/applicationUpload/uploadMaterialsTemp'
          } else {
            var uploadUrl = '/api/applicationUpload/uploadMaterialsTemp/' + $scope.uploaddir
          }
        }
        $scope.uploading = true

        $upload
          .upload({
            url: uploadUrl,
            file: $scope.selectedMaterials,
          })
          .error(function (data, status, headers, config) {
            $scope.successMsg = ''
            $scope.errorMsg = 'Materialer kunne ikke uploades.'
          })
          .success(function (data, status, headers, config) {
            if ($scope.id === undefined) {
              $scope.uploaddir = data
            }
            $scope.uploading = false
            $scope.errorMsg = ''
            $scope.successMsg = ''
            attachFileModal.hide()
            $scope.reloadMaterials()
          })
      }
    }

    $scope.deleteMaterial = function (m) {
      var index = $scope.materials.indexOf(m)
      if (index > -1) {
        $scope.materials.splice(index, 1)
      }

      if ($scope.id === undefined) {
        if ($scope.uploaddir !== undefined) {
          $http
            .post('/api/applicationUpload/deleteMaterialTemp/' + $scope.uploaddir + '?filename=' + m.filename)
            .success(function (res) {
              console.log('Deleted material ' + m.filename)
            })
            .error(function (res) {
              console.log('Could not delete material ' + m.filename + ': ' + res)
            })
        }
      } else {
        $http
          .post('/api/applicationUpload/deleteMaterial/' + $scope.id + '?filename=' + m.filename)
          .success(function (res) {
            console.log('Deleted material ' + m.filename)
          })
          .error(function (res) {
            console.log('Could not delete material ' + m.filename + ': ' + res)
          })
      }
    }

    $scope.onMaterialSelect = function ($files) {
      $scope.selectedMaterials = $files
    }

    $scope.setWarning = function (warning, warningField) {
      $scope[warningField] = warning
    }

    $scope.redoRKILookupModal = function () {
      $modal({ template: warningRedoRKITemplate, show: true, scope: $scope })
    }

    $scope.redoRKI = function () {
      $timeout(function () {
        $scope.rkiRegistrations = {}
      })

      angular.forEach($scope.rkiElements, function (xs) {
        angular.forEach(xs, function (x) {
          if (x.length == 8) {
            $http.post('/api/registration/companyRegistrationForce/' + x).success(function (obj) {
              $timeout(function () {
                $scope.rkiRegistrations[x] = obj
              })
            })
          }
          if (x.length == 10) {
            $http.post('/api/registration/personRegistrationForce/' + x).success(function (obj) {
              $timeout(function () {
                $scope.rkiRegistrations[x] = obj
              })
            })
          }
        })
      })

      // hent på ny...
    }

    var serverVars = {}
    var modelVars = {}

    var originalEngine = new ComputationEngine(
      modelVars,
      serverVars,
      function () {
        return $scope.userInfo.id
      },
      function () {
        return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
      },
      function () {
        return $scope.engine.eval('forsikringFaellespoliceSelskab')
      },
      dkkapVarDefs,
    ) // hent senere serverVars ind...

    var engine = originalEngine

    var param = {}
    if ($stateParams.id !== undefined) {
      param.id = parseInt($stateParams.id, 10)
    }

    var DummyEngine = function (variableDefs, values) {
      for (var i in values) {
        if (variableDefs[i] !== undefined && variableDefs[i].datatype === 'date') {
          if (values[i] !== null) {
            values[i] = new Date(getTimeOfDate(values[i]))
          }
        }
      }

      this.eval = function (identifier, fallback) {
        if (values[identifier] === undefined) {
          return fallback
        }
        return values[identifier]
      }
      this.getValues = function () {
        return values
      }
      this.resetComputation = function () {}
      this.clearExpressionCache = function () {}
      this.getVariableDefs = function () {
        return variableDefs
      }
    }

    $scope.formIsLoaded = false
    $scope.formIsDisabled = false
    $scope.justInitialized = {}

    $scope.errorCount = errorCount
    $scope.errorList = errorList

    $scope.status = 0
    $scope.currentRow = {}

    var cachedRes = {} // "Fortryd"

    var getFail = function () {
      $scope.setGlobalAlert('Beregningen kunne ikke åbnes!')
      $location.url('/')
    }
    $scope.cernoSuccess = false
    $scope.cernoWorking = false
    $scope.transferToCerno = function () {
      $scope.cernoWorking = true
      $http.post('/cerno/transfer/' + param.id).success(function () {
        $scope.cernoSuccess = true
      })
    }

    $scope.transferToPenneo = function () {
      $scope.penneoWorking = true
      $http
        .post('/penneo/transfer/' + param.id, {
          ...{ persons: $scope.persons },
        })
        .success(function () {
          $scope.penneoSuccess = true
        })
    }

    $scope.finalizePenneo = function () {
      $scope.penneoFinalizeWorking = true
      $http.post('/penneo/finalize/' + param.id).success(function () {
        $scope.penneoFinalizeSuccess = true
      })
    }

    $scope.customerInfoLinkSuccess = false
    $scope.customerInfoLinkWorking = false
    $scope.customerInfoLink1 = ''
    $scope.customerInfoLink2 = ''
    $scope.createCustomerInfoLinks = function () {
      $scope.customerInfoLinkWorking = true
      $http.post('/api/application/createKeyForUserLink/' + param.id).success(function (response) {
        $scope.customerInfoLink1 = response[0].url
        if (response.length === 2) {
          $scope.customerInfoLink2 = response[1].url
        }
        $scope.customerInfoLinkStatusBool = 0
        $scope.customerInfoLinkSuccess = true
      })
    }

    $scope.aftalenr = null
    $scope.aftalenr2 = null

    $scope.sendCustomerInfoLinkSuccess = false
    $scope.sendCustomerInfoLinkWorking = false
    $scope.sendCustomerInfoLinkFailure = false
    $scope.sendCustomerInfoLink = ''
    $scope.sendCustomerLink = function () {
      $scope.sendCustomerInfoLinkWorking = true
      $http
        .post('/api/application/sendUserLink/' + param.id)
        .success(function (response) {
          $scope.sendCustomerInfoLink = response.url
          $scope.sendCustomerInfoLinkSuccess = true
          $scope.sendCustomerInfoLinkWorking = false
        })
        .error(function (_) {
          $scope.sendCustomerInfoLinkFailure = true
          $scope.sendCustomerInfoLinkWorking = false
        })
    }

    $scope.customerInfoLinkStatus = function () {
      $http.post('/api/application/customerInfoLinkStatus/' + $scope.id).success(function (json) {
        if (json.key == null) {
          $scope.customerInfoLinkStatusBool = 0
        }
        if (json.key != null && json.submitTimestamp == null) {
          $scope.customerInfoLinkStatusBool = 1
        }

        if (json.key != null && json.submitTimestamp != null) {
          $scope.customerInfoLinkStatusBool = 2
        }
      })
    }

    $scope.showMail = function (id) {
      var modalScope = $scope.$new()
      modalScope.mailHistoryId = id
      modalScope.mailContent = undefined
      $http.get('/api/mailhistory/' + id + '/json').success(function (json) {
        modalScope.mailContent = json
      })
      var modal = $modal({
        scope: modalScope,
        template: mailTemplate,
        show: false,
      })
      modal.$promise.then(modal.show)
    }
    $scope.getSignerStatus = function () {
      $http.get('/penneo/signerStatus/' + $scope.id).success(function (json) {
        $scope.signerStatus = json
      })
    }

    $scope.statusToText = function (id) {
      switch (id) {
        case 0:
          return 'Ny'
        case 1:
          return 'Afventer'
        case 2:
          return 'Afvist'
        case 3:
          return 'Slettet'
        case 4:
          return 'Underskrevet'
        case 5:
          return 'Færdiggjort'
        case 6:
          return 'Fejlet'
        default:
          return 'Fejl'
      }
    }
    $scope.statusToColor = function (id) {
      switch (id) {
        case 0:
          return 'text-warning'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
        case 3:
          return 'text-danger'
        case 4:
          return 'text-success'
        case 5:
          return 'text-success'
        case 6:
          return 'text-danger'
        default:
          return 'text-danger'
      }
    }
    $scope.statusToIcon = function (id) {
      switch (id) {
        case 0:
          return 'fa-question-circle'
        case 1:
          return 'fa-question-circle'
        case 2:
          return 'fa-exclamation-circle '
        case 3:
          return 'fa-exclamation-circle '
        case 4:
          return 'fa-check-circle'
        case 5:
          return 'fa-check-circle'
        case 6:
          return 'fa-exclamation-circle '
        default:
          return 'fa-exclamation-circle '
      }
    }

    var loadForm = function (res) {
      $scope.forceEnabled = {}
      $scope.saving = false
      unregisterWatches()

      $scope.mailhistory = res.mailhistory
      if (res.modelVars !== undefined) {
        $scope.aftalenr = res.modelVars.aftalenr
        $scope.aftalenr2 = res.modelVars.aftalenr2
      }
      engine = originalEngine
      $scope.fieldDescriptions = res.infotexts
      $scope.engine = engine
      $scope.formIsLoaded = false
      $scope.formIsDisabled = false
      $scope.status = 0
      $scope.currentRow = res.modelVars

      $scope.signature = res.signature
      $scope.rkiRegistrations = {}
      $scope.showRkiRegistrations = false

      cachedRes = res
      var defs = engine.getVariableDefs()
      for (var field in res.serverVars) {
        serverVars[field] = res.serverVars[field]
      }
      $scope.products = res.products
      $scope.insuranceCompanies = res.insuranceCompanies
      $scope.insuranceCompanyLookup = {}
      $scope.insuranceCompanyLookupReverse = {}
      for (var i in $scope.insuranceCompanies) {
        $scope.insuranceCompanyLookup[$scope.insuranceCompanies[i].id] = $scope.insuranceCompanies[i].name
        $scope.insuranceCompanyLookupReverse[$scope.insuranceCompanies[i].name] = $scope.insuranceCompanies[i].id

        var temp = $scope.insuranceCompanies[i]
        for (var j in temp.synonyms) {
          $scope.insuranceCompanyLookupReverse[temp.synonyms[j]] = $scope.insuranceCompanies[i].id
        }
      }

      $scope.options.gpsFirmaKode = []
      for (var i in res.gpsProviders) {
        $scope.options.gpsFirmaKode.push({ value: res.gpsProviders[i].name, option: res.gpsProviders[i].caption })
      }

      $scope.options.gpsMedKoerebog = [
        { value: false, option: 'GPS-tracker uden kørebog' },
        { value: true, option: 'GPS-tracker med kørebog' },
      ]

      $scope.companyGroupCount = 0
      $scope.companyGroups = res.companyGroups
      $scope.companyGroupLookup = {}
      $scope.companyGroupLookupReverse = {}
      for (var i in $scope.companyGroups) {
        $scope.companyGroupCount++
        $scope.companyGroupLookup[$scope.companyGroups[i].id] = $scope.companyGroups[i]
        $scope.companyGroupLookupReverse[$scope.companyGroups[i].name] = $scope.companyGroups[i].id
      }

      if (res.values === undefined) {
        engine.resetComputation($scope.justInitialized) // Kun indlæs default-værdier når den er ved at blive redigeret!
      }

      if (param.id !== undefined) {
        $scope.id = param.id
        $scope.reloadMaterials()

        var values = res.values !== undefined ? res.values : res.modelVars
        //console.log($scope.formIsLoaded)
        for (var field in values) {
          if (defs[field] !== undefined) {
            if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
              if (values[field] === null) {
                $scope.modelVars[field] = null
              } else {
                $scope.modelVars[field] = new Date(getTimeOfDate(values[field]))
              }
            } else {
              $scope.modelVars[field] = values[field] // håndtér datoer etc
            }
            if (field == 'insuranceCompanyId' && $scope.modelVars[field] == 0) {
              $scope.modelVars[field] = null
            }
            if (field == 'productId' && $scope.products[$scope.modelVars[field]] == undefined) {
              $scope.modelVars[field] = null
            }
            // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
            if (field == 'anviserForhandler' && $scope.modelVars[field] === false) {
              $scope.modelVars[field] = 0
              $scope.modelVars.anviserKontraktsaet = true
            }
            if (field == 'anviserForhandler' && $scope.modelVars[field] === true) {
              $scope.modelVars[field] = 1
              $scope.modelVars.anviserKontraktsaet = false
            }

            $scope.justInitialized[field] = [] //true;
          }
        }

        console.log($scope.modelVars, res.modelVars, res.values)

        // ---
        // Af hensyn til Anmodning om leasingkode-formularen, gemmer vi også initialModelVars
        // Vi tillader at der kan være fejl i angulars formvalidering, når en beregning er låst
        // Derfor skal vi bruge initialModelVars hvor angular ikke har sat felter til undefined

        var preparedValues = {}
        for (var field in values) {
          if (defs[field] !== undefined) {
            if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
              if (values[field] === null) {
                preparedValues[field] = null
              } else {
                preparedValues[field] = new Date(getTimeOfDate(values[field]))
              }
            } else {
              preparedValues[field] = values[field] // håndtér datoer etc
            }
            if (field == 'insuranceCompanyId' && preparedValues[field] == 0) {
              preparedValues[field] = null
            }
            if (field == 'productId' && $scope.products[preparedValues[field]] == undefined) {
              preparedValues[field] = null
            }
            // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
            if (field == 'anviserForhandler' && preparedValues[field] === false) {
              preparedValues[field] = 0
              preparedValues.anviserKontraktsaet = true
            }
            if (field == 'anviserForhandler' && preparedValues[field] === true) {
              preparedValues[field] = 1
              preparedValues.anviserKontraktsaet = false
            }
          }
        }
        $scope.initialModelVars = preparedValues
        $scope.getSignerStatus()

        //console.log($scope.formIsLoaded)
        //console.log(modelVars['loebetidWrite'],modelVars.loebetidWrite, JSON.stringify(modelVars),values)//,$scope.formIsLoaded)
        if (res.values !== undefined) {
          if ($scope.userInfo.isSuperAdmin) {
            $scope.forceEnabled = {
              navn: true,
              adresse: true,
              postnr: true,
              tlf: true,
              tlfAlternativ: true,
              mail: true,

              navn2: true,
              adresse2: true,
              postnr2: true,
              tlf2: true,
              tlfAlternativ2: true,
              mail2: true,

              registreringsnummer: true,
              stelnummer: true,

              kilometerstand: true,
              insuranceCompanyId: true,
              maerkeModel: true,
              farve: true,
              produktlink: true,

              faellespolice: true,
              forsikringIkraft: true,
              boligtype: true,
              bruttoloen: true,
              nettoloen: true,
              raadighedsbeloeb: true,
              antalVoksne: true,
              antalBoern: true,
              haftBilFoer: true,

              ejerCpr1: true,
              ejerNavn1: true,
              ejerCpr2: true,
              ejerNavn2: true,
              ejerCpr3: true,
              ejerNavn3: true,
              ejerCpr4: true,
              ejerNavn4: true,

              kommentar: true,
              statustekst: true,

              netsRegNr: true,
              netsKontoNr: true,
              netsAfventer: true,

              beskatningsgrundlagBil: true,

              leasinggiverBetalerNummerplader: true,
            }
          }

          $scope.engine = new DummyEngine(engine.getVariableDefs(), res.values)
          $scope.variableDefs = $scope.engine.getVariableDefs()
          $scope.formIsDisabled = true

          if (res.values.status === 1 && $scope.userInfo.isSuperAdmin) {
            $scope.showRkiRegistrations = true

            $scope.rkiGroups = ['Kunde', 'Ultimative ejere', 'Kautionist']
            $scope.rkiElements = {
              Kunde: [],
              'Ultimative ejere': [],
              Kautionist: [],
            }
            if (res.values.kundetype === false) {
              $scope.rkiElements['Kunde'].push(res.values.cpr)
            } else {
              $scope.rkiElements['Kunde'].push(res.values.cvr)
            }
            if (res.values.meddebitor === true) {
              $scope.rkiElements['Kunde'].push(res.values.cpr2)
            }

            if (res.values.kautionist == 2) {
              $scope.rkiElements['Kautionist'].push(res.values.kautionistCpr)
            }
            if (res.values.kautionist == 3) {
              $scope.rkiElements['Kautionist'].push(res.values.kautionistCvr)
            }

            angular.forEach(
              [res.values.ejerCpr1, res.values.ejerCpr2, res.values.ejerCpr3, res.values.ejerCpr4],
              function (nummer) {
                if (nummer !== null && nummer !== undefined && nummer.length > 0) {
                  $scope.rkiElements['Ultimative ejere'].push(nummer)
                }
              },
            )

            angular.forEach($scope.rkiElements, function (xs) {
              angular.forEach(xs, function (x) {
                if (x.length == 8) {
                  $http.post('/api/registration/companyRegistration/' + x + '/' + $scope.id).success(function (obj) {
                    $timeout(function () {
                      $scope.rkiRegistrations[x] = obj
                    })
                  })
                }
                if (x.length == 10) {
                  $http.post('/api/registration/personRegistration/' + x + '/' + $scope.id).success(function (obj) {
                    $timeout(function () {
                      $scope.rkiRegistrations[x] = obj
                    })
                  })
                }
              })
            })

            $scope.showTinglysning = true

            $http.post('/api/application/getTinglysning/' + $scope.id).success(function (obj) {
              $timeout(function () {
                $scope.tinglysningResult = obj
              })
            })

            $scope.showExperian = false
            $scope.experianResult = null
            $scope.experianError = null

            $http
              .post('/api/application/experian/json/' + $scope.id)
              .success(function (obj) {
                $timeout(function () {
                  $scope.experianResult = obj
                  $scope.showExperian = true
                })
              })
              .error(function (err) {
                $scope.experianError = err
                $scope.showExperian = true
              })

            $scope.getAllRecalculations = function () {
              $http.post('/api/recalculation/getAll/' + $scope.id).success(function (obj) {
                $timeout(function () {
                  $scope.recalculationResult = obj
                })
              })
            }
            $scope.deleteRecalculation = function (id) {
              $http.post('/api/recalculation/delete/' + id).success(function (obj) {
                $scope.getAllRecalculations()
              })
            }

            $scope.getAllRecalculations()

            $scope.showPant = false
            if ($scope.modelVars.stelnummer != '') {
              $http.post('/api/bilbogenLookup/' + $scope.modelVars.stelnummer).success(function (res) {
                $timeout(function () {
                  $scope.showPant = true
                  $scope.pantResult = res
                })
              })
            }
          }
        }
        $scope.status = $scope.currentRow.status

        if ($scope.status > 0) {
          $http.post('/api/ptb/getCvrs/' + $scope.id).success(function (res) {
            $scope.cvrs = res
          })
        }

        engine.clearExpressionCache()
      } else {
        var json = localStorage.getItem('modelVarsPreload')
        var values = JSON.parse(json)
        if (values) {
          for (var field in values) {
            if (defs[field] !== undefined) {
              if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
                if (values[field] === null) {
                  $scope.modelVars[field] = null
                } else {
                  $scope.modelVars[field] = new Date(getTimeOfDate(values[field]))
                }
              } else {
                $scope.modelVars[field] = values[field] // håndtér datoer etc
              }
              if (field == 'insuranceCompanyId' && $scope.modelVars[field] == 0) {
                $scope.modelVars[field] = null
              }
              if (field == 'productId' && $scope.products[$scope.modelVars[field]] == undefined) {
                $scope.modelVars[field] = null
              }
              // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
              if (field == 'anviserForhandler' && $scope.modelVars[field] === false) {
                $scope.modelVars[field] = 0
                $scope.modelVars.anviserKontraktsaet = true
              }
              if (field == 'anviserForhandler' && $scope.modelVars[field] === true) {
                $scope.modelVars[field] = 1
                $scope.modelVars.anviserKontraktsaet = false
              }

              $scope.justInitialized[field] = [] //true;
            }
          }
          engine.clearExpressionCache()
          $scope.modelVars.loebetidWrite = engine.eval($scope.variableDefs.loebetidWrite.defaultExpr)
        }

        localStorage.removeItem('modelVarsPreload')
        /* */
      }

      $scope.formIsLoaded = true
      //setTimeout(function(){
      $scope.form.$setPristine()
      $rootScope.currentForm = $scope.form
      $rootScope.currentStatus = $scope.modelVars.status
      registerWatches()
      //},100);

      $scope.registerButtonState = initializeRegisterButton({})

      $scope.findLeasingSync()
    }
    $scope.checkDirty = function () {
      angular.forEach($scope.form, function (input, key) {
        if (input.$dirty) {
          console.log(input, key)
        }
      })
    }

    $scope.undo = function () {
      var undoWarning = $modal({
        scope: $scope,
        template: warningUndoTemplate,
        show: false,
      })
      undoWarning.$promise.then(undoWarning.show)
    }

    $scope.doUndo = function () {
      loadForm(cachedRes)
    }

    $scope.modelVars = modelVars
    $scope.products = {}
    $scope.insuranceCompanies = {}
    $scope.variableDefs = engine.getVariableDefs()
    $scope.engine = engine
    $scope.showmode = 0 // FAV eller ej
    $scope.mainTabs = [
      {
        title: 'Finansieringsoplysninger',
        content: '',
      },
      {
        title: 'Kundeoplysninger',
        content: '',
      },
      {
        title: 'Køretøj',
        content: '',
      },
    ]

    if (typeof $scope.modelVars.boligtype == 'boolean' || $scope.modelVars.boligtype instanceof Boolean) {
      $scope.modelVars.boligtype = $scope.modelVars.boligtype ? 1 : 0
    }

    $scope.mainTabsReverse = {
      Finansieringsoplysninger: 0,
      Kundeoplysninger: 1,
      Køretøj: 2,
    }

    $scope.mainTabs.currentMainTab = 'Finansieringsoplysninger'

    $scope.hasTab = function (tabName) {
      return $scope.mainTabsReverse[tabName] !== undefined
    }

    $scope.addTab = function (tabObject) {
      if (!$scope.hasTab(tabObject.title)) {
        $scope.mainTabs.push(tabObject)
        $scope.mainTabsReverse[tabObject.title] = $scope.mainTabs.length - 1
      }
    }

    $scope.removeTab = function (tabName) {
      if ($scope.hasTab(tabName)) {
        var offset = $scope.mainTabsReverse[tabName]
        $scope.mainTabs.splice(offset, 1)
        delete $scope.mainTabsReverse[tabName]

        for (var i = offset; i < $scope.mainTabs.length; i++) {
          $scope.mainTabsReverse[$scope.mainTabs[i]] = i
        }
      }
    }

    $scope.currentTabTitle = function () {
      return $scope.mainTabs[$scope.mainTabs.currentMainTab].title
    }

    $scope.statusDescription = ['Gem som kladde', 'Færdig ansøgning']

    $scope.statusDropdown = [
      {
        text: 'Gem som kladde',
        click: 'modelVars.status = 0',
      },
      {
        text: 'Færdig ansøgning',
        click: 'modelVars.status = 1',
      },
    ]

    $scope.renderStaticAmount = function (value) {
      if (value === Infinity) {
        return 'kr ∞'
      } else if (value === -Infinity) {
        return 'kr -∞'
      } else {
        return 'kr ' + danishLocaleNumber(Math.ceil(value))
      }
    }
    $scope.renderStaticPct = function (value) {
      return (value * 100).toFixed(2).replace('.', ',') + ' %'
    }
    $scope.renderStaticDecimal = function (value) {
      return value.toFixed(1).replace('.', ',') + ''
    }
    $scope.renderAmount = function (value) {
      return danishLocaleNumber(Math.ceil(value))
    }
    $scope.renderPct = function (value) {
      return (value * 100).toFixed(2).replace('.', ',')
    }
    $scope.renderDecimal = function (value, decimals) {
      return value.toFixed(decimals).replace('.', ',')
    }
    $scope.renderDate = dateToDanish
    var justSaved = false

    var watches = []

    var unregisterWatches = function () {
      for (var i in watches) {
        var temp = watches[i]
        temp()
      }
      watches = []
    }

    $scope.onPersonsChange = function (persons) {
      $scope.persons = persons
    }

    $scope.digitalSignature = function (callback) {
      if ($scope.modelVars.usePenneo && $scope.cvrs.length > 0) {
        var modalScope = $scope.$new()
        modalScope.callback = callback
        $modal({
          template:
            '<div class="modal" tabindex="-1" role="dialog" ptb-modal aftalenr="aftalenr" application-id="id" engine="engine" when-done="callback" persons="persons" on-persons-change="onPersonsChange" $hide="$hide()"/>',
          show: true,
          scope: modalScope,
        })
      } else {
        callback()
      }
    }

    var registerWatches = function () {
      unregisterWatches()
      $scope.$watch('modelVars.knaekleasing', function (val, oldVal) {
        if (val === oldVal) return // ignore if called on initialization
        if (!$scope.formIsLoaded) return

        if (val == true) {
          $scope.modelVars.useLoebetidWrite = true

          if ($scope.modelVars.useRestvaerdiCalc != 'restvaerdi') {
            var temp = engine.eval('restvaerdi')
            if (isEmpty(temp)) {
              $scope.modelVars.restvaerdiExMomsWrite = undefined
            } else {
              $scope.modelVars.restvaerdiExMomsWrite = temp
            }
            $scope.modelVars.useRestvaerdiCalc = 'restvaerdi'
          }
        }
      })
      watches.push(
        $scope.$watch('modelVars.insuranceCompanyId', function (val, oldVal) {
          if ($scope.modelVars.insuranceCompanyId != engine.eval('forsikringFaellespoliceSelskab')) {
            $scope.modelVars.faellespoliceValgt = false

            if ($scope.modelVars.productId == 6 || $scope.modelVars.productId == 8) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              $scope.modelVars.gpsFirmaKode =
                engine.eval('forsikringFaellespoliceGpsGraense') <= engine.eval('fakturaprisExMoms')
                  ? serverVars.gpsFirmaKodeFlex
                  : null
              $scope.modelVars.gpsMedKoerebog = false
            }
          }

          //if ( $scope.insuranceCompanyLookupReverse !== undefined) {
          /*if (engine.eval("faellespolice") && $scope.mainTabs.currentMainTab == 3) {
                      $scope.mainTabs.currentMainTab = 2;
                    }*/

          //}
        }),
      )

      watches.push(
        $scope.$watch('modelVars.faellespoliceValgt', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization

          if (val) {
            $scope.modelVars.insuranceCompanyId = engine.eval('forsikringFaellespoliceSelskab')

            if ($scope.modelVars.productId == 6 || $scope.modelVars.productId == 8) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              $scope.modelVars.gpsFirmaKode =
                engine.eval('forsikringFaellespoliceGpsGraense') <= engine.eval('fakturaprisExMoms')
                  ? serverVars.gpsFirmaKodeFlex
                  : null
              $scope.modelVars.gpsMedKoerebog = false
            }
          } else {
            if ($scope.modelVars.insuranceCompanyId == engine.eval('forsikringFaellespoliceSelskab')) {
              $scope.modelVars.insuranceCompanyId = null
            }

            if ($scope.modelVars.productId == 6 || $scope.modelVars.productId == 8) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              $scope.modelVars.gpsFirmaKode = null
              $scope.modelVars.gpsMedKoerebog = false
            }
          }
        }),
      )

      watches.push(
        $scope.$watchCollection('modelVars', function (newVar, oldVar) {
          //console.log(newVar,oldVar);
          /*for (var field in newVar) {
                      if (newVar[field] != oldVar[field]) {
                        console.log(field, newVar[field], oldVar[field])
                      }
                    }*/

          if (!$scope.formIsLoaded) return
          //console.log('modelVars watch called')
          //console.log('collectionWatch', 'before', oldVar.loebetidWrite, 'after',newVar.loebetidWrite)
          engine.clearExpressionCache()
          //console.log('fakturaprisExMoms before = ' + oldVar.fakturaprisExMoms + ', fakturaprisExMoms after = ' + newVar.fakturaprisExMoms)
          //console.log('totalpris = ' + engine.eval('totalpris'))

          if ($scope.form.$dirty) {
            //} && !justSaved) {
            $scope.setGlobalAlert('')
          }
          if (justSaved) {
            justSaved = false
          }
          // 1) kald kun autostuff

          //console.log('modelVars changed')
          for (var i in engine.resetOrdering) {
            var varName = engine.resetOrdering[i]
            var rs = $scope.variableDefs[varName].reset
            var changeInResetVarFound = false
            for (var i in rs) {
              var val = newVar[rs[i]]
              var oldVal = oldVar[rs[i]]
              if (val === oldVal) continue // ignore if called on initialization
              if (val instanceof Date && oldVal instanceof Date && val.getTime() === oldVal.getTime()) continue
              changeInResetVarFound = true
              break
            }
            if (changeInResetVarFound) {
              //console.log('change in reset var found - recomputing ' + varName)
              var f = $scope.autostuffWatches[varName]
              f()
              //console.log('autostuff completed: '+ varName +' =' + $scope.modelVars[varName])
            }
          }
        }),
      )

      /*
            watches.push(
              $scope.$watch('modelVars.loebetidWrite', function(val, oldVal) {
                if (val===oldVal) return; // ignore if called on initialization
                if (!$scope.formIsLoaded) return;

                if ($scope.modelVars.productIdChanged) {
                  $scope.modelVars.productIdChanged = false;
                  if ($scope.modelVars.loebetidChanged && oldVal <= $scope.modelVars.maxLoebetid) {
                    // ændret løbetid bevares!
                  } else {
                    $scope.modelVars.loebetidChanged = false;
                  }
                } else {
                  //console.log('sætter løbetid ændret')
                  $scope.modelVars.loebetidChanged = true; // det er en bruger indtastning!
                }
              })
            );*/

      watches.push(
        $scope.$watch('modelVars.productId', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val >= 5) {
            console.log('changed insurance company!')
            $scope.modelVars.forsikringFriskade = true

            if (val == 6 || val == 8) {
              $scope.modelVars.faellespoliceValgt = true
              $scope.modelVars.insuranceCompanyId = $scope.engine.eval('forsikringFaellespoliceSelskab')

              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              console.log('special case!!!')
              $scope.modelVars.gpsFirmaKode =
                engine.eval('forsikringFaellespoliceGpsGraense') <= engine.eval('fakturaprisExMoms')
                  ? serverVars.gpsFirmaKodeFlex
                  : null
              $scope.modelVars.gpsMedKoerebog = false
            }
          }
          if (val <= 4 && oldVal >= 5) {
            $scope.modelVars.faellespoliceValgt = false
            $scope.modelVars.insuranceCompanyId = ''
            $scope.modelVars.forsikringFriskade = false

            $scope.modelVars.gpsFirmaKode = null
            $scope.modelVars.gpsMedKoerebog = false
          }

          // be aware that this is only relevant when changing a loaded formular.
          // defaultValue in ComputationEngine sets this otherwise
          if (val == 2 || val >= 4) {
            $scope.modelVars.kundetype = true
          }
          if ($scope.modelVars.kundetype == true) {
            // erhverv
            if (val == 6 || val == 8) {
              $scope.modelVars.meddebitor = true
            } else {
              $scope.modelVars.meddebitor = false
            }
          }
          if (val == 4) {
            modelVars.varevogn = true
          }
          if (val >= 4) {
            // på leasing-productIder skal løbetiden specificeres direkte
            modelVars.useLoebetidWrite = true
          } else {
            // på finansierings-productIds skal restværdiCalc ikke bruges
            modelVars.useRestvaerdiCalc = 'restvaerdi'
          }

          if (val >= 4) {
            // på leasing-productIder skal løbetiden specificeres direkte
            modelVars.fastRente = false
          }
          if (val != 3) {
            modelVars.tidligereIndregistreret = true
          }
          if ((val == 7 || val == 8) && oldVal !== 7 && oldVal !== 8) {
            modelVars.sommerbilAntalSaesoner = 1
          }
          if (val !== 7 && val !== 8 && (oldVal == 7 || oldVal == 8 || oldVal == null)) {
            modelVars.sommerbilAntalSaesoner = 0
          }
          $scope.modelVars.loebetidWrite = engine.eval($scope.variableDefs.loebetidWrite.defaultExpr)
          $scope.modelVars.betalingFoersteGang = engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
          $scope.modelVars.overkoerselKmPris = engine.eval($scope.variableDefs.overkoerselKmPris.defaultExpr)
          $scope.modelVars.totalprisMoms = engine.eval($scope.variableDefs.totalprisMoms.defaultExpr)

          $scope.modelVars.productIdChanged = true
        }),
      )

      var gpsWatcher = function (val, oldVal) {
        if (val === oldVal) return // ignore if called on initialization
        if (modelVars.productId == 5 || modelVars.productId == 7) {
          if (modelVars.faellespoliceValgt) {
            var shouldHaveGps = engine.eval('forsikringFaellespoliceGpsGraense') <= engine.eval('fakturaprisExMoms')

            if (shouldHaveGps && $scope.modelVars.gpsFirmaKode === null) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeFlex
            }
            if (!shouldHaveGps && $scope.modelVars.gpsFirmaKode !== null) {
              $scope.modelVars.gpsFirmaKode = null
            }
          }
        }
      }

      watches.push($scope.$watch('modelVars.fakturaprisExMoms', gpsWatcher))
      watches.push($scope.$watch('modelVars.leveringsomkostningerExMoms', gpsWatcher))
      watches.push($scope.$watch('modelVars.eftermonteretUdstyr', gpsWatcher))
      watches.push($scope.$watch('modelVars.nummerplader', gpsWatcher))

      watches.push(
        $scope.$watch('modelVars.kundetype', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == true) {
            // erhverv
            if (modelVars.productId == 6 || modelVars.productId == 8) {
              modelVars.meddebitor = true
            } else {
              modelVars.meddebitor = false
            }
          }

          modelVars.leasingtype = engine.eval('leasingtype.default')
        }),
      )
      watches.push(
        $scope.$watch('modelVars.anviserForhandler', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == 1) {
            // forhandler
            modelVars.anviserKontraktsaet = false
          } else {
            modelVars.anviserKontraktsaet = true
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.meddebitor', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == 1) {
            if ($scope.modelVars.adresse2 == '') {
              $scope.modelVars.adresse2 = $scope.modelVars.adresse
            }
            if ($scope.modelVars.postnr2 == null) {
              $scope.modelVars.postnr2 = $scope.modelVars.postnr
            }
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.cvr', function (cvr) {
          if (CVR_REGEXP.test(cvr)) {
            $scope.cvrResult = {
              register: 'CVR',
              type: 'wait',
            }

            $http.post('/api/CVRLookup/' + cvr).success(function (res) {
              $scope.cvrResult = res
              console.log($scope.cvrResult)
              var virksomhedsform = getCvrValue('Virksomhedsform')
              $scope.cvrEnkeltmandsvirksomhed =
                virksomhedsform &&
                virksomhedsform != 'Aktieselskab' &&
                virksomhedsform != 'Anpartsselskab' &&
                virksomhedsform != 'Kommanditaktieselskab/Partnerselskab' &&
                virksomhedsform != 'Iværksætterselskab'
              $scope.modelVars.tegningsregel = getCvrValue('Tegningsregel')
              $scope.modelVars.enkeltmandsvirksomhed = $scope.cvrEnkeltmandsvirksomhed
              $scope.modelVars.regnskabMangler = res.reports == 0
            })
          } else {
            $scope.cvrResult = {}
            $scope.cvrEnkeltmandsvirksomhed = false
            $scope.modelVars.enkeltmandsvirksomhed = false
          }
        }),
      )

      watches.push(
        $scope.$watchCollection(
          '[modelVars.productId, modelVars.kundetype, modelVars.stelnummer, modelVars.cvr, modelVars.cpr, modelVars.cpr2]',
          function () {
            if (!$scope.aftalenr) {
              var kundenr = modelVars.kundetype ? modelVars.cvr : modelVars.cpr

              var updateGenleasing = function (value) {
                console.log('called updateGenleasing with ', value)
                if (value) {
                  modelVars.genleasing = true
                  modelVars.genleasingKontraktnr = value.kontraktnr
                  modelVars.genleasingGps = value.produkt == 'Split' || value.produkt == 'Sæson-Split'
                } else {
                  modelVars.genleasing = false
                  modelVars.genleasingKontraktnr = null
                  modelVars.genleasingGps = false
                }
              }

              if (modelVars.productId >= 4 && !!modelVars.stelnummer && !!kundenr) {
                $http
                  .post(
                    '/api/existingContracts/lookupReleasing',
                    {
                      stelnummer: modelVars.stelnummer,
                      kundenr: kundenr,
                      kundenr2: modelVars.cpr2,
                    },
                    {
                      headers: { 'Content-Type': 'application/json' },
                    },
                  )
                  .success(function (res) {
                    $timeout(function () {
                      updateGenleasing(res.value)
                    })
                  })
                  .error(function (res) {
                    $timeout(function () {
                      updateGenleasing(null)
                    })
                  })
              } else {
                updateGenleasing(null)
              }
            }
          },
        ),
      )
    }

    $scope.cvrResult = {}
    $scope.cvrEnkeltmandsvirksomhed = false

    var getCvrValue = function (key) {
      if ($scope.cvrResult !== undefined) {
        if ($scope.cvrResult.sections !== undefined) {
          for (var j in $scope.cvrResult.sections) {
            if ($scope.cvrResult.sections[j].elements !== undefined) {
              for (var i in $scope.cvrResult.sections[j].elements) {
                var elem = $scope.cvrResult.sections[j].elements[i]
                if (elem.key == key) {
                  return elem.value[0]
                }
              }
            }
          }
        }
      }
      return null
    }

    $http.post('/api/application/get', param).success(loadForm).error(getFail)

    $scope.options = {}
    $scope.options.nyBil = [
      { value: true, option: 'Ny' },
      { value: false, option: 'Brugt' },
    ]
    $scope.options.gaeldsbrev = [
      { value: false, option: 'Med ejendomsforbehold' },
      { value: true, option: 'Gældsbrev' },
    ]
    $scope.options.kundetype = [
      { value: false, option: 'Privatkunde' },
      { value: true, option: 'Erhvervskunde' },
    ]
    $scope.options.leasingtype = [
      { value: false, option: 'Finansiel leasing' },
      { value: true, option: 'Operationel leasing' },
    ]
    $scope.options.leasingperiodeKontrakt = [
      { value: false, option: 'Udelad leasingperiode i kontrakt' },
      { value: true, option: 'Medtag leasingperiode i kontrakt' },
    ]
    $scope.options.haftBilFoer = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.demobil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.tilladPrivatAnviser = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.saesonleasingGentegning = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.meddebitor = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.boligtype = [
      { value: 0, option: 'Ejerbolig' },
      { value: 1, option: 'Lejebolig' },
      { value: 2, option: 'Andelsbolig' },
    ]
    $scope.options.fastRente = [
      { value: false, option: 'Variabel rente' },
      { value: true, option: 'Fast rente' },
    ]
    $scope.options.tidligereIndregistreret = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.anviserForhandler = [
      { value: 0, option: 'Leasingtager' },
      { value: 1, option: 'Forhandler' },
      { value: 2, option: 'Anden (privat)' },
      { value: 3, option: 'Anden (erhverv)' },
    ]
    $scope.options.anviserKontraktsaet = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.anviser2 = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Forhandler' },
      { value: 2, option: 'Anden (erhverv)' },
    ]
    $scope.options.anviser2Kontraktsaet = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.fastPeriode = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.aldersbetingelseDeaktiveret = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.cprUdenMod11 = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.brugtmoms = [
      { value: false, option: 'Ekskl. moms' },
      { value: true, option: 'Inkl. brugtmoms' },
    ]
    $scope.options.leasinggiverBetalerNummerplader = [
      { value: false, option: 'Forhandler' },
      { value: true, option: 'PerB Finans' },
    ]
    $scope.options.varevogn = [
      { value: false, option: 'Bil' },
      { value: true, option: 'Varevogn' },
    ]
    $scope.options.forsikringAldersgraense = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringSkadesfri = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringStatsborger = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringUndtagelse = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringFriskade = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.campingFastligger = [
      { value: false, option: 'Ja, vognen skal have nummerplader på' },
      { value: true, option: 'Nej, kunden er fastligger' },
    ]
    $scope.options.gaeldsbrevUdenPant = [
      { value: false, option: 'Med pant' },
      { value: true, option: 'Uden pant' },
    ]
    $scope.options.knaekleasing = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genberegningRegAfgiftValutabil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genberegningRegAfgiftBiludlejningMereEnd2Mdr = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.kautionist = [
      { value: 0, option: 'Ingen kautionist' },
      { value: 1, option: 'Ja, forhandler er kautionist' },
      { value: 2, option: 'Ja, privat kautionist' },
      { value: 3, option: 'Ja, erhvervskautionist' },
    ]
    $scope.options.kautionist2 = [
      { value: 0, option: 'Ingen kautionist 2' },
      { value: 1, option: 'Ja, privat kautionist 2' },
      { value: 2, option: 'Ja, erhvervskautionist 2' },
    ]
    $scope.options.sommerbil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.stiftelsesprovisionBeloebSpec = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.disableEkstraMaanedRegAfgift = [
      { value: false, option: 'Ekstra måned aktiveret' },
      { value: true, option: 'Ekstra måned deaktiveret' },
    ]
    $scope.options.prisInklAfgift = [
      { value: false, option: 'Ekskl. afgift' },
      { value: true, option: 'Inkl. afgift' },
    ]
    $scope.options.faellespoliceValgt = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genleasing = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genleasingGps = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.afgiftBeregnetAf = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Det har jeg' },
      { value: 2, option: 'Det har PerB Finans' },
    ]
    $scope.options.useRestvaerdiCalc = [
      { value: 'restvaerdi', option: 'Restværdi' },
      { value: 'afviklingsprocent', option: 'Afviklingsprocent' },
      { value: 'ydelse', option: 'Ydelse' },
    ]
    $scope.options.useFoersteYdelseExMomsPctWrite = [
      { value: false, option: 'Beløb' },
      { value: true, option: 'Procent' },
    ]

    $scope.parseInt = parseInt

    $scope.convertToSuperDraft = function () {
      $http.post('/api/application/convertToSuperDraft/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev konverteret til superkladde!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.convertToDraft = function () {
      $http.post('/api/application/convertToDraft/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev konverteret til kladde!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }

    $scope.validateEmail = function (email) {
      if (email === undefined) return true

      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }

    $scope.validateEmailList = function (str) {
      if (str === undefined) return true
      if (str.trim() == '') return true

      var xs = str.split(',')
      for (var i in xs) {
        var x = xs[i].trim()
        if (!$scope.validateEmail(x)) {
          return false
        }
      }
      return true
    }

    $scope.mailExternal = function (mail, cc, subject, content) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendExternalMail/' + param.id, {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          values: temp,
          id: $scope.id,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Sagen blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
        })
    }

    $scope.mailContract = function (mail, cc, subject, content, attachmentsState, subdocumentsState) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendContractMail/' + param.id, {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          attachments: attachmentsState,
          subdocuments: subdocumentsState,
          downloadButtonState: $scope.downloadButtonState,
          values: temp,
          id: $scope.id,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Kontrakten blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }

    $scope.mailOfferFiles = []
    $scope.mailOfferFilesSelect = function (files) {
      $scope.mailOfferFiles = files
    }

    $scope.mailOffer = function (mail, cc, subject, content, attachmentsState) {
      if ($scope.mailOfferFiles.length > 0) {
        $upload
          .upload({
            url: '/api/applicationUpload/uploadMaterialsTemp',
            file: $scope.mailOfferFiles,
          })
          .error(function () {
            $scope.errorMsg = 'Filer kunne ikke uploades.'
          })
          .success(function (data, status, headers, config) {
            console.log(data)
            $scope.mailOfferBase(mail, cc, subject, content, attachmentsState, data)
          })
      } else {
        $scope.mailOfferBase(mail, cc, subject, content, attachmentsState, undefined)
      }
    }
    $scope.mailOfferBase = function (mail, cc, subject, content, attachmentsState, data) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendOfferMail', {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          attachments: attachmentsState,
          downloadButtonState: $scope.downloadButtonState,
          values: temp,
          id: $scope.id,
          uploaddir: data,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Tilbuddet blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }
    $scope.mailMaterialRequest = function (mail, cc, subject, content) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendMaterialRequestMail', {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          values: temp,
          id: $scope.id,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Materialeforespørgselen blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }

    $scope.mailExternalModal = function () {
      var modalScope = $scope.$new()

      modalScope.mail = $scope.userInfo.externalMail
      $http.get('/api/application/getExternalMail/' + $scope.id).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })
      $modal({ template: applicationMailExternalTemplate, show: true, scope: modalScope })
    }

    $scope.mailContractModal = function () {
      var modalScope = $scope.$new()

      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail
      $http.get('/api/application/getContractMail/' + $scope.id).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })

      var subdocuments = [
        { name: 'huskeliste', friendly: 'Huskeliste' },
        { name: 'supplerendeoplysninger', friendly: 'Supplerende oplysninger' },
        { name: 'skatLeasingkontrakt', friendly: 'Leasingkontrakt' },
        { name: 'skatAllongeSplit', friendly: 'Allonge, SPLITleasing' },
        { name: 'skatAllongeAnviser', friendly: 'Anviser køber' },
        { name: 'faktura', friendly: 'Faktura' },
        { name: 'skatAllongeLeverancegodkendelse', friendly: 'Leverancegodkendelse' },
      ]
        .concat(isLeasing() && isKoebstaederne() ? [{ name: 'trooglove', friendly: 'Tro- og loveerklæring' }] : [])
        .concat([{ name: 'kautionserklaering', friendly: 'Kautionserklæring' }])
        .concat($scope.modelVars.productId == 6 ? [{ name: 'braendstofkort', friendly: 'Brændstofkort' }] : [])
        .concat([
          { name: 'alarmopkald', friendly: 'Alarmopkald' },
          { name: 'husstandsoplysninger', friendly: 'Husstandsoplysninger' },
        ])

      modalScope.attachments = [
        {
          name: 'kontrakt',
          friendly: 'Kontrakt MED kundekopi',
        },
        {
          name: 'kontraktexkundekopi',
          friendly: 'Kontrakt UDEN kundekopi',
        },
        {
          name: 'kontraktudvalgte',
          friendly: 'Kontrakt, udvalgte dokumenter',
          subdocuments: subdocuments,
        },
      ]

      if ($scope.modelVars.productId == 6) {
        modalScope.attachments.push({
          name: 'braendstofkort',
          friendly: 'Bestillingsformular til brændstofkort',
        })
      }

      /*if ($scope.userInfo.isSuperAdmin) {
              modalScope.attachments.push({
                name : 'kautionserklaering',
                friendly : 'Kautionserklæring'
              });
            }
            modalScope.attachments.push({
              name : 'anviser',
              friendly : 'Anviser køber*'
            });*/
      modalScope.attachments.push({
        name: 'anviserprivatforhandler',
        friendly: 'Anviser køber - sekundær',
      })
      /*modalScope.attachments.push({
              name : 'leverancegodkendelse',
              friendly : 'Leverancegodkendelse'
            });
            if ($scope.modelVars.productId == 6) {
                modalScope.attachments.push({
                  name : 'leverancegodkendelse2',
                  friendly : 'Leverancegodkendelse (Bruger 2)'
                });
            }*/
      if (isLeasing() && isKoebstaederne()) {
        modalScope.attachments.push({
          name: 'trooglove',
          friendly: 'Tro- og love erklæring*',
        })
      }
      /*
                modalScope.attachments.push({
                  name : 'husstandsoplysninger',
                  friendly : 'Husstandsoplysninger*'
                });*/
      modalScope.attachments.push({
        name: 'finansieringsforslag',
        friendly: 'Finansieringsforslag',
      })

      if (!isLeasing() && $scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'allongeindfrielse',
          friendly: 'Allonge, indfrielse',
        })
      }
      /*
                if (isLeasing()) {
                    modalScope.attachments.push({
                      name : 'faktura',
                      friendly : 'Faktura, førsteydelse'
                    });
                }
            */
      modalScope.subdocumentsState = {}

      for (var i in modalScope.attachments) {
        var a = modalScope.attachments[i]
        if (a.subdocuments) {
          var obj = {}
          for (var j in a.subdocuments) {
            obj[a.subdocuments[j].name] = false
          }
          modalScope.subdocumentsState[a.name] = obj
        }
      }

      modalScope.attachmentsState = {
        kontrakt: true,
        kontraktexkundekopi: false,
        kontraktudvalgte: false,
        braendstofkort: false,
        kautionserklaering: false,
        anviser: false,
        anviserprivatforhandler: false,
        husstandsoplysninger: false,
        finansieringsforslag: false,
        allongeindfrielse: false,
      }

      $scope.attachmentCount = 1
      modalScope.updateAttachmentCount = function () {
        modalScope.updateSubdocumentValidity()
        var c = 0
        angular.forEach(modalScope.attachmentsState, function (isActive) {
          if (isActive) {
            c++
          }
        })
        $scope.attachmentCount = c
      }

      $scope.subdocumentValidity = true

      modalScope.updateSubdocumentValidity = function () {
        for (var i in modalScope.attachments) {
          var a = modalScope.attachments[i]
          if (a.subdocuments && modalScope.attachmentsState[a.name]) {
            var c = 0
            for (var j in a.subdocuments) {
              if (modalScope.subdocumentsState[a.name][a.subdocuments[j].name]) {
                c++
              }
            }

            if (c == 0) {
              $scope.subdocumentValidity = false
              return
            }
          }
        }

        $scope.subdocumentValidity = true
      }

      $modal({ template: applicationMailContractTemplate, show: true, scope: modalScope })
    }

    $scope.mailOfferModal = function () {
      var modalScope = $scope.$new()

      modalScope.rememberDownloadButtonState = function () {
        $http.post('/api/setDownloadButtonState', $scope.downloadButtonState)
      }

      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }
      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail
      $http.post('/api/application/getOfferMail', { values: temp }).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })

      modalScope.attachments = [
        {
          name: 'finansieringsforslag',
          friendly: 'Finansieringsforslag',
        },
        {
          name: 'husstandsoplysninger',
          friendly: 'Husstandsoplysninger',
        },
      ]
      if ($scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'kautionserklaering',
          friendly: 'Kautionserklæring',
        })
      }
      if ($scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'kontraktudkast',
          friendly: 'Kontraktudkast',
        })
      }

      modalScope.attachmentsState = {
        finansieringsforslag: true,
        kautionserklaering: false,
        husstandsoplysninger: false,
        kontraktudkast: false,
      }

      $scope.attachmentCount = 1
      modalScope.updateAttachmentCount = function () {
        var c = 0
        angular.forEach(modalScope.attachmentsState, function (isActive) {
          if (isActive) {
            c++
          }
        })
        $scope.attachmentCount = c
      }

      $modal({ template: applicationMailOfferTemplate, show: true, scope: modalScope })
    }

    $scope.mailMaterialRequestModal = function () {
      var modalScope = $scope.$new()

      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail

      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http.post('/api/application/getMaterialRequestMail', { values: temp }).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })

      $modal({ template: applicationMailMaterialRequestTemplate, show: true, scope: modalScope })
    }

    $scope.setInfotext = function (field, description) {
      $http.post('/api/infotext/set/application/' + field, { description: description }).success(function (res) {
        $scope.fieldDescriptions[field] = description
      })
    }

    $scope.editInfotext = function (field) {
      if ($scope.userInfo.isSuperAdmin) {
        var modalScope = $scope.$new()
        modalScope.field = field
        modalScope.description = $scope.fieldDescriptions[field]
        if (modalScope.description == undefined) {
          modalScope.description = ''
        }
        $modal({ template: editInfotextTemplate, show: true, scope: modalScope })
      }
    }

    $scope.acceptPopup = function () {
      if ($scope.engine.eval('hovedstol') >= $scope.userInfo.hovedstolBestyrelseGraense) {
        var modalScope = $scope.$new()
        modalScope.acceptPopupFinalize = $scope.acceptPopupFinalize
        $modal({ template: bestyrelsesgodkendelseTemplate, show: true, scope: modalScope })
      } else {
        $scope.acceptPopupFinalize()
      }
    }

    $scope.acceptPopupFinalize = function () {
      $scope.$broadcast('disposableIncome.save')
      if ($scope.modelVars.begrundelseAfvisning.trim() != '') {
        $modal({ template: godkendafvisningfejlTemplate, show: true })
      } else {
        $http.post('/api/application/get', param).success(function (res) {
          if (res.modelVars.aftalenr > 0) {
            /*console.log('Har allerede aftalenr ' + res.modelVars.aftalenr);*/

            var modalScope = $scope.$new()
            modalScope.options.forceNewAftalenr = [
              { value: false, option: 'Nej, behold nuværende aftalenr' },
              { value: true, option: 'Ja, tildel nyt aftalenr' },
            ]
            modalScope.self = modalScope
            modalScope.forceNewAftalenr = false
            modalScope.updateApprovalDate = false
            modalScope.sendmail = false
            modalScope.changedProductId =
              $scope.modelVars.productId != $scope.currentRow.transferredProductId &&
              $scope.currentRow.transferredProductId > 0
            modalScope.isSplit = $scope.modelVars.productId == 6 || $scope.modelVars.productId == 8
            modalScope.aftalenr = res.modelVars.aftalenr
            modalScope.aftalenr2 = res.modelVars.aftalenr2
            modalScope.externalSideEffects = true
            $modal({ template: acceptCommentTemplate, show: true, scope: modalScope })
          } else {
            var modalScope = $scope.$new()

            modalScope.isLeasing = $scope.modelVars.productId >= 4
            modalScope.options.selvanmelder = [
              { value: false, option: 'Krone Kapital' },
              { value: true, option: 'PerB Finans' },
            ]
            modalScope.self = modalScope

            modalScope.selvanmelder =
              (serverVars.selvanmelderDemobil && $scope.modelVars.demobil) ||
              serverVars.selvanmelderProdukt[$scope.modelVars.productId]

            modalScope.dmrSynSenestDato = modelVars.dmrSynSenestDato
            modalScope.synAdvarsel = engine.eval('synAdvarsel')
            modalScope.renderDate = dateToDanish
            modalScope.externalSideEffects = true

            $modal({ template: acceptCommentTemplate, show: true, scope: modalScope })
          }
        })
      }
    }

    $scope.acceptWrapper = function (
      sendmail,
      comment,
      updateApprovalDate,
      forceNewAftalenr,
      selvanmelder,
      penneoCCName,
      penneoCCMail,
      docsIncludeDeliveryApproval,
      docsIncludeInvoice,
      externalSideEffects,
      callback,
    ) {
      return function () {
        $scope.accept(sendmail, comment, updateApprovalDate, forceNewAftalenr, selvanmelder, callback)
      }
    }

    $scope.accept = function (sendmail, comment, updateApprovalDate, forceNewAftalenr, selvanmelder, callback) {
      $scope.saving = true

      if (callback) {
        callback()
      }

      var sm = sendmail ? 1 : 0
      var ua = updateApprovalDate ? 1 : 0
      var fa = forceNewAftalenr ? 1 : 0

      $http
        .post('/api/application/accept/' + param.id + '/' + sm + '/' + ua + '/' + fa, {
          comment: comment || '',
          selvanmelder: selvanmelder,
          persons: $scope.persons,
          debtorNos: {}
        })
        .success(function (res) {
          $timeout(function () {
            $scope.saving = false
          })
          $scope.setGlobalAlert('Ansøgningen blev godkendt!')
          $scope.setKeepAlert(false)
          //$scope.transferToCerno(); Klares nu serverside i finalizeApproval!!
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
        })
    }

    $scope.forceacceptWrapper = function (isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate) {
      return function () {
        $scope.forceaccept(isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate)
      }
    }
    $scope.forceaccept = function (isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate) {
      var xs = [aftalenr]
      if (aftalenr2 > 0 && isSplit) {
        xs.push(aftalenr2)
      }
      xs.push(sendmail ? 1 : 0)
      xs.push(updateApprovalDate ? 1 : 0)
      var method = aftalenr2 > 0 && isSplit ? 'forceaccept2' : 'forceaccept1'
      $http.post('/api/application/' + method + '/' + param.id + '/' + xs.join('/')).success(function (res) {
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.setReview = function (status, userId) {
      var requestUrl = '/api/application/setReview/' + param.id + '/' + (status ? '1' : '0')
      if (typeof userId !== 'undefined') {
        requestUrl += '?userId=' + userId
      }
      $http.post(requestUrl).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens behandlingsstatus blev ændret!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.reject = function () {
      $http.post('/api/application/reject/' + param.id, {}).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev afvist!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.unapprove = function () {
      $http.post('/api/application/unapprove/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens godkendelsesstatus blev fjernet!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.archive = function () {
      $http.post('/api/application/archive/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev arkiveret!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.unarchive = function () {
      $http.post('/api/application/unarchive/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens blev gendannet fra arkivet!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.saving = false
    $scope.saveButton = function (status, saveAsNew) {
      if ($scope.formIsLoaded) {
        var wait = false
        if ($scope.modelVars.status !== status) {
          $scope.modelVars.status = status
          wait = true
        }

        var f = function () {
          if (!$scope.form.$invalid) {
            if (status == 1) {
              $modal({ template: warningSendTemplate, show: true, scope: $scope })
            } else {
              $scope.save(saveAsNew) // save without modal
            }
          }
        }

        if (wait) {
          setTimeout(f, 100)
        } else {
          f()
        }
      }
    }

    var formatValue = function (varDef, val) {
      if (val === undefined) return undefined
      if (varDef.datatype == 'amount') {
        val = $scope.renderStaticAmount(val)
      } else if (varDef.datatype == 'percent') {
        val = $scope.renderStaticPct(val)
      } else if (varDef.datatype == 'count') {
        val = $scope.renderAmount(val)
      } else if (varDef.datatype == 'decimal') {
        val = $scope.renderDecimal(val, 2)
      } else if (varDef.datatype == 'singledecimal') {
        val = $scope.renderDecimal(val, 1)
      }
      return val
    }
    //console.log('defined getMin')
    $scope.getMin = function (varName) {
      var varDef = $scope.variableDefs[varName]
      if (varDef.minExpr !== undefined) {
        return formatValue(varDef, $scope.engine.eval(varName + '.min'))
      }
    }
    $scope.getMax = function (varName) {
      var varDef = $scope.variableDefs[varName]
      if (varDef.maxExpr !== undefined) {
        return formatValue(varDef, $scope.engine.eval(varName + '.max'))
      }
    }

    $scope.save = function (saveAsNew) {
      $scope.saving = true

      justSaved = true
      var temp = {}
      for (var v in modelVars) {
        var d = modelVars[v]
        temp[v] = d instanceof Date ? dateToYMD(d) : d
        if (v == 'insuranceCompanyId') {
          if (temp[v] == null || temp[v] == '') {
            temp[v] = 0
          }
        }
      }

      var p = saveAsNew ? {} : param

      $http
        .post('/api/application/save', {
          param: p,
          modelVars: temp,
          uploaddir: $scope.uploaddir,
        })
        .success(function (res) {
          $scope.$broadcast('formCtrl.save.success')

          /*$scope.saveMessage = "Beregningen blev gemt";
          setTimeout(function() {
            $scope.saveMessage = "";
          },2000)*/
          if (saveAsNew) {
            $scope.setGlobalAlert('Beregningen blev gemt som kopi!')
          } else if (p.id === undefined) {
            $scope.setGlobalAlert('Beregningen blev gemt!')
          } else {
            $scope.setGlobalAlert('Beregningen blev opdateret!')
            $scope.setKeepAlert(false)
          }

          var subscope = $rootScope.$new()
          if (p.id === undefined) {
            $scope.form.$setPristine()

            if (res.message !== null) {
              subscope.close = function ($hide) {
                $location.url('/application/' + res.id)
                $hide()
              }
              subscope.message = res.message
              $modal({ template: applicationExternalLiabilitiesTemplate, show: true, scope: subscope })
            } else {
              $location.url('/application/' + res.id)
            }
          } else {
            if (res.message != null) {
              subscope.close = function ($hide) {
                $hide()
              }
              subscope.message = res.message
              $modal({ template: applicationExternalLiabilitiesTemplate, show: true, scope: subscope })
            }
            // hent ned på ny
            $http.post('/api/application/get', param).success(loadForm).error(getFail)
          }
        })
        .error(function () {
          /*$scope.errorMessage = "Beregningen kunne ikke gemmes";
          setTimeout(function() {
            $scope.errorMessage= "";
          },2000)*/
        })
    }
  },
]
