import existingContractsVehicleTemplate from '@/shared/templates/existing-contracts-vehicle.html'

export const dkkapExistingContractsVehicle = [
  '$http',
  '$rootScope',
  '$timeout',
  function ($http, $rootScope, $timeout) {
    return {
      restrict: 'A',
      template: existingContractsVehicleTemplate,
      scope: {
        modelVars: '=',
      },
      link: function ($scope, elm, attrs) {
        $scope.existingContracts = null

        function loadExisting() {
          if ($scope.modelVars.stelnummer && $scope.modelVars.stelnummer.length == 17) {
            $http.post('/api/existingContracts/lookupVehicle/' + $scope.modelVars.stelnummer).success(function (res) {
              $timeout(function () {
                $scope.existingContracts = res
              })
            })
          } else {
            $scope.existingContracts = null
          }
        }

        $scope.$watch('modelVars.stelnummer', loadExisting)
      },
    }
  },
]
