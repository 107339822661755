
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/codanDetails.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Detaljer</h4>\n      </div>\n      <div class=\"modal-body\">\n        <h1 class=\"text-muted text-center\">\n          <i class=\"fa fa-spin fa-spinner\" ng-if=\"detailsLoading\"></i>\n        </h1>\n\n        <table class=\"table\">\n          <tr ng-repeat=\"(key,value) in details.kontrol\">\n            <td>{{key}}</td>\n            <td>{{value}}</td>\n          </tr>\n        </table>\n\n        <hr />\n\n        <table class=\"table\">\n          <tr ng-repeat=\"(key,value) in details.alm\">\n            <td>{{key}}</td>\n            <td>{{value}}</td>\n          </tr>\n        </table>\n\n        <hr />\n\n        <table class=\"table\">\n          <tr ng-repeat=\"(key,value) in details.stilstand\">\n            <td>{{key}}</td>\n            <td>{{value}}</td>\n          </tr>\n        </table>\n      </div>\n\n      <div class=\"modal-footer\">\n        <button class=\"btn btn-default\" ng-click=\"$hide()\">Luk</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      